import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppDispatch, useTypedSelector } from "../../app/hooks";
import isEmail from "validator/lib/isEmail";
import { useHistory } from "react-router-dom";
import Link from "@mui/material/Link";

import { EnduvoButton } from "../../components/EnduvoButton";
import { getCommonAppConfig } from "../../features/commonAppConfig/commonAppConfig.slice";
import { milRegister } from "../../features/auth/auth.slice";
import { light } from "../../css/color";
import classes from "./styles.module.css";
import { LoginPage } from "../../pages/LoginPage/index";
import { SignUpPage } from "../../pages/SignUpPage/index";
import { ForgotPassword } from "../../pages/ForgotPassword";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "976px",
  height: "673px",
  bgcolor: "#FFFFFF", //'background.paper',
  background: "#FFFFFF",
  border: "2px solid #000",
  // background: '#282C30',
  borderRadius: 5,
  boxShadow: 24,
  // p: 4,
};

export const MilitarySignUp = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  // const { applicationLists } = useTypedSelector((state) => state.commonAppConfig);
  const [openSignUp, setopenSignUp] = React.useState(false);
  const [openLogin, setLoginOpen] = React.useState(false);
  const [openResetPassword, setResetPasswordOpen] = React.useState(false);
  const handleOpen = () => setopenSignUp(true);
  const handleLoginOpen = () => setLoginOpen(true);
  const handleResetPasswordOpen = () => setResetPasswordOpen(true);

  const handleClose = () => setopenSignUp(false);
  const handleLoginClose = () => setLoginOpen(false);
  const handleResetPasswordClose = () => setResetPasswordOpen(false);

  const [applicationLists, setApplicationLists] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [militaryEmail, setMilitaryEmail] = useState("");
  const [confirmMilitaryEmail, setConfirmMilitaryEmail] = useState("");
  const [personalEmail, setPersonalEmail] = useState("");
  const [confirmPersonalEmail, setConfirmPersonalEmail] = useState("");
  const [title, setTitle] = useState("");
  const [militaryBranchList, setMilitaryBranchList] = useState<any[]>([]);
  const [militaryRankList, setMilitaryRankList] = useState<any[]>([]);
  const [baseStationList, setBaseStationList] = useState<any[]>([]);
  const [howDidYouHearAboutUsDropdownList, sethowDidYouHearAboutUsDropdownList] = useState<any[]>([]);
  const [howDidYouHearAboutUs, sethowDidYouHearAboutUs] = useState("Select");
  const [militaryBranch, setMilitaryBranch] = useState("Select");
  const [militaryRank, setMilitaryRank] = useState("Select");
  const [baseStation, setBaseStation] = useState("Select");
  const [organization, setorganization] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const setInitialData = async (selectedBranchName: string) => {
    const response = await dispatch(getCommonAppConfig());
    if (response) {
      setApplicationLists(response.payload.data[0].military_branches);
      const appConfigList = response.payload.data[0];
      if (appConfigList && appConfigList.military_branches) {
        const militaryBranchDropdownList: string[] = [];
        const howDidYouHearAboutUsDropdownList: string[] = [];
        appConfigList.how_did_you_hear_about_us.forEach((element: string) => {
          howDidYouHearAboutUsDropdownList.push(element);
        });
        sethowDidYouHearAboutUsDropdownList(howDidYouHearAboutUsDropdownList);
        appConfigList.military_branches.forEach((militaryBranch: any) => {
          militaryBranchDropdownList.push(militaryBranch.branch_name);
          if (militaryBranch.branch_name === selectedBranchName) {
            setMilitaryRankList(militaryBranch.ranks);
            setBaseStationList(militaryBranch.basestations);
          }
        });
        setMilitaryBranchList(militaryBranchDropdownList);
      }
    }
  };

  useEffect(() => {
    setInitialData("Air Force");
  }, []);

  const onSelectBranch = (selectedBranchName: string) => {
    setMilitaryBranch(selectedBranchName);
    applicationLists.forEach((militaryBranch: any) => {
      if (militaryBranch.branch_name === selectedBranchName) setMilitaryRankList(militaryBranch.ranks);
    });
  };
  const goToHome = () => {
    setOpenSuccessModal(false);
    history.push("/");
  };
  const submit = async () => {
    if (
      baseStation !== "Select" &&
      militaryRank !== "Select" &&
      militaryBranch !== "Select" &&
      firstName &&
      lastName &&
      militaryEmail &&
      confirmMilitaryEmail &&
      personalEmail &&
      confirmPersonalEmail
    ) {
      if (!isEmail(militaryEmail)) return setErrorMessage("Email is not valid.");
      if (!isEmail(personalEmail)) return setErrorMessage("Email is not valid.");
      setErrorMessage("");
      const userObject = {
        name: firstName.concat(" ", lastName),
        email: personalEmail,
        military_user: true,
        user_category: "USER",
        how_did_you_hear_about_us: howDidYouHearAboutUs,
        title,
        organization,
        secondary_email_section: {
          secondary_email: militaryEmail,
          military_branch: militaryBranch,
          military_rank: militaryRank,
          base_station: baseStation,
        },
      };
      setIsLoading(true);
      const response = await dispatch(milRegister(userObject));
      setIsLoading(false);
      if (!response) return setErrorMessage("Please try afyer some time.");
      if (!response.payload.success) return setErrorMessage(response.payload.error.message);
      setOpenSuccessModal(true);
    } else return setErrorMessage("All fields are required.");
  };

  return (
    <Box sx={{ backgroundImage: light.bgColor, paddingTop: "31px", overflow: "hidden" }}>
      <Stack sx={{ pb: 10, flexDirection: "row", overflow: "hidden" }}>
        <Stack sx={{ position: "absolute", left: "12%", marginTop: -4 }}>
          <img
            style={{
              position: "absolute",
              marginTop: 40,
              marginLeft: 100,
              WebkitFilter: "grayscale(1) contrast(60%) ",
              filter: "drop-shadow(176.13px 161.752px 107.835px rgba(0, 0, 0, 0.26)) brightness(65%)",
            }}
            height="42px"
            width="42.17px"
            src="/images/Mil-Signup-sphere1.png"
            alt="/images/Mil-Signup-sphere1.png"
          />
          <img height="394.27px" width="241.35px" src="/images/Mil_Signup-vector1.png" alt="/images/Mil_Signup-vector1.png" />
          <img
            style={{ position: "absolute", top: "170%", right: "-18%" }}
            height="369.27px"
            width="506px"
            src="/images/Mil-Signup-Object1.png"
            alt="/images/Mil-Signup-Object1.png"
          />
        </Stack>
        {/* <Stack sx={{ position: 'absolute', left: '0%', top: '65px', opacity: 0.9 }}>
                    <img
                        height="1060px" 
                        width="1400px" 
                        src="/images/MilSignup-Bg.png"
                        alt="/images/MilSignup-Bg.png"
                    />
                </Stack> */}
        <Stack sx={{ position: "absolute", left: "62%", top: "35%" }}>
          <img height="48.72px" width="278.9px" src="/images/Mil-Signup-vector2.png" alt="/images/Mil-Signup-vector2.png" />
        </Stack>
        <Stack sx={{ position: "absolute", left: "100%", top: "47%" }}>
          <img
            style={{ position: "absolute", right: "15%" }}
            height="509px"
            width="463.81px"
            src="/images/Mil-Signup-Elipse.png"
            alt="/images/Mil-Signup-Elipse.png"
          />
        </Stack>
        <Stack sx={{ position: "absolute", left: "100%", top: "30%" }}>
          <img
            style={{ position: "absolute", right: "15%" }}
            height="491.92px"
            width="259.36px"
            src="/images/Mil-Signup-Img.png"
            alt="/images/Mil-Signup-Img.png"
          />
        </Stack>
        <Stack
          sx={{
            position: "absolute",
            right: "6%",
            top: "80%",
            filter: "blur(20px) drop-shadow(0px 0px 0px #ffffff)",
          }}
        >
          <img
            // style={{ position: 'absolute', left: '90%' }}
            height="253px"
            width="253px"
            src="/images/Mil-Signup-Elipse2.png"
            alt="/images/Mil-Signup-Elipse2.png"
          />
        </Stack>
        <Stack sx={{ position: "absolute", left: "38%", top: "75%" }}>
          <img height="403.5px" width="479.27px" src="/images/Mil-Signup-vector3.png" alt="/images/Mil-Signup-vector3.png" />
        </Stack>
      </Stack>
      <Stack alignItems="center" spacing={2} pb={30} sx={{ overflow: "hidden", mt: -6, zIndex: 200 }}>
        <Typography sx={{ fontSize: "60px", color: light.textColor2 }}>Get started for free</Typography>
        <Typography sx={{ fontSize: "24px", color: light.textColor2 }}>
          Experience how learning should be, no credit card required!
        </Typography>
        <Stack direction="row" spacing={2}>
          <Stack spacing={0.5}>
            <Stack direction="row">
              <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>First name*</Typography>
            </Stack>
            <TextField
              disabled={isLoading}
              onChange={(e) => setFirstName(e.target.value)}
              InputProps={{ style: { height: "50px" } }}
              id="outlined-basic"
              placeholder="Enter first name"
              className={classes.inputField}
              value={firstName}
            />
          </Stack>
          <Stack spacing={0.5}>
            <Stack direction="row">
              <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>Last name*</Typography>
            </Stack>
            <TextField
              disabled={isLoading}
              onChange={(e) => setLastName(e.target.value)}
              InputProps={{ style: { height: "50px" } }}
              id="outlined-basic"
              placeholder="Enter last name"
              className={classes.inputField}
              value={lastName}
            />
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack spacing={0.5}>
            <Stack direction="row">
              <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>Military branch*</Typography>
            </Stack>
            <TextField
              disabled={isLoading}
              onChange={(e) => onSelectBranch(e.target.value)}
              select
              InputProps={{ style: { height: "50px", color: militaryBranch == "Select" ? "#00000050" : "#000" } }}
              id="outlined-basic"
              className={classes.inputField}
              value={militaryBranch}
            >
              <MenuItem value="Select" disabled>
                Select
              </MenuItem>
              {militaryBranchList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
          <Stack spacing={0.5}>
            <Stack direction="row">
              <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>
                Military rank/grade*
              </Typography>
            </Stack>
            <TextField
              disabled={isLoading}
              onChange={(e) => setMilitaryRank(e.target.value)}
              select
              InputProps={{ style: { height: "50px", color: militaryRank == "Select" ? "#00000050" : "#000" } }}
              id="outlined-basic"
              // label="Military rank/grade"
              className={classes.inputField}
              value={militaryRank}
            >
              <MenuItem value="Select" disabled>
                Select
              </MenuItem>
              {militaryRankList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Stack>
        <Stack spacing={2} direction="row">
          <Stack spacing={0.5}>
            <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>Title</Typography>

            <TextField
              disabled={isLoading}
              onChange={(e) => setTitle(e.target.value)}
              InputProps={{ style: { height: "50px" } }}
              id="outlined-basic"
              placeholder="Enter title"
              className={classes.inputField}
              value={title}
            />
          </Stack>
          <Stack spacing={0.5}>
            <Stack direction="row">
              <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>Base/station*</Typography>
            </Stack>
            <TextField
              disabled={isLoading}
              onChange={(e) => setBaseStation(e.target.value)}
              select
              InputProps={{ style: { height: "50px", color: baseStation == "Select" ? "#00000050" : "#000" } }}
              className={classes.inputField}
              value={baseStation}
            >
              <MenuItem value="Select" disabled>
                Select
              </MenuItem>
              {baseStationList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack spacing={0.5}>
            <Stack direction="row">
              <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>
                Military email address*
              </Typography>
            </Stack>
            <TextField
              disabled={isLoading}
              onChange={(e) => setMilitaryEmail(e.target.value)}
              InputProps={{ style: { height: "50px" } }}
              id="outlined-basic"
              placeholder="Military email address"
              className={classes.inputField}
              value={militaryEmail}
            />
          </Stack>
          <Stack spacing={0.5}>
            <Stack direction="row">
              <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>
                Confirm military email address*
              </Typography>
            </Stack>
            <TextField
              disabled={isLoading}
              onChange={(e) => setConfirmMilitaryEmail(e.target.value)}
              InputProps={{ style: { height: "50px" } }}
              id="outlined-basic"
              placeholder="Confirm military email address"
              className={classes.inputField}
              value={confirmMilitaryEmail}
            />
            {militaryEmail !== confirmMilitaryEmail && confirmMilitaryEmail ? (
              <Typography sx={{ fontSize: "10px", color: light.errorTextColor, lineHeight: "16px" }}>
                Military email address mismatch
              </Typography>
            ) : null}
          </Stack>
        </Stack>
        <Stack sx={{ alignItems: "flex-start" }}>
          <Stack direction="row" spacing={2}>
            <Stack spacing={0.5}>
              <Stack direction="row">
                <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>
                  Personal email address*
                </Typography>
              </Stack>
              <TextField
                disabled={isLoading}
                onChange={(e) => setPersonalEmail(e.target.value)}
                InputProps={{ style: { height: "50px" } }}
                id="outlined-basic"
                placeholder="Personal email address"
                className={classes.inputField}
                value={personalEmail}
              />
            </Stack>
            <Stack spacing={0.5}>
              <Stack direction="row">
                <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>
                  Confirm personal email address*
                </Typography>
              </Stack>
              <TextField
                disabled={isLoading}
                onChange={(e) => setConfirmPersonalEmail(e.target.value)}
                InputProps={{ style: { height: "50px" } }}
                id="outlined-basic"
                placeholder="Confirm personal email address"
                className={classes.inputField}
                value={confirmPersonalEmail}
              />
              {personalEmail !== confirmPersonalEmail && confirmPersonalEmail ? (
                <Typography sx={{ fontSize: "10px", color: light.errorTextColor, lineHeight: "16px" }}>
                  Personal email address mismatch
                </Typography>
              ) : null}
            </Stack>
          </Stack>
          <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>
            We will send communication to personal email address.
          </Typography>
        </Stack>
        <Stack spacing={2} direction="row">
          <Stack spacing={0.5}>
            <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>
              Please identify your unit/organization
            </Typography>
            <TextField
              disabled={isLoading}
              onChange={(e) => setorganization(e.target.value)}
              InputProps={{ style: { height: "50px" } }}
              id="outlined-basic"
              placeholder="Please identify your unit/organization"
              className={classes.inputField}
              value={organization}
            />
          </Stack>
          <Stack spacing={0.5}>
            <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>
              How did you hear about us dropdown list.
            </Typography>

            {/* howDidYouHearAboutUsDropdownList */}
            <TextField
              disabled={isLoading}
              onChange={(e) => sethowDidYouHearAboutUs(e.target.value)}
              select
              InputProps={{ style: { height: "50px", color: baseStation == "Select" ? "#00000050" : "#000" } }}
              className={classes.inputField}
              value={howDidYouHearAboutUs}
            >
              <MenuItem value="Select" disabled>
                Select
              </MenuItem>
              {howDidYouHearAboutUsDropdownList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Stack>
        <Typography sx={{ fontSize: "13px", color: light.textColor5, lineHeight: "16px" }}>
          We will never share your data without approval.
        </Typography>
        <Stack flexDirection="row" alignItems="center">
          <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>
            By clicking Get Started, you agree to Enduvo Cookie Policy,
          </Typography>
          <Typography
            className={classes.textBgStyle}
            sx={{ cursor: "pointer", zIndex: 200 }}
            onClick={() => (window.location.href = `https://enduvo.com/terms-of-use/`)}
          >
            Terms of Use,
          </Typography>
          <Typography
            className={classes.textBgStyle}
            sx={{ cursor: "pointer", zIndex: 200 }}
            onClick={() => (window.location.href = `https://enduvo.com/privacy-policy/`)}
          >
            Privacy Policy.
          </Typography>
        </Stack>
        <Stack flexDirection="row" alignItems="center">
          <Typography sx={{ fontSize: "14px", color: light.textColor5, lineHeight: "16px" }}>
            Already have an account?
          </Typography>
          <Typography className={classes.textBgStyle} sx={{ cursor: "pointer", zIndex: 200 }} onClick={handleLoginOpen}>
            Login
          </Typography>
        </Stack>
        {isLoading ? (
          <Box sx={{ display: "flex" }}>
            <CircularProgress size={25} sx={{ color: "#939393" }} />
          </Box>
        ) : null}
        <Stack>
          <EnduvoButton
            startIcon={null}
            action={submit}
            buttonText="Get started"
            width="350px"
            height={null}
            disabled={isLoading}
          />
        </Stack>
        <Typography variant="caption" color="red">
          {errorMessage}
        </Typography>
        <Modal
          open={openSignUp || openLogin || openResetPassword}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {/* {!openResetPassword ? */}
          <Box sx={style}>
            <div
              style={{
                position: "absolute",
                marginTop: "15px",
                marginLeft: "930px",
                marginRight: "20px",
                cursor: "pointer",
                zIndex: 200,
              }}
              onClick={() => (openSignUp ? handleClose() : openLogin ? handleLoginClose() : handleResetPasswordClose())}
            >
              <img
                height="32px"
                width="32px"
                src="/images/dapp-signup-close1.png"
                alt="/images/dapp-signup-close1.png"
                className={classes.closeButton}
              />
            </div>
            {openSignUp ? <SignUpPage closeModal={handleClose} openLoginModal={handleLoginOpen} /> : null}
            {openLogin ? (
              <LoginPage
                closeModal={handleLoginClose}
                openRegisterModal={handleOpen}
                openResetPasswordModal={handleResetPasswordOpen}
              />
            ) : null}
            {openResetPassword ? (
              <ForgotPassword
                closeModal={handleResetPasswordClose}
                openRegisterModal={handleOpen}
                openLoginModal={handleLoginOpen}
              />
            ) : null}
          </Box>
        </Modal>
        <Modal open={openSuccessModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
          <Box
            sx={{
              position: "absolute" as "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "596px",
              height: "293px",
              bgcolor: light.bgColor5,
              border: "2px solid #000",
              borderRadius: 5,
              boxShadow: 24,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ p: 6 }}>
              <Typography sx={{ fontSize: "20px" }}>
                {`A set password link has been sent to your personal email,\n  ${personalEmail}\n `}
              </Typography>
              <Typography sx={{ fontSize: "20px" }}>
                {`\n A verification code has been sent to your military email, \n ${militaryEmail}. You will need this code to set your password.`}
              </Typography>
              <Stack sx={{ pt: 2 }}>
                <EnduvoButton startIcon={null} action={goToHome} buttonText="Ok" width="486px" height={null} disabled={false} />
              </Stack>
            </Box>
          </Box>
        </Modal>
      </Stack>
    </Box>
  );
};
