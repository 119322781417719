import React, { EffectCallback, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import { light } from "../../css/color";
import { EnduvoButton } from "../../components/EnduvoButton";
import { providerLogin } from "../../features/auth/auth.slice";
import { useAppDispatch } from "../../app/hooks";
import config from "../../app/config";

const LaunchAppPage = () => {
  const [success, setSuccess] = useState(true);
  const { search } = useLocation();

  useEffect(() => {
    const query = new URLSearchParams(search);
    setSuccess(query.get("success") === "true");
  }, []);

  const openApp = () => {
    const token = localStorage.getItem("enduvo_token");
    window.location.href = `${config.desktopAppDeepLinkURL}login?token=${token?.split(" ")[1]}`;
  };

  return (
    <Box sx={{ bgcolor: "rgb(192, 192, 192)" }}>
      <Stack alignItems="center" pt={6} pb={10}>
        <Stack flexDirection="row" sx={{ bgcolor: light.bgColor3, borderRadius: 5 }}>
          <Stack direction="column" sx={{ width: "500px", pr: 4, pl: 2, mt: 5 }}>
            <Stack alignItems="center" mb={2}>
              <Typography variant="h4" component="h2" color={light.textColor1}>
                {success ? `Almost done!` : `Something went wrong!`}
              </Typography>
            </Stack>
            <Stack mb={2} pl={4}>
              <Typography color={light.textColor1} sx={{ fontSize: 18, textAlign: "center" }}>
                {success ? `Press continue to go back to the Enduvo Launcher.` : `Please try login again.`}
              </Typography>
            </Stack>
            <Stack pr={2} pl={4}>
              {success ? (
                <EnduvoButton
                  startIcon={null}
                  action={() => openApp()}
                  buttonText="Continue"
                  width={null}
                  height={null}
                  disabled={false}
                />
              ) : null}
            </Stack>
            <Stack mt={4} pl={4}>
              <Typography color={light.textColor1} sx={{ fontSize: 16, fontStyle: "italic" }}></Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};

export default LaunchAppPage;
