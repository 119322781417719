import React, { useState } from "react";
import isEmail from "validator/lib/isEmail";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import classes from "./styles.module.css";

import { useAppDispatch } from "../../app/hooks";
import { requestPasswordLink } from "../../features/auth/auth.slice";
import { light } from "../../css/color";
import { RightSideUi } from "../../components/RightSideUi/index";
import { EnduvoButton } from "../../components/EnduvoButton";

interface ForgotPasswordPageParams {
  closeModal: () => void;
  openLoginModal: () => void;
  openRegisterModal: () => void;
}
export const ForgotPassword = (props: ForgotPasswordPageParams) => {
  const dispatch = useAppDispatch();

  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);

  const handleRegisterOpen = () => {
    props.openRegisterModal();
    props.closeModal();
  };

  const openLogin = () => {
    props.openLoginModal();
    props.closeModal();
  };

  const submit = async () => {
    if (!email) return setErrorMessage("Email is required.");
    else if (!isEmail(email)) return setErrorMessage("Email is not valid.");
    else setErrorMessage("");

    const response = await dispatch(requestPasswordLink({ email }));
    if (!response.payload.success) return setErrorMessage(response.payload.error.message);
    else setErrorMessage("");
    return setSuccessMessage(true);
  };
  return (
    <>
      <Stack direction="row">
        <Box sx={{ padding: "50px", borderRadius: "20px", width: "530px", pb: 14 }}>
          <Stack alignItems="center">
            {successMessage ? (
              <Stack justifyContent="center" sx={{ mt: "4px", p: "20px" }} spacing={2}>
                <Stack alignItems="center">
                  <Typography variant="h4" component="h2" color={light.textColor1}>
                    You should receive a link in a few moments. Please open that link to reset your password.
                  </Typography>
                </Stack>
                <Stack alignItems="center">
                  <Typography color={light.textColor1} sx={{ fontWeight: "bold", fontSize: "16px" }}>
                    {email}
                  </Typography>
                </Stack>
                <Stack>
                  <EnduvoButton
                    startIcon={null}
                    action={openLogin}
                    buttonText="Back to Login"
                    width={null}
                    height={null}
                    disabled={false}
                  />
                </Stack>
              </Stack>
            ) : (
              <Stack justifyContent="center" sx={{ p: "20px", borderRadius: "20px" }} spacing={2}>
                <Stack alignItems="center">
                  <Typography variant="h4" component="h2" color={light.textColor1}>
                    Enter your email to receive instructions on how to reset your password.
                  </Typography>
                </Stack>
                <Typography variant="caption" color="#A4A4A4">
                  Email
                </Typography>
                <TextField
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  InputProps={{ style: { height: "50px" } }}
                  id="outlined-basic"
                  placeholder="name@gmail.com"
                  className={classes.inputField}
                  value={email}
                />
                <Stack>
                  <EnduvoButton
                    startIcon={null}
                    action={submit}
                    buttonText="Reset"
                    width={null}
                    height={null}
                    disabled={false}
                  />
                </Stack>
                <Typography variant="caption" color="red">
                  {errorMessage}
                </Typography>
                <Stack direction="row" spacing={1} sx={{ justifyContent: "center" }}>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    variant="body2"
                    color="transparent"
                    className={classes.body3}
                    onClick={openLogin}
                  >
                    {`< Go back to Login`}
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ justifyContent: "center" }}>
                  <Typography variant="body2" color="#797979">
                    Doesn't have an account?
                  </Typography>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    variant="body2"
                    color="transparent"
                    className={classes.body3}
                    onClick={handleRegisterOpen}
                  >
                    Register
                  </Typography>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Box>
        <RightSideUi />
      </Stack>
    </>
  );
};
