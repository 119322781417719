import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { light } from "../../css/color";
import config from "../../app/config";

const RedirectPage = () => {
  const { search } = useLocation();
  const deepLink = `${config.desktopAppDeepLinkURL}view${search}`;
  useEffect(() => {
    setTimeout(function () {
      window.location.href = `/download?openLink=${encodeURIComponent(deepLink)}&type=open_app`;
    }, 2000);
    window.location.href = deepLink;
  }, []);

  return (
    <Box
      sx={{
        padding: "100px",
        textAlign: "center",
        bgcolor: light.bgColor,
        height: "110vh",
      }}
    >
      <CircularProgress sx={{ color: "grey.500" }} />
    </Box>
  );
};

export default RedirectPage;
