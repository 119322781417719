import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/auth.slice";
import downloadReducer from "../features/download/download.slice";
import commonAppConfigReducer from "../features/commonAppConfig/commonAppConfig.slice";
import enquiryReducer from "../features/enquiry/enquiry.slice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    download: downloadReducer,
    commonAppConfig: commonAppConfigReducer,
    enquiry: enquiryReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
