import React, { useState } from "react";
import isEmail from "validator/lib/isEmail";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import config from "../../app/config";
import { useAppDispatch } from "../../app/hooks";
import { login } from "../../features/auth/auth.slice";
import classes from "./styles.module.css";
import { light } from "../../css/color";
import { RightSideUi } from "../../components/RightSideUi/index";
import { EnduvoButton } from "../../components/EnduvoButton";

interface LoginPageParams {
  closeModal: () => void;
  openRegisterModal: () => void;
  openResetPasswordModal: () => void;
  getDownloadLinkUrl?: () => void;
}

export const LoginPage = (props: LoginPageParams) => {
  const dispatch = useAppDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const openSignUp = () => {
    props.openRegisterModal();
    props.closeModal();
  };
  const openForgotPassword = () => {
    props.openResetPasswordModal();
    props.closeModal();
  };

  const submit = async () => {
    if (!username) return setErrorMessage("Username is required.");
    else if (!isEmail(username)) return setErrorMessage("Username should be a valid email.");
    else if (!password) return setErrorMessage("Password is required.");
    else setErrorMessage("");

    const response = await dispatch(login({ username, password }));
    if (response.payload && !response.payload.success) return setErrorMessage(response.payload.error.message);
    else setErrorMessage("");
    props.closeModal();
    if (props.getDownloadLinkUrl) return props.getDownloadLinkUrl();
    else return null;
  };

  const microsoftSubmit = () => {
    window.location.href = `${config.baseURL}/api/auth/microsoft`;
  };

  const googleSubmit = () => {
    window.location.href = `${config.baseURL}/api/auth/google`;
  };

  return (
    <>
      <Stack direction="row">
        <Stack sx={{ borderRadius: 5, width: "530px", alignItems: "center", bgcolor: light.bgColor, pb: 14, pt: 14 }}>
          <Stack spacing={1} direction="column" justifyContent="center" sx={{ width: "350px" }}>
            <Typography variant="h4" component="h2" color={light.textColor1}>
              It’s awesome to have you here!
            </Typography>
            <Stack direction="column" spacing={1} sx={{ justifyContent: "center" }}>
              <Typography variant="caption" color="#A4A4A4">
                Username
              </Typography>
              <TextField
                onChange={(e) => setUsername(e.target.value)}
                InputProps={{ style: { height: "50px" } }}
                placeholder="Enter username..."
                name="username"
                className={classes.inputField}
                value={username}
              />
            </Stack>
            <Stack direction="column" spacing={2.75}>
              <Stack direction="column" spacing={1} sx={{ justifyContent: "center" }}>
                <Typography variant="caption" color="#A4A4A4">
                  Password
                </Typography>
                <TextField
                  onChange={(e) => setPassword(e.target.value)}
                  type="password"
                  InputProps={{ style: { height: "50px" } }}
                  placeholder="********"
                  name="password"
                  className={classes.inputField}
                  value={password}
                />
              </Stack>
              <Stack>
                <EnduvoButton startIcon={null} action={submit} buttonText="Login" width={null} height={null} disabled={false} />
              </Stack>
              <Typography variant="caption" color="red">
                {errorMessage}
              </Typography>
            </Stack>
            <Stack direction="column" spacing={1.75} sx={{ justifyContent: "center" }}>
              {/* <Stack direction="column" spacing={1} sx={{ justifyContent: "center" }}>
                <Typography variant="body2" color="#A4A4A4" align="center">
                  Or sign in with
                </Typography>
                <Stack direction="row" spacing={2.75} sx={{ justifyContent: "center" }}>
                  <Stack direction="column" spacing={1.25} sx={{ justifyContent: "center" }}>
                    <Button
                      onClick={microsoftSubmit}
                      className={classes.buttonIcon}
                      sx={{ borderRadius: "20px", border: "1px solid #A4A4A4", ":hover": { bgcolor: "#f0f0f0" } }}
                    >
                      <img src="/images/microsoft.png" alt="/images/microsoft.png" />
                    </Button>
                    <Typography variant="body2" color="#797979" align="center">
                      Microsoft
                    </Typography>
                  </Stack>
                  <Stack direction="column" spacing={1.25} sx={{ justifyContent: "center" }}>
                    <Button
                      onClick={googleSubmit}
                      className={classes.buttonIcon}
                      sx={{ borderRadius: "20px", border: "1px solid #A4A4A4", ":hover": { bgcolor: "#f0f0f0" } }}
                    >
                      <img src="/images/google.png" alt="/images/google.png" />
                    </Button>
                    <Typography variant="body2" color="#797979" align="center">
                      Google
                    </Typography>
                  </Stack>
                </Stack>
              </Stack> */}
              <Stack direction="column" spacing={0.25} sx={{ justifyContent: "center" }}>
                <Stack direction="row" spacing={1} sx={{ justifyContent: "center" }}>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    variant="body2"
                    color="transparent"
                    className={classes.body3}
                    onClick={openForgotPassword}
                  >
                    Forgot Password ?
                  </Typography>
                </Stack>
                <Stack direction="row" spacing={1} sx={{ justifyContent: "center" }}>
                  <Typography variant="body2" color="#797979">
                    Doesn't have an account?
                  </Typography>
                  <Typography
                    sx={{ cursor: "pointer" }}
                    variant="body2"
                    color="transparent"
                    className={classes.body3}
                    onClick={openSignUp}
                  >
                    Register
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <RightSideUi />
      </Stack>
    </>
  );
};
