import { Route, Switch } from "react-router-dom";

import { HomePage } from "./pages/HomePage/index";
import { DownloadAppPage } from "./pages/DownloadAppPage/index";
import { ResetPassword } from "./pages/ResetPassword/index";
import { ResetPasswordMilitary } from "./pages/ResetPasswordMilitary/index";
import { PlansPage } from "./pages/PlansPage/index";
import { GetADemo } from "./pages/GetADemo/index";
import { AboutPage } from "./pages/AboutPage/index";
import { TeamInvite } from "./pages/TeamInvite/index";
import RedirectPage from "./pages/RedirectPage";
import ProviderLoginRedirectPage from "./pages/ProviderLoginRedirectPage";
import LaunchAppPage from "./pages/LaunchAppPage";
import { PageNotFound } from "./pages/PageNotFound/index";
import { MilitarySignUp } from "./pages/MilitarySignUp/index";
import { EarlyAccessSignUp } from "./pages/EarlyAccessSignUp/index";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { TermsOfUse } from "./pages/TermsOfUse";
import { Contact } from "./pages/ContactPage";
import { OidcCallback } from "./pages/OidcCallback";
import { Login } from './pages/Login/Login';

export const Routes = () => (
  <Switch>
    <Route path="/" exact={true}>
      <HomePage />
    </Route>
    <Route path="/download" exact={true}>
      <DownloadAppPage />
    </Route>
    <Route path="/reset-password" exact={true}>
      <ResetPassword />
    </Route>
    <Route path="/reset-password-mil" exact={true}>
      <ResetPasswordMilitary />
    </Route>
    <Route path="/teams" exact={true}>
      <TeamInvite />
    </Route>
    <Route path="/plans" exact={true}>
      <PlansPage />
    </Route>
    <Route path="/getademo" exact={true}>
      <GetADemo />
    </Route>
    <Route path="/about" exact={true}>
      <AboutPage />
    </Route>
    <Route path="/view">
      <RedirectPage />
    </Route>
    <Route path="/oidc/:providerIdentifier/callback">
      <OidcCallback />
    </Route>
    <Route path="/redirect-sso">
      <ProviderLoginRedirectPage />
    </Route>
    <Route path="/sso">
      <LaunchAppPage />
    </Route>
    <Route path="/mil-signup" exact={true}>
      <MilitarySignUp />
    </Route>
    <Route path="/early-access" exact={true}>
      <EarlyAccessSignUp />
    </Route>
    <Route path="/early-access/signup" exact={true}>
      <HomePage />
    </Route>
    <Route path="/privacy-policy" exact={true}>
      <PrivacyPolicy />
    </Route>
    <Route path="/terms-of-use" exact={true}>
      <TermsOfUse />
    </Route>
    <Route path="/contact" exact={true}>
      <Contact />
    </Route>
    <Route path="/login">
      <Login />
    </Route>
    <Route>
      <PageNotFound />
    </Route>
  </Switch>
);
