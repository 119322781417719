import React, { useEffect } from "react";
import { Container, Typography, Box, CircularProgress } from "@mui/material";
import { light } from "../../css/color";
import { useAppDispatch, useTypedSelector } from "../../app/hooks";
import { AuthActionStatus, oidcCallback } from "../../features/auth/auth.slice";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Cookies from 'js-cookie';

type CallbackParams = {
  providerIdentifier: string;
};

export const OidcCallback = () => {
  const dispatch = useAppDispatch();
  const { providerIdentifier } = useParams<CallbackParams>();
  const { search: queryString } = useLocation();
  const history = useHistory();

  const status = useTypedSelector((state) => state.auth.status);
  const isLoading = status !== AuthActionStatus.failed;
  const error = useTypedSelector((state) => state.auth.error)

  useEffect(() => {
    dispatch(oidcCallback({ providerIdentifier, queryString }));
  }, [dispatch, providerIdentifier, queryString]);

  useEffect(() => {
    if (status !== AuthActionStatus.success) {
      return;
    }

    const deepLink = Cookies.get('deepLink');
    if (!deepLink) {
      history.push('/');

      return;
    }

    Cookies.remove('deepLink');
    window.location.href = deepLink;
  }, [status, history]);

  const LoadingView = (
    <>
      <Box sx={{ padding: 2 }}>
        <Typography
          sx={{ color: light.textColor2, fontSize: "28px", lineHeight: "27px" }}
          align="center"
          paragraph
        >
          Completing Log In ...
        </Typography>
      </Box>
      <Box  sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress  color="inherit"/>
      </Box>
    </>
  );

  const ErrorView = (
    <Box sx={{ padding: 2 }}>
      <Typography
        sx={{ color: light.textColor2, fontSize: "28px", lineHeight: "27px" }}
        align="center"
        paragraph
      >
        { error || 'Something went wrong. Please try again.' }
      </Typography>
    </Box>
  );

  return (
    <>
      <Box sx={{ padding: 8 }} >
        <Container maxWidth="lg">
          { isLoading ? LoadingView : ErrorView }
        </Container>
      </Box>
    </>
  );
};
