import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowOutlined";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useAppDispatch } from "../../app/hooks";
import { download } from "../../features/download/download.slice";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Modal from "@mui/material/Modal";
import { LoginPage } from "../../pages/LoginPage/index";
import { SignUpPage } from "../../pages/SignUpPage/index";
import { ForgotPassword } from "../../pages/ForgotPassword";
import { EnduvoButton } from "../../components/EnduvoButton";
import classes from "./styles.module.css";
import { light } from "../../css/color";
import config from "../../app/config";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "976px",
  height: "673px",
  bgcolor: "#FFFFFF", //'#282C30', //'background.paper',
  border: "2px solid #000",
  background: "#FFFFFF",
  borderRadius: 5,
  boxShadow: 24,
};

export const DownloadAppPage = () => {
  const [openLogin, setLoginOpen] = React.useState(false);
  const [openSignUp, setopenSignUp] = React.useState(false);
  const [openResetPassword, setResetPasswordOpen] = React.useState(false);

  const handleOpenSignUp = () => setopenSignUp(true);
  const handleLoginClose = () => setLoginOpen(false);
  const handleOpenLogin = () => setLoginOpen(true);
  const handleSignUpClose = () => setopenSignUp(false);
  const handleResetPasswordOpen = () => setResetPasswordOpen(true);
  const handleResetPasswordClose = () => setResetPasswordOpen(false);

  const [downloadCompleted, setdownloadCompleted] = React.useState(false);
  const [downloadUrlAlert, setdownloadUrlAlert] = React.useState("");
  const [showDownloadAlert, setShowDownloadAlert] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState("");
  const handleAlertClose = () => setShowDownloadAlert(false);
  const dispatch = useAppDispatch();

  const history = useHistory();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const openLink = query.get("openLink");

  const getDownloadLinkUrl = async () => {
    try {
      const token = localStorage.getItem("enduvo_token");
      if (token) {
        const resultAction = await dispatch(download());
        if (resultAction.payload.data && resultAction.payload.data.isInstallerExists) {
          setdownloadUrlAlert("Downloading...");
          setAlertSeverity("success");
          setTimeout(function () {
            setdownloadCompleted(true);
          }, 5000);
          window.location.href = resultAction.payload.data.downloadUrl;
        } else if (resultAction.payload.error) {
          setdownloadUrlAlert(
            `${resultAction.payload.error.message ? resultAction.payload.error.message : "Please login again and try again."}`
          );
          if (!resultAction.payload.error.message) {
            localStorage.removeItem("enduvo_token");
            history.push("/");
          }
          setAlertSeverity("error");
        } else {
          setdownloadUrlAlert(
            `Currently not available in ${resultAction.payload.data.os ? resultAction.payload.data.os : "this OS"}`
          );
          setAlertSeverity("info");
        }
        setShowDownloadAlert(true);
      } else setLoginOpen(true);
    } catch (error) {
      const msg = (error as Error).message;
      setdownloadUrlAlert(msg);
      setAlertSeverity("error");
      setLoginOpen(true);
    }
  };

  const openApp = () => {
    if (openLink) window.location.href = decodeURIComponent(openLink);
    else window.location.href = config.desktopAppDeepLinkURL;
    // else window.location.href = "enduvolauncher://";
  };
  return (
    <Stack sx={{ background: light.bgColor, height: "110vh", paddingLeft: "68px", paddingTop: "31px" }}>
      <Stack sx={{ marginTop: "31px", justifyContent: "space-around" }} flexDirection="row" flexWrap="wrap">
        {downloadCompleted ? (
          <Stack spacing={2} direction="column" mt={25}>
            <Typography sx={{ fontSize: "60px", lineHeight: "62px", fontWeight: "bold", color: light.textColor2 }}>
              Done downloading? Next...
            </Typography>
            <Typography sx={{ fontSize: "20px", lineHeight: "27px", color: light.textColor2 }}>
              Open the file, by double clicking it in your downloads folder.
            </Typography>
            <Typography sx={{ fontSize: "20px", lineHeight: "27px", color: light.textColor2 }}>
              Follow the instructions to install Enduvo to your computer.
            </Typography>
            <Typography sx={{ fontSize: "20px", lineHeight: "27px", color: light.textColor2 }}>
              After the app is installed :
            </Typography>
            <Stack sx={{ pt: 4 }} direction="row" spacing={2}>
              <EnduvoButton
                startIcon={<PlayArrowRoundedIcon />}
                action={openApp}
                buttonText="Open Enduvo"
                width={null}
                height={null}
                disabled={false}
              />
            </Stack>
          </Stack>
        ) : (
          <Stack spacing={2} direction="column" justifyContent="flex-start" mt={25}>
            <Typography
              sx={{ fontSize: "60px", lineHeight: "62px", fontWeight: "bold", color: light.textColor2 }}
              className={classes.heading1}
            >
              Intuitive Platform for 3D Content Creation
            </Typography>
            <Typography sx={{ fontSize: "20px", lineHeight: "27px", color: light.textColor2 }} className={classes.heading2}>
              Transform your presentations with Enduvo's simple drag and drop interface and powerful editing tools
            </Typography>
            {alertSeverity == "success" ? (
              <Stack>
                <Typography
                  sx={{ fontSize: "60px", lineHeight: "75px", fontWeight: "bold" }}
                  color="transparent"
                  className={classes.textBg}
                >
                  Downloading...
                </Typography>
              </Stack>
            ) : (
              <>
                {" "}
                <Stack sx={{ pt: 4 }} direction="row" spacing={2}>
                  <EnduvoButton
                    startIcon={<PlayArrowRoundedIcon />}
                    action={getDownloadLinkUrl}
                    buttonText="Download"
                    width={null}
                    height={null}
                    disabled={false}
                  />
                  <Button sx={{ color: light.textColor2, textTransform: "none" }}>
                    <Typography variant="subtitle1">Learn More</Typography>
                  </Button>
                </Stack>
                <Typography sx={{ fontSize: "16px", lineHeight: "27px", color: "#949494" }}>For Mac/Win/Oculus</Typography>{" "}
              </>
            )}
          </Stack>
        )}
        <Modal
          open={openSignUp || openLogin || openResetPassword}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                position: "absolute",
                marginTop: "15px",
                marginLeft: "930px",
                marginRight: "20px",
                cursor: "pointer",
                zIndex: 200,
              }}
              onClick={() => (openSignUp ? handleSignUpClose() : openLogin ? handleLoginClose() : handleResetPasswordClose())}
            >
              <img
                height="32px"
                width="32px"
                src="/images/dapp-signup-close1.png"
                alt="/images/dapp-signup-close1.png"
                onClick={() => (openSignUp ? handleSignUpClose() : openLogin ? handleLoginClose() : handleResetPasswordClose())}
                className={classes.closeButton}
              />
            </div>
            {openSignUp ? <SignUpPage closeModal={handleSignUpClose} openLoginModal={handleOpenLogin} /> : null}
            {openLogin ? (
              <LoginPage
                closeModal={handleLoginClose}
                openRegisterModal={handleOpenSignUp}
                getDownloadLinkUrl={getDownloadLinkUrl}
                openResetPasswordModal={handleResetPasswordOpen}
              />
            ) : null}
            {openResetPassword ? (
              <ForgotPassword
                closeModal={handleResetPasswordClose}
                openRegisterModal={handleOpenSignUp}
                openLoginModal={handleOpenLogin}
              />
            ) : null}
          </Box>
        </Modal>
        <Snackbar open={showDownloadAlert} autoHideDuration={6000} onClose={handleAlertClose}>
          {alertSeverity == "success" ? (
            <Alert onClose={handleAlertClose} sx={{ width: "100%" }} severity="success">
              {downloadUrlAlert}
            </Alert>
          ) : alertSeverity == "error" ? (
            <Alert onClose={handleAlertClose} sx={{ width: "100%" }} severity="error">
              {downloadUrlAlert}
            </Alert>
          ) : (
            <Alert onClose={handleAlertClose} sx={{ width: "100%" }} severity="info">
              {downloadUrlAlert}
            </Alert>
          )}
        </Snackbar>
        <Stack ml={2} pr={17}>
          <Box className={classes.elipse}>
            <img height="497px" width="497px" src="/images/dapp-elipse.png" alt="/images/dapp-elipse.png" />
          </Box>
          <Box className={classes.platform}>
            <img
              className={classes.platformImg}
              // height="346.08px"
              // width="781.74px"
              src="/images/light/dapp-platform.png"
              alt="/images/light/dapp-platform.png"
            />
          </Box>
          <Box className={classes.lap}>
            <img height="477.9px" width="695.3px" src="/images/dapp-download-lapp.png" alt="/images/dapp-download-lapp.png" />
          </Box>
          <Box className={classes.box5}>
            <img height="187px" width="239px" src="/images/light/dapp-box.png" alt="/images/light/dapp-box.png" />
          </Box>
          <Box className={classes.box7}>
            <img width="257px" height="195px" src="/images/dapp-box3.png" alt="/images/dapp-box3.png" />
          </Box>
          <Box className={classes.box6}>
            <img height="241.13px" width="271.51px" src="/images/light/dapp-box2.png" alt="/images/light/dapp-box2.png" />
          </Box>
          <Box className={classes.box9}>
            <img width="324px" height="313px" src="/images/light/dapp-box5.png" alt="/images/light/dapp-box5.png" />
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};
