import Typography from '@mui/material/Typography';
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { light }  from "../../css/color";

export const PlansPage = () => {
    return (
        <Box sx={{  bgcolor: light.bgColor,  height: '110vh', paddingLeft: '68px', paddingTop: '31px'}}>
            <Stack alignItems="center">
                <Typography sx={{ fontSize: '60px', color: light.textColor1}}>Plans</Typography>
            </Stack>
        </Box>
    );
};