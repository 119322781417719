import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { light } from "../../css/color";
import Stack from "@mui/material/Stack";
import classes from "./styles.module.css";

export const TermsOfUse = () => {
  return (
    <Box sx={{ backgroundImage: light.bgColor, paddingTop: "36px", overflow: "hidden" }}>
      <Stack pl={12} pr={8} spacing={2} sx={{ width: "89%", marginLeft: "50px" }}>
        <h1 className={classes.touHeading}>
            Terms of Use
        </h1>
        <Stack spacing={4}>
          <Stack direction="column" pt={4}>
            <Typography color={light.textColor2} className={classes.subHeading}>
              PLEASE READ THESE TERMS OF USE CAREFULLY. BY ACCESSING OR USING THIS WEB SITE, OR THE ENDUVO, 
              YOU AGREE TO BE BOUND BY THE TERMS AND CONDITIONS DESCRIBED HEREIN AND ALL TERMS INCORPORATED BY REFERENCE. 
              IF YOU DO NOT AGREE TO ALL OF THESE TERMS, DO NOT USE THIS WEB SITE OR THE ENDUVO PRODUCT.
            </Typography>
          </Stack>
          <Stack>
            <Typography color={light.textColor2} className={classes.textStyle}>
              We reserve the right to change or modify any of the terms and conditions contained in the Terms or any policy or 
              guideline of the Sites, at any time and in our sole discretion. Any changes or modification will be effective immediately 
              upon posting of the revisions on the Sites, and you waive any right you may have to receive specific notice of such 
              changes or modifications. Your continued use of these Sites following the posting of changes or modifications will 
              confirm your acceptance of such changes or modifications.
            </Typography>
          </Stack>
          <Stack flexDirection="row">
            <Typography color={light.textColor2} className={classes.textStyle}>
              1.
            </Typography>
            <Typography color={light.textColor2} className={classes.textStyle}>
              These terms and conditions (these <span style={{ fontWeight: 'bold' }}>“Terms”</span>) apply to the purchase and sale of products and services 
              through <a onClick={() => window.open("https://www.enduvo.com")} style={{ cursor: 'pointer', fontStyle: 'italic', textDecoration: 'underline' }}>https://www.enduvo.com</a>, its software and subdomains, mobile applications, and other media managed by Enduvo, Inc., 
              provides an online and virtual, augmented and mixed reality course creation software (the <span style={{ fontWeight: 'bold' }}>“Software”</span>) 
              and platform  (the <span style={{ fontWeight: 'bold' }}>“Platform”</span>) designed to deliver massive open online courses, commonly referred to as MOOCs,
              (the <span style={{ fontWeight: 'bold' }}>“Services”</span>), which enables end-users (<span style={{ fontWeight: 'bold' }}>“Students”</span>) 
              to connect with independent instructors (the <span style={{ fontWeight: 'bold' }}>“Students”</span>,” collectively with Students, 
              <span style={{ fontWeight: 'bold' }}>"You"</span> or the <span style={{ fontWeight: 'bold' }}>“Users,”</span> each a <span style={{ fontWeight: 'bold' }}>“User”</span>) 
              who provide pre-recorded instruction and learning services (the <span style={{ fontWeight: 'bold' }}>“Courses”</span>). From time to time, 
              Enduvo (also referred to as <span style={{ fontWeight: 'bold' }}>“Company,”</span> <span style={{ fontWeight: 'bold' }}>“ Us,” </span>
              <span style={{ fontWeight: 'bold' }}>“We,”</span> or <span style={{ fontWeight: 'bold' }}>“Our”</span> as the context may require), may update these Terms to clarify our practices, o
              r reflect new or different practices as new products and features are introduced without prior written notice at any time, 
              in our sole discretion. If we make any material changes to these Terms, we will notify You using prominent means such as by 
              email notice sent to the email address specified in Your account or by posting a notice through our services. 
              Modifications will become effective on the day they are posted unless stated otherwise.
            </Typography>
          </Stack>
          <Stack>
            <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                2.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle}>
                In this Agreement, in addition to the capitalized terms above, the following terms shall have the following meanings:
              </Typography>
            </Stack>
            <Stack flexDirection="row">
              <ul className={classes.textStyle}>
                <li>
                <span style={{ fontWeight: 'bold' }}>“Terms”</span> means these Terms
                </li>
                <li>
                <span style={{ fontWeight: 'bold' }}>“Student/Learner/You/ Your”</span> means users or Learners of the service
                </li>
                <li>
                <span style={{ fontWeight: 'bold' }}>“Account”</span> means collectively the personal information, Payment Information, and credentials used by Users 
                  to access Paid Content and/or any communications System on the Website.
                </li>
                <li>
                <span style={{ fontWeight: 'bold' }}>“Services”</span> means the services available to You through this Website, specifically use of the Enduvo’s proprietary learning platform;
                </li>
                <li>
                <span style={{ fontWeight: 'bold' }}>“Courses”</span> means pre-recorded instruction and/or learning services
                </li>
                <li>
                <span style={{ fontWeight: 'bold' }}>“Payment Information”</span> means any details required for the purchase of Services and payment for Services or Content 
                  from this Website. This includes, but is not limited to, credit/debit card numbers, bank account numbers, and sort codes;
                </li>
                <li>
                <span style={{ fontWeight: 'bold' }}>“Site” or “Sites”</span> means the website that You are currently using <a onClick={() => window.open("https://www.enduvo.com")} style={{ cursor: 'pointer', fontStyle: 'italic', textDecoration: 'underline' }}>https://www.enduvo.com</a> and any sub-domains of this 
                  site (e.g. subdomain.enduvo.com) unless expressly excluded by our terms and conditions;
                </li>
                <li>
                <span style={{ fontWeight: 'bold' }}>“Platform”</span> means Enduvo’s proprietary learning online platform
                </li>
                <li>
                <span style={{ fontWeight: 'bold' }}>“Instructor Terms”</span> means policies and terms that govern Instructors using Enduvo’s proprietary learning online platform or software appliance /desktop client
                </li>
                <li>
                <span style={{ fontWeight: 'bold' }}>“Partner Terms”</span> means policies and terms that govern third-party Partners using Enduvo’s proprietary learning 
                  online platform or software appliance /desktop client
                </li>
                <li>
                <span style={{ fontWeight: 'bold' }}>“Content”</span> means content on the Sites, including all information, software, technology, data, logos, marks, designs, 
                  text, graphics, pictures, audio and video files, other data or copyrightable materials or content, and their selection and arrangement
                </li>
                <li>
                <span style={{ fontWeight: 'bold' }}>“User Content”</span> means content uploaded, transmitted, or posted by Users, whether they are Instructors or Students
                </li>
                <li>
                <span style={{ fontWeight: 'bold' }}>“User Ideas”</span> means unsolicited ideas, including ideas for new promotions, products, services, applications, technologies or processes or other ideas
                </li>
              </ul>
            </Stack>
          </Stack>
          <Stack>
            <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                3.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontWeight: 'bold' }}>
                Your Use of Enduvo’s Sites, Software, Platform, and Services.
              </Typography>
            </Stack>
            <Stack flexDirection="row">
              <ul className={classes.textStyle}>
                <li>
                  YOUR USE OF THE SITES, THE SERVICES, RELATED WEBSITES, AND/OR THE CONTENT CONTAINED THEREIN CONSTITUTES YOUR AGREEMENT 
                  TO BE BOUND BY THESE TERMS OF USE. The Sites and Services are offered to You conditioned on Your acceptance of these 
                  Terms of Use and <a href="/privacy-policy">Privacy Policy</a>, and all terms, conditions, and notices contained herein. Please read the Terms of Use 
                  and Privacy Policy carefully before You start to use the Sites. By using the Sites, You accept and agree to be bound and 
                  abide by these Terms of Use and our Privacy Policy, incorporated herein by reference. If You do not, for any reason, 
                  agree to these Terms of Use and our Privacy Policy, You must not access or use the Sites.
                </li>
                <li>
                  You may use the Site only for lawful purposes and in accordance with these Terms. You agree to not use the Sites, Software, Platform, and Services:
                </li>
                <li>
                  In any way that violates any applicable federal, state, local, or international law or regulation (including, without 
                  limitation, any laws regarding the export of data or software to and from the United States or other countries).
                </li>
                <li>
                  For the purpose of exploiting, harming or attempting to exploit or harm minors in any way by exposing them to inappropriate 
                  content, asking for personally identifiable information or otherwise.
                </li>
                <li>
                  To transmit, or procure the sending of, any advertising or promotional material inconsistent with the Website, including 
                  any “junk mail,” “chain letter,” or “spam,” or any other similar solicitation.
                </li>
                <li>
                  To impersonate or attempt to impersonate any person or entity (including, without limitation, by using e-mail addresses 
                  or other personally identifying characteristics associated with such a person or entity.
                </li>
                <li>
                  “Site” or “Sites” means the website that You are currently using https://www.enduvo.comand any sub-domains of this site 
                  (e.g. subdomain.enduvo.com) unless expressly excluded by our terms and conditions;
                </li>
                <li>
                  To engage in any other conduct that restricts or inhibits anyone’s use or enjoyment of the Sites, Software, Platform, 
                  and Services, or which, as determined by us, may harm Us or users of the Sites, Software, Platform, and Services, or expose them to liability.
                </li>
                <li>
                  Additionally, you agree not to:
                  <ul>
                    <li>
                      Use the Sites, Software, Platform, and Services in any manner that could disable, overburden, damage, or impair 
                      the site or interfere with any other party’s use of the Website, including their ability to engage in real time activities through the Website.
                    </li>
                    <li>
                      Use any robot, spider, or other automatic devices, process or means to access the Website for any purpose, including 
                      monitoring or copying any of the material on the Sites, Software, Platform, or Services.
                    </li>
                    <li>
                      Use any manual process to monitor or copy any of the material on the Website or for any other unauthorized purpose without our prior written consent.
                    </li>
                    <li>
                      Use any device, software, or routine that interferes with the proper working of the Sites, Software, Platform, or Services.
                    </li>
                    <li>
                      Introduce any viruses, trojan horses, worms, logic bombs, or other material which is malicious or technologically harmful.
                    </li>
                    <li>
                      Attempt to gain unauthorized access to, interfere with, damage, or disrupt any parts of the Sites, Software, Platform, 
                      or Services, or any server, computer, or database connected to the Sites, Software, Platform, or Services.
                    </li>
                    <li>
                      Attack the Sites, Software, Platform, or Services via a denial-of-service attack or a distributed denial-of-service attack.
                    </li>
                    <li>
                      Otherwise attempt to interfere with the functionality of the Sites, Software, Platform, or Services.
                    </li>
                  </ul>
                </li>
                <li>
                  If You elect to access or use our Services that involve payment of a fee, then You agree to pay, and will be responsible 
                  for payment of, that fee and all taxes associated with such access or use. If You provide credit card information to pay 
                  for such fees then You hereby represent and warrant that You are authorized to supply such information and hereby authorize 
                  Enduvo to charge Your credit card on a regular basis to pay the fees as they are due.
                </li>
                <li>
                  If Your payment method fails or Your Account is past due, then We may collect fees owed using other collection mechanisms. 
                  This may include charging other payment methods on file with Us and/or retaining collection agencies and legal counsel. 
                  We may also block Your access to any Services pending resolution of any amounts due by You to Enduvo.
                </li>
                <li>
                  You must comply with all applicable federal, state, local, or international laws and regulations (including, without limitation, 
                  any laws regarding the export of data or software to and from the United States or other countries), laws relating to copyright 
                  and other intellectual property use, and to privacy and personal identity. Further, access to Our Services from territories 
                  where their contents are illegal is prohibited. Those who choose to access or use the Services from locations outside of the 
                  United States do so at their own initiative and are responsible for compliance with all local rules including, without limitation, 
                  rules about the internet, data, e-mail, or privacy. You further agree to comply with all applicable laws regarding the transmission 
                  of technical data exported from the United States or the country in which You reside. If You use the Services or Third Party Platforms 
                  from countries outside of the United States, You must agree to abide by all local rules regarding online conduct and acceptable content.
                </li>
                <li>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Excluded States and Territories.
                  </Typography>
                  You must not use the Sites, Software, Platform, or Services if You are a citizen, national, or resident of, 
                  or are under control of, the government of Cuba, Iran, Sudan, Libya, North Korea, Syria, Iraq, Morocco, Nigeria, 
                  Tunisia, Pakistan, Algeria, Afghanistan, Saudi Arabia, or any other country to which the United States has prohibited export. 
                  Each time You use Enduvo Products or Services You represent, warrant, and covenant that: (i) You are not a citizen, national, 
                  or resident of, nor under the control of, any such country to which the United States has prohibited export; 
                  (ii) You will not download or otherwise export or re-export the Software, directly or indirectly, to the above mentioned 
                  countries nor to citizens, nationals or residents of those countries; (iii) You are not listed on the U.S. 
                  Department of Treasury’s Lists of Specially Designated Nationals, Specially Designated Terrorists, and Specially 
                  Designated Narcotic Traffickers, the U.S. Department of State’s List of Statutorily Debarred Parties, or the U.S. 
                  Department of Commerce’s Denied Persons List, Entity List, or Unverified List Table of Denial Orders; 
                  (iv) You will not download or otherwise export or re-export the Enduvo Software, directly or indirectly, to persons 
                  on the above mentioned lists; (v) You will neither Use nor allow the Enduvo Software to be Used for, any purposes 
                  prohibited by United States federal or state law, including, without limitation, for the development, design, 
                  manufacture or production of nuclear, chemical, or biological weapons of mass destruction; (vi) The Enduvo Software will 
                  not be exported, directly, or indirectly, in violation of these laws, nor will the Enduvo Products or Services be 
                  Used for any purpose prohibited by these laws including, without limitation, nuclear, chemical, or biological weapons proliferation.
                </li>
                <li>
                  <Typography sx={{ fontWeight: 'bold' }}>
                    Minimum Age Requirement.
                  </Typography>
                  By using the Sites, Software, Platform, and Services, You represent and warrant that You are at least 
                  (i) 18 years old; (ii) are of the legal age of majority to enter into a binding contract in Your jurisdiction; and 
                  (iii) not prohibited from accessing or using the Sites, Platform, Software, or Services by any applicable law, rule, or regulation. 
                  If any of the above statements are not true, YOU MAY NOT USE THE SITES, PLATFORM, SOFTWARE, OR SERVICES. 
                  CHILDREN UNDER THE AGE OF 13 MAY NOT USE THE SITES OR REGISTER FOR AN ACCOUNT.
                </li>
              </ul>
            </Stack>
          </Stack>
          <Stack>
            <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                4.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontWeight: 'bold' }}>
                User Content.
              </Typography>
            </Stack>
            <Stack flexDirection="row">
              <ul className={classes.textStyle}>
                <li>
                  The Sites, Software, Platform, and Services may contain discussion forums, bulletin boards, review services,
                   or other forums in which Users or third parties may post content, messages, materials, reviews, or other 
                   items (“User Content”) on the Sites (“ Interactive Areas”). You are solely responsible for Your use of such 
                   Interactive Areas and use them at Your own risk. You represent and warrant that any content You submit to any 
                   Interactive Areas complies with these Terms and that You own or control all right, title, and interest in and 
                   to any content You submit to any Interactive Area. Any content submitted to any public area of the Sites 
                   is non-confidential and non-proprietary. By using any Interactive Areas, You agree that the content must 
                   comply with all applicable federal, state, local, and international laws and regulations, and agree not 
                   to post any User Content containing material which is defamatory, discriminatory, obscene, indecent, abusive, 
                   offensive, harassing, violent, hateful, inflammatory or otherwise objectionable. You also expressly agree not to post, 
                   upload to, transmit, distribute, store, create, or otherwise publish through the Sites any of the following:
                </li>
                <li>
                  Content that would constitute, encourage, or provide instructions for a criminal offense, violate the rights of any party, 
                  or that would otherwise create liability or violate any applicable federal, state, or local law.
                </li>
                <li>
                  Any message, data, information, text, music, sound, photos, graphics, code, or other material that is unlawful, 
                  libelous, defamatory, obscene, pornographic, indecent, lewd, suggestive, harassing, threatening, invasive of privacy 
                  or publicity rights, abusive, inflammatory, or fraudulent.
                </li>
                <li>
                  Content that may infringe any patent, trademark, trade secret, copyright, or other intellectual property or proprietary right of any party.
                </li>
                <li>
                  Content that impersonates any person or entity or otherwise associates, infers or misrepresents the User’s affiliation with a person or entity.
                </li>
                <li>
                  Any tool or service that imports content from any platform that prohibits such services from accessing its platform for this purpose.
                </li>
                <li>
                  Unsolicited promotions, political campaigning, advertising, or solicitations.
                </li>
                <li>
                  Private information of any third party, including, without limitation, addresses, phone numbers, email addresses, 
                  and credit card numbers, unless that third party has expressly consented to such use and disclosure.
                </li>
                <li>
                  Viruses, corrupted data, or other harmful, disruptive, or destructive files.
                </li>
                <li>
                  Content that is unrelated to the topic of the Interactive Area(s) in which such Content is posted, in Enduvo’s sole determination.
                </li>
                <li>
                  Content that, in Enduvo’s sole judgment, is objectionable or which restricts or inhibits any other person from using or 
                  enjoying the Interactive Areas or the Sites, or which may expose Enduvo or its affiliates or Users to any harm or liability of any kind.
                </li>
                <li>
                  Enduvo has a zero-tolerance policy towards unsolicited email or messages, commonly referred to as “spam.” 
                  Users may not use the Interactive Areas or the Sites generally to send commercial or other messages to any third-party, 
                  within or outside of the Sites, if those messages are not solicited, authorized, or welcomed by the third-party, 
                  and in using the Sites, Users must comply with all applicable laws, including laws that apply in any jurisdiction, 
                  to spam and marketing practices, and with any applicable marketing association guidelines on ethical marketing practices.
                </li>
                <li>
                  Any use of the Interactive Areas or other portions of the Sites in violation of the foregoing violates these 
                  Terms of Use and may result in, among other things, termination or suspension of Your rights to use the 
                  Interactive Areas and/or the Sites. If You believe that Your own User Content violates any law or regulation, 
                  is inaccurate, or poses any risk whatsoever to any third party, it is Your responsibility to take any corrective 
                  steps You deem necessary. If You believe that any third party’s Content or any Enduvo Content violates any laws or 
                  regulations, including, without limitation, any copyright laws, You should report it to Enduvo in accordance with 
                  the procedures that we maintain under our intellectual property policies as set forth in the section titled Intellectual Property Policy.
                </li>
                <li>
                  By interacting with User Content, you may encounter User Content that You consider offensive, indecent, or objectionable. 
                  We have absolutely no responsibility to keep such User Content from You and no liability for Your access to 
                  or consumption of any User Content. You interact with User Content at your own peril.
                </li>
                <li>
                  We shall, at all times, shall have the right to:
                </li>
                <li>
                  Remove or refuse to post any User Content for any or no reason in Our sole discretion.
                </li>
                <li>
                  Take any action with respect to any User Content that We deem necessary or appropriate in Our sole discretion, 
                  including if We believe that such User Content violates these Terms, infringes any intellectual property right or 
                  other rights of any person or entity, threatens the personal safety of users of the Site or the public, 
                  or could in any way create liability for Enduvo.
                </li>
                <li>
                  Disclose Your identity or other information about you to any third party who claims that material posted by 
                  you violates their rights, including their intellectual property rights.
                </li>
                <li>
                  Take appropriate legal action, including without limitation, referral to law enforcement, for any illegal or unauthorized use of the Site.
                </li>
                <li>
                  Terminate or suspend your access to all or part of the Website for any or no reason, including without limitation, 
                  any violation of these Terms of Use, or local, state, or federal law or regulation.
                </li>
                <li>
                  Without limiting the foregoing, We have the right to fully cooperate with any law enforcement authorities or court order requesting 
                  or directing Us to disclose the identity or other information of anyone posting any materials on or through the Site. 
                  YOU WAIVE AND HOLD HARMLESS ENDUVO, INC., AND ITS AFFILIATES, LICENSEES, AND SERVICE PROVIDERS FROM ANY CLAIMS RESULTING 
                  FROM ANY ACTION TAKEN BY ANY ADMINISTRATOR OF THE SITE DURING OR AS A RESULT OF SUCH AN INVESTIGATION AND FROM ANY ACTIONS 
                  TAKEN AS A CONSEQUENCE OF INVESTIGATIONS BY EITHER ENDUVO OR LAW ENFORCEMENT AUTHORITIES.
                </li>
                <li>
                  We do not undertake to review all material before it is posted on the Site, and cannot ensure prompt removal of objectionable 
                  material after it has been posted. Accordingly, Enduvo assumes no liability for any action or inaction regarding transmissions, 
                  communications, or content placed on the Site by any user or third party and shall have no liability or responsibility to anyone 
                  for performance or nonperformance of the
                  activities described in this section.
                </li>
              </ul>
            </Stack>
          </Stack>
          <Stack>
            <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                5.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle}>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Commercial Transactions.
                </Typography>
                All purchases through our Site or other transactions for the sale of Services are governed by these Terms as well as any applicable addenda hereto.
              </Typography>
            </Stack>
            <Stack flexDirection="row">
              <ul className={classes.textStyle}>
                <li>
                  Our platform is designed for Instructors, Content Providers, and Service Partners to create and sell materials related to online 
                  learning including Courses, online courses, images, videos, 3D models or Services or products related to learning domain. 
                  You will not sell any products that are not courses, images, videos, 3D Models, Services or products or at least related to 
                  learning and education. Enduvo reserves the right to terminate your subscription immediately, in Our sole discretion, 
                  if You use the platform to sell any items that are out of the scope of what We allow. Even if the content you create are 
                  related to learning and education, you cannot create products that teach any unlawful activity.
                </li>
                <li>
                  You shall pay Enduvo all fees specified in any applicable order document or other agreement between You and Enduvo. Enduvo may 
                  adjust the pricing in any such order annually by providing written notice to You. All fees shall be paid by You to and received by 
                  Enduvo within thirty (30) days after receipt of Enduvo’s invoice. Any amount payable to Enduvo hereunder and not paid when due 
                  shall accrue interest at a rate of the lower of one and one-half percent (1.5 %) per month or the maximum rate permitted by 
                  applicable law, and You shall be responsible for Enduvo’s reasonable expenses (including reasonable attorneys’ fees) incurred 
                  in connection with collecting any past due amounts from You.
                </li>
                <li>
                  You shall pay all sales, use, service, value added and other taxes (excluding taxes on Enduvo’s income) or fees imposed by any 
                  jurisdiction or financial institution arising out of or related to these Terms or to Your use of the Software or receipt of 
                  Services, regardless of when such tax liability or fee is asserted.
                </li>
                <li>
                  <p>
                      <span style={{ fontWeight: 'bold' }}>
                        Student Subscriptions.
                      </span>
                      If you are a Student, the Student Terms of Use (“ Student TOU”) to which you agreed when registering for 
                      Enduvo’s Service are incorporated herein. Any inconsistencies between the Student TOU and these 
                      Terms are resolved in favor of the Student TOU.
                  </p>
                </li>
                <li>
                  <p>
                      <span style={{ fontWeight: 'bold' }}>
                        Instructor Terms.
                      </span>
                      If you are an Instructor, the Instructor Terms of Use (“ Instructor TOU”) to which you agreed when registering for 
                      Enduvo’s Service are incorporated herein. Any inconsistencies between the Instructor TOU and these 
                      Terms are resolved in favor of the Instructor TOU.
                  </p>
                </li>
                <li>
                  <p>
                      <span style={{ fontWeight: 'bold' }}>
                        Partner Terms.
                      </span>
                      If you are a Partner, the Partner Terms of Use (“ Partner TOU”) to which you agreed when registering for Enduvo’s Service 
                      are incorporated herein. Any inconsistencies between the Instructor TOU and these Terms are resolved in favor of the Partner TOU.
                  </p>
                </li>
                <li>
                  <p>
                    <span style={{ fontWeight: 'bold' }}>
                    Site License Purchase.
                    </span>
                    When You purchase a site license an unlimited number of users, from an individual company (limited to a single Internet domain) 
                    may access the course for twelve (12) months from purchase (when the order is completed).
                  </p>
                </li>
              </ul>
            </Stack>
          </Stack>
          <Stack>
            <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                6.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontWeight: 'bold' }}>
                Intellectual Property Policy.
              </Typography>
            </Stack>
            <Stack flexDirection="row">
              <ul className={classes.textStyle}>
                <li>
                  <Typography color={light.textColor2} className={classes.textStyle}sx={{ fontWeight: '600' }}>
                    Our Intellectual Property.
                  </Typography>
                  <Typography color={light.textColor2} className={classes.textStyle}>
                    The Site and Our Services, as well as contents, features, and functionality (including but not limited to all information, 
                    software, text, displays, images, video and audio, and the design, selection, and arrangement thereof), are owned by 
                    Enduvo and Our licensors or other providers of such material, and are protected by United States and international copyright, 
                    trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
                  </Typography>
                    <ul>
                      <li>
                        You agree that you shall not reproduce, distribute, modify, create derivative works of, publicly display, publicly perform, 
                        republish, download, store, or transmit any of the material on our Website, except as follows:
                      </li>
                      <li>
                        Your computer may temporarily store copies of such materials in RAM incidental to your accessing and viewing those materials.
                      </li>
                      <li>
                        You may store files that are automatically cached by Your Web browser for display enhancement purposes.
                      </li>
                      <li>
                        You may print one copy of a reasonable number of pages of the Site for your own personal, non-commercial use 
                        and not for further reproduction, publication, distribution, or dissemination.
                      </li>
                      <li>
                        If We provide desktop, mobile, or other applications for download, You may download a single copy to your computer 
                        or mobile device solely for Your own personal, non-commercial use, provided you agree to be bound by our end user license agreement for such applications.
                      </li>
                      <li>
                        If the Site includes social media features with certain content, you may take such actions as are enabled by such features.
                      </li>
                    </ul>
                </li>
                <li>
                    <Typography color={light.textColor2} className={classes.textStyle}>
                      You must not:
                    </Typography>
                    <ul>
                      <li>
                        Modify copies of any materials from the Site.
                      </li>
                      <li>
                        Use any illustrations, photographs, video or audio sequences or any graphics separately from the accompanying text.
                      </li>
                      <li>
                        Delete or alter any copyright, trademark, or other proprietary rights notices from copies of materials from this site.
                      </li>
                      <li>
                        Reverse engineer, decompile, disassemble, reproduce, or create any derivative works from any portion or subset of the Site or any content thereon.
                      </li>
                    </ul>
                </li>
                <li>
                  If you print, copy, modify, download or otherwise use or provide any other person with access to any part of the Site 
                  in violation of these Terms, Your right to use the Site will cease immediately and You must, at our option, return or 
                  destroy any copies of the materials You have made. No right, title, or interest in or to the Site or any content on 
                  the Site is transferred to you, and all rights not expressly granted are reserved by Us. Any use of the Site not expressly 
                  permitted by these Terms is a breach of these Terms and may violate copyright, trademark, and other laws.
                </li>
                <li>
                  Enduvo’s name and logo, and all related names, logos, product, and service names, designs, and slogans are trademarks of 
                  Us or Our licensors. You must not use such marks without the prior written permission of Us. All other names, logos, product, 
                  and service names, designs and slogans on the Site are the trademarks of their respective owners.
                </li>
              </ul>
            </Stack>
            <Stack>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontWeight: '600',   }}>
                Your Intellectual Property.
              </Typography>
              <ul className={classes.textStyle}>
                  <li>
                    You hereby grant Enduvo a non-exclusive right and license to reproduce, distribute, publicly perform, offer, market 
                    and otherwise use and exploit the Submitted Content through the Site, Software, Platform, and Services, and sublicense 
                    it to Users for these purposes directly or through third parties. Notwithstanding the foregoing, and subject to the 
                    Instructor Terms, if you are an Instructor, unless specifically stated otherwise, You have the right to remove all or 
                    any portion of Your Submitted Content from the Services at any time. Removal of Your Submitted Content will terminate 
                    the foregoing license and rights sixty (60) days after such removal as to new Users, provided, however, that any rights 
                    given to Users prior to that time will continue in accordance with the terms granted to such Users.
                  </li>
                  <li>
                    You hereby agree that We may record all or any part of any Courses (including voice chat communications) for quality 
                    control and delivering, marketing, promoting, demonstrating or operating the Services. You hereby grant 
                    Enduvo permission to use Your name, likeness, image or voice in connection with offering, delivering, marketing,
                     promoting, demonstrating, and selling the Services, Courses, Company Content and Submitted Content and waive any 
                     and all rights of privacy, publicity, or any other rights of a similar nature in connection therewith, 
                     to the extent permissible under applicable law.
                  </li>
                </ul>    
            </Stack>
            <Stack>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontWeight: '600',   }}>
                Third Party Intellectual Property.
              </Typography>
              <ul className={classes.textStyle}>
                  <li>
                    Enduvo has adopted the following general policy toward copyright infringement in accordance with the Digital Millennium 
                    Copyright Act (“DMCA”) and other intellectual property laws, rules, and regulations. As required by the DMCA, this site 
                    maintains specific contact information provided below, including an e-mail address, for notifications of claimed infringement 
                    regarding materials posted to this site. All notices should be addressed to the contact person specified below 
                    (our agent for notice of claimed infringement):
                  </li>
              </ul>    
            </Stack>
            <Stack sx={{ ml: 2 }}>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontStyle: 'italic'  }}>
                Notification of Claimed Infringement:
                </Typography>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontStyle: 'italic'  }}>
                Enduvo Inc.
                </Typography>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontStyle: 'italic'  }}>
                Attn: Copyright Infringement
                </Typography>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontStyle: 'italic'  }}>
                2601 W. Lake Ave.
                </Typography>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontStyle: 'italic' }}>
                Suite A6-110
                </Typography>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontStyle: 'italic' }}>
                Peoria, Illinois USA 61615
                </Typography>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontStyle: 'italic' }}>
                Telephone: 877.624.7838
              </Typography>
            </Stack>
            <Stack>
              <ul className={classes.textStyle}>
                  <li>
                    You may contact Our agent for notice of claimed infringement specified above with complaints regarding allegedly 
                    infringing posted material and we will investigate those complaints. If the posted material is believed in good 
                    faith by us to violate any applicable law, we will remove or disable access to any such material, and we will 
                    notify the posting party that the material has been blocked or removed.
                  </li>
                  <li>
                    In notifying Us of alleged copyright infringement, the DMCA requires that You include the following information: 
                    (i) description of the copyrighted work that is the subject of claimed infringement; 
                    (ii) description of the infringing material and information sufficient to permit us to locate the alleged material; 
                    (iii) contact information for You, including Your address, telephone number and/or e-mail address; 
                    (iv) a statement by You that You have a good faith belief that the material in the manner complained of is not 
                    authorized by the copyright owner, or its agent, or by the operation of any law; 
                    (v) a statement by You, signed under penalty of perjury, that the information in the notification is accurate and
                    that You have the authority to enforce the copyrights that are claimed to be infringed; and
                    (vi) a physical or electronic signature of the copyright owner or a person authorized to act on the copyright owner’s behalf. 
                    Failure to include all of the above-listed information may result in the delay of the processing of Your complaint.
                  </li>
              </ul>    
            </Stack>
          </Stack>
          <Stack>
            <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                7.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle}>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Notice of Claimed Infringement.
                </Typography>
                If You believe in good faith that any Content has been used in a manner that infringes a copyright that 
                You own or control, You may send a timely Notice of Claimed Infringement containing the following information:
              </Typography>
            </Stack>
            <Stack flexDirection="row">
              <ul className={classes.textStyle}>
                <li>
                  A physical or electronic signature of the copyright owner, or a person authorized to act on its behalf.
                </li>
                <li>
                  A description of the copyrighted work that You claim has been infringed upon.
                </li>
                <li>
                  A description of where the material that You claim is infringing is located on the Sites, the easiest way 
                  being by providing us with the URL(s) leading directly to the allegedly infringing Content.
                </li>
                <li>
                  Your mailing address, telephone number, and email address.
                </li>
                <li>
                  A statement by You that You have a good faith belief that the disputed use is not authorized by the copyright owner, its agent, or the law.
                </li>
                <li>
                  A statement, made under penalty of perjury, that the information contained in Your notice is accurate and that 
                  You are the copyright owner or authorized to act on the copyright owner’s behalf.
                </li>
                <li>
                  For claims of trademark infringement, send a notice to Enduvo’s Designated Agent containing the preceding information 
                  as applicable to Your trademark or service mark, including the mark, registration number if applicable, basis for 
                  Your trademark claim, jurisdiction in which You claim trademark rights, and class of goods or services and accompanying 
                  description for which You assert trademark rights. Please note that a copy of Your notice will be sent to the party 
                  who posted the content You report as infringing.
                </li>
                <li>
                  Once Proper Bona Fide Infringement Notification is Received by Enduvo’s Support Team, it is Enduvo’s policy to 
                  notify the Content provider or user that an infringement notification has been received and that the Content 
                  will be removed at the end of 24 hours unless a counter-notice is supplied to Enduvo. If no legitimate counter-notice 
                  is supplied, the infringing content will be removed from the system.
                </li>
                <li>
                  Procedure to Supply a Counter-Notice to Enduvo. If the Content provider, or user believes that the Content that 
                  was removed or to which access was disabled is either not infringing, or the Content provider or user believes 
                  that it has the right to post and use such Content from the copyright owner, the copyright owner’s agent, 
                  or pursuant to the law, the Content provider or user must send a counter-notice containing the following 
                  information to Enduvo’s compliance Team listed below:
                </li>
                <li>
                  A physical or electronic signature of the Content provider or user.
                </li>
                <li>
                  Identification of the Content that is claimed to be infringing
                </li>
                <li>
                  A statement that the Content provider or user has a good faith belief that the infringement notification was 
                  received as a result of mistake or a misidentification of the Content.
                </li>
                <li>
                  Content provider’s or user’s name, address, telephone number, and, if available, e-mail address and a statement 
                  that such person or entity consents to the jurisdiction of the Federal Court for the judicial district in which 
                  the Content provider’s or user’s address is located, or if the Content provider’s or user’s address is located 
                  outside the United States, for any judicial district in which Enduvo is located, and that such person or entity 
                  will accept service of process from the person who provided notification of the alleged infringement.
                </li>
                <li>
                  If a counter-notice is received by Enduvo’s compliance Team, Enduvo will restore access to the Content and notify 
                  the original complaining party. The Content will then remain published unless the copyright owner files an action 
                  seeking a court order against the Content provider or user.
                </li>
              </ul>
            </Stack>
          </Stack>
          <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                8.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle}>
                Unless set forth otherwise in an order document entered in connection with these Terms, the term of any order 
                shall be for one (1) year, which term shall automatically renew for successive one (1) year terms, unless either 
                party provides at least thirty (30) days prior written notice to the other party prior to a renewal date of such 
                party’s intent not to renew such order.
              </Typography>
          </Stack>
          <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                9.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle}>
                We may, in our sole and absolute discretion and without notice or liability to You or any third party, immediately suspend, 
                limit Your access to and/or terminate Your license and access to the Sites, Software, Platform, and Services, or any feature 
                thereof, for any reason, including, without limitation, any of the following reasons: (a) if we believe in our sole 
                discretion that You have violated these Terms or any applicable laws or regulations; (b) at the request of law enforcement, 
                government agencies, or courts; (c) if unexpected technical or security issues or problems arise; or (d) if we believe in our 
                sole discretion that Your use or access of any of our Sites, Software, Platform, or Services, may create risk 
                (including but not limited to legal risk) for us, our affiliates, contractual partners, or users. Enduvo shall not be 
                liable to You nor any third-party for any termination of Your account or access to the Enduvo
                Service.              
            </Typography>
          </Stack>
          <Stack flexDirection="row">
            <Typography color={light.textColor2} className={classes.textStyle}>
              10.
            </Typography>
            <Typography color={light.textColor2} className={classes.textStyle}>
              <Typography sx={{ fontWeight: 'bold' }}>
                Outstanding Obligations.
              </Typography>
              Any suspension or termination shall not affect Your obligations to us under these Terms. The provisions of these 
              Terms which by their nature should survive the suspension or termination of Your use of the Sites, Software, Platform, 
              or Services, shall survive including, but not limited to, the indemnities, releases, disclaimers, limitations on liability, 
              provisions related to choice of law, arbitration, and dispute resolution, no class action, no trial by jury, 
              and all of the miscellaneous provisions set forth below.
            </Typography>
          </Stack>
          <Stack flexDirection="row">
            <Typography color={light.textColor2} className={classes.textStyle}>
              11.
            </Typography>
            <Typography color={light.textColor2} className={classes.textStyle}>
              <Typography sx={{ fontWeight: 'bold' }}>
                Subscription Termination.
              </Typography>
              Your Subscription commences on the date You create and/or purchase a Subscription, and will continue for the period set 
              forth in the applicable Subscription, unless terminated in accordance with this Agreement. Following the term set forth 
              in the Subscription (i.e. monthly or annual), this Agreement is automatically renewable for additional terms until 
              You cancel Your Subscription by providing written notice to Enduvo Courses at: terminate@enduvo.com. For monthly plans, 
              You may terminate Your Subscription at any time and You will not be charged any additional monthly fees. For annual plans, 
              if You attempt to cancel Your Subscription early, the Service will remain available to You – and You will remain obligated 
              for all payments – until the end of the then-current term.
            </Typography>
          </Stack>
          <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                12.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle}>
                YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SOFTWARE AND RECEIPT OF SERVICES IS AT YOUR SOLE RISK. 
                THE SOFTWARE AND SERVICES ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITH ALL FAULTS. WITHOUT LIMITING 
                THE FOREGOING AND TO THE FULLEST EXTENT OF THE LAW, THE INDEMNIFIED PARTIES EXPRESSLY DISCLAIM ALL WARRANTIES OF 
                ANY KIND, INCLUDING BUT NOT LIMITED TO ANY: (A) WARRANTIES THAT THE SOFTWARE WILL MEET YOUR REQUIREMENTS; 
                (B) WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, SECURITY, USEFULNESS, TIMELINESS, OR INFORMATIONAL 
                CONTENT OF THE SOFTWARE; (C) WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE; AND 
                (D) WARRANTIES THAT THE SOFTWARE WILL BE ERROR-FREE OR THAT ERRORS IN THE SOFTWARE WILL BE CORRECTED. NO ADVICE OR INFORMATION, 
                WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM US OR THROUGH OR FROM THE SOFTWARE SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED 
                IN THESE TERMS. The software and related services have not been evaluated by the Food and Drug Administration. 
                This software is not intended to diagnose, treat, cure, or prevent any disease. Without limiting the foregoing, 
                University of Illinois and OSF make no warranties or representations concerning the Software or any other materials, 
                services, information, or technology, and the University of Illinois expressly disclaims all such warranties and representations, 
                express or implied, including, but not limited to, any implied warranties of merchantability, fitness for a particular purpose, 
                title and non-infringement.              
              </Typography>
          </Stack>
          <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                13.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle}>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Limitation of Liability.
                </Typography>
                TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW, UNDER NO CIRCUMSTANCES, INCLUDING, WITHOUT LIMITATION, 
                THE NEGLIGENCE OF ANY PARTY, WILL THE INDEMNIFIED PARTIES BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, 
                OR EXEMPLARY DAMAGES, INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PROFITS, LOSS OF LOSS OF USE, DATA LOSS, OR OTHER 
                INTANGIBLE LOSSES (EVEN IF THE INDEMNIFIED PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM, 
                ARISING OUT OF, RELATING TO, OR IN ANY WAY CONNECTED WITH YOUR USE OF, OR YOUR INABILITY TO USE, THE SOFTWARE, UNDER ANY 
                THEORY OF LIABILITY. YOUR SOLE REMEDY, AND OUR AGGREGATE LIABILITY IN CONNECTION WITH THESE TERMS OR YOUR USE OF THE SOFTWARE 
                SHALL IN ALL EVENTS BE LIMITED TO ONE DOLLAR ($1.00). SOME JURISDICTIONS DO NOT ALLOW THE LIMITATION OR EXCLUSION OF LIABILITY 
                FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES, SO SOME OF THE ABOVE PROVISIONS MAY NOT APPLY TO YOU. IN SUCH JURISDICTIONS, OUR 
                LIABILITY IS LIMITED TO THE FULLEST EXTENT OF THE LAW. Without limiting the foregoing, under no circumstances will University 
                of Illinois of Illinois or OSF be liable for consequential, indirect, special, punitive or incidental damages or lost profits, 
                whether foreseeable or unforeseeable, based on claims of You or any other party arising out of breach or failure of express or 
                implied warranty, breach of contract, misrepresentation, negligence, strict liability in tort, failure of any remedy to achieve 
                its essential purpose, or otherwise. Notwithstanding the form (e.g., contract, tort, or otherwise) in which any legal or equitable 
                action may be brought, in no event will University of Illinois of Illinois and OSF be liable for damages or losses that exceed, 
                in the aggregate, the amount of fees paid by end user for the products that gave rise to such damages or losses for each respective 
                breach or series of related breaches, and end user agrees that it must first pursue and exhaust all remedies against Enduvo before 
                pursuing any remedy (if any) against University of Illinois of Illinois and OSF.
              </Typography>
          </Stack>
          <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                14.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle}>
                You agree to indemnify, defend, and hold Enduvo and its parents, subsidiaries, related companies, and controlled affiliates, 
                and its and their respective members, officers, directors, employees, agents, partners, licensors, service providers, 
                strategic partners, and distribution partners (collectively, the “Indemnified Parties”), harmless in connection with any claims, 
                liabilities, losses, damages, obligations, costs, and expenses (including but not limited to reasonable attorneys’ fees and costs) 
                (“Claims”) arising out of or relating to Your use of the Software, Your breach of these Terms, or Your acts and omissions relating 
                to any of the foregoing. We have the right to control the defense, settlement and/or other resolution of any Claims, at Your sole 
                cost and expense. You may not settle or otherwise resolve any Claim without our express written permission. 
              </Typography>
          </Stack>
          <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                15.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle}>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Data Collection.
                </Typography>
                We and our service providers and other partners may collect and process information from and/or about You when You use the 
                Software for any purpose unless specifically requested in writing not to do so. In addition, You will provide feedback to 
                Enduvo regarding Your use and experience using the Software as reasonably requested by Enduvo from time to time, and shall 
                cooperate with Enduvo in its marketing efforts, including without limitation allowing Enduvo to reference You as a customer 
                of Enduvo, and use Your logo in our marketing materials including our website unless specifically requested in writing not to do so.
              </Typography>
          </Stack>
          <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                15.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle}>
                <Typography sx={{ fontWeight: 'bold' }}>
                  Applicable Law, Jurisdiction, and Dispute Resolution.
                </Typography>
                All disputes not involving a claim for injunctive relief shall be resolved by binding arbitration using the then-current 
                commercial arbitration rules of JAMS (com). All arbitration and claims for equitable relief shall be resolved by either 
                binding arbitration or in a court of competent jurisdiction sitting in Cook County, Illinois, applying the laws of the 
                State of Illinois without regard to principles of conflicts of laws. By agreeing to these terms and all applicable addenda, 
                You irrevocably consent to the jurisdiction of any such court in any such suit, action, or proceeding, and to the laying of 
                venue in such court. You irrevocably waive any objection to the laying of venue of any such suit, action, or proceeding brought 
                in such courts and irrevocably waives any claim that any such suit, action, or proceeding brought in any such court has been 
                brought in an inconvenient forum.
              </Typography>
          </Stack>
          <Stack>
            <ul className={classes.textStyle}>
              <li>
                  <p>
                    <span style={{ fontWeight: 'bold' }} >No Waiver. </span>
                    No waiver of any breach of any provision of these Terms will be deemed a waiver of any preceding or succeeding breach 
                    or of any other provision of these Terms. No extension of time for performance of any obligations or acts will be 
                    deemed a extension of the time for performance of any other obligations or acts.
                  </p>
              </li>
              <li>
                  <p>
                    <span style={{ fontWeight: 'bold' }} >Severability. </span>
                    In the event that any one or more of the provisions contained herein shall, for any reason, be held to be invalid, 
                    illegal, or unenforceable in any respect, such invalidity, illegality, or unenforceability shall not affect any other 
                    provisions of these Terms, but these Terms shall be construed as if such invalid, illegal, or unenforceable provisions 
                    had never been contained herein, unless the deletion of such provision or provisions would result in such a material 
                    change so as to cause completion of the transactions contemplated herein to be unreasonable.
                  </p>
              </li>
              <li>
                <p>
                  <span style={{ fontWeight: 'bold' }} >No Contest. </span>
                    Our electronically or otherwise properly stored copy of these Terms will be deemed to be the true, complete, valid, 
                    authentic, and enforceable copy, and you agree that you will not contest the admissibility or enforceability of our copy of these 
                    Terms in connection with any action or proceeding arising out of or relating to these Terms.
                    We may assign our rights and duties under these Terms at any time to any third party without notice. You may not assign these Terms without Ourprior written consent.
                </p>
              </li>
              <li>
                  We may assign our rights and duties under these Terms at any time to any third party without notice. 
                  You may not assign these Terms without Ourprior written consent.
              </li>
              <li>
                  <p>
                    <span style={{ fontWeight: 'bold' }} >Third Party Beneficiaries. </span>
                    You acknowledge that the provisions of these Terms of Use are intended to inure to the benefit of University of Illinois and 
                    OSF as third party beneficiaries of these Terms of Use, entitled to enforce such provisions against you. You further acknowledge 
                    that University of Illinois and OSF accepts its third party beneficiary rights hereunder and that such rights will be deemed irrevocable.
                  </p>
              </li>
              <li>
                <p>
                    <span style={{ fontWeight: 'bold' }} >Period of Limitation. </span>
                    You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to 
                    the use of the Sites, Software, Platform, and Services, or these Terms must be filed within one (1) year after such claim or cause of action arose or be forever barred.
                </p>
              </li>
              <li>
                  <p>
                    <span style={{ fontWeight: 'bold' }} >Entire Agreement. </span>
                    These Terms of Use are the entire and exclusive agreement between Enduvo, Inc., and you regarding Enduvo’s Sites, Software, 
                    Platform, and Services, and these Terms of Use supersede and replace any prior agreements between Enduvo and You as to the 
                    Sites, Software, Platform, and Services, except as specifically set forth herein.
                  </p>
              </li>
            </ul>
          </Stack>
          <Stack spacing={2}>
            <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                17.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontWeight: 'bold' }}>
                Student Terms of Use Addendum
              </Typography>
            </Stack>
            <Stack>
              <Typography color={light.textColor2} className={classes.textStyle}>
                All Student users agree to all Terms in the Terms of Use and the following Addendum. In the event of a conflict between 
                this Addendum and the Enduvo Terms of Use, the conflict shall be resolved in favor of this Addendum:
              </Typography>
            </Stack>
            <Stack flexDirection="row">
              <ul className={classes.textStyle}>
                <li>
                  Enduvo provides a platform upon which Students may obtain content via Enduvo’s online marketplace. Enduvo is neither a course 
                  provider nor an educational institution. We do not hire or employ Instructors, nor are We responsible or liable for any 
                  interactions involved between the Instructors and the Students who purchase any content via our Sites, Software, Platform, 
                  or Services, with the exception of providing the technological means through which Instructors may broadcast and otherwise make available their Courses.
                </li>
                <li>
                  We are not liable for, and specifically disclaim liability for, any disputes, claims, losses, injuries, or damage of any 
                  kind that may arise out of or relating, directly or indirectly, to conduct of Instructors or Students, including, but not 
                  limited to, any Student’s reliance upon any information provided by an Instructor.
                </li>
                <li>
                  As stated in our Privacy Policy, Enduvo only provides Instructors with the following information about the Students 
                  enrolled in their Courses: (1) name, (2) email address, and (3) the Instructor’s Course in which the Student has enrolled. 
                  Enduvo does not disclose any other information about Students to Instructors, and Students assume full responsibility for 
                  the disclosure and use of any other personal information the Student chooses to disclose to any Instructor or any other 
                  User via the Sites, Software, Platform, or Services, which at all times shall be subject to the terms of our Privacy Policy.
                </li>
                <li>
                  We do not guarantee in any manner the reliability, validity, accuracy or truthfulness of any User Content. You also 
                  understand that by using the Sites, Software, Platform, and Services, you may be exposed to User Content that 
                  You consider offensive, indecent, or objectionable. Enduvo has no responsibility to keep such content from You 
                  and no liability for Your access or use of any User Content, to the extent permissible under applicable law.
                </li>
                <li>
                  The Services may give You access to links to third party platforms (“Third Party Platforms”), either directly or 
                  through Courses or Instructors. Enduvo does not endorse any of these Third Party Platforms and does not control 
                  them in any manner. Accordingly, Enduvo does not assume any liability associated with Third Party Platforms. 
                  You need to take appropriate steps to determine whether accessing a Third Party Platform is appropriate, and 
                  to protect Your personal information and privacy on such Third Party Platform.
                </li>
                <li>
                  We are not liable for any disputes, claims, losses, injuries, or damage of any kind that may arise out of or 
                  relating, directly or indirectly, to conduct of Instructors or Students, including, but not limited to, 
                  any Student’s reliance upon any information provided by an Instructor.
                </li>
              </ul>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                18.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontWeight: 'bold' }}>
                Instructor Terms of Use Addendum
              </Typography>
            </Stack>
            <Stack>
              <Typography color={light.textColor2} className={classes.textStyle}>
                All Instructor users agree to all Terms in the Terms of Use and the following Addendum. In the event of a conflict between this 
                Addendum and the Enduvo Terms of Use, the conflict shall be resolved in favor of this Addendum:
              </Typography>
            </Stack>
            <Stack flexDirection="row">
              <ul className={classes.textStyle}>
                <li>
                  As an Instructor, You are contracting directly with Enduvo, Inc., a Delaware corporation, in the United States of America. Additionally, 
                  although We may utilize other Enduvo subsidiaries to facilitate Your payments, Your agreement remains between You and Enduvo, Inc.
                </li>
                <li>
                  Enduvo provides a platform upon which Instructors may create, offer for sale, and administer Courses to Students in the context of Enduvo’s 
                  online marketplace. Enduvo is neither a Course provider nor an educational institution. We do not hire or employ instructors, nor are 
                  We responsible or liable for any interactions involved between the Instructors and the Students who purchase any content via our Sites, 
                  Software, Platform, or Services, with the exception of providing the technological means through which Instructors may broadcast and 
                  otherwise make available their Courses and, at each Instructor’s election, for processing payments through Enduvo’s payment gateway.
                </li>
                <li>
                  We are not liable for, and specifically disclaim liability for, any disputes, claims, losses, injuries, or damage of any kind that may 
                  arise out of or relating, directly or indirectly, to conduct of Instructors or Students, including, but not limited to, any Student’s 
                  reliance upon any information provided by an Instructor.
                </li>
                <li>
                  As stated in our Privacy Policy, Enduvo only provides Instructors with the following information about the Students enrolled in their 
                  Courses: (1) name, (2) email address, and (3) the Instructor’s Course in which the Student has enrolled. Enduvo does not disclose any 
                  other information about Students to Instructors, and Students assume full responsibility for the disclosure and use of any other personal 
                  information the Student chooses to disclose to any Instructor or any other User via the Sites, Software, Platform, or Services, 
                  which at all times shall be subject to the terms of our Privacy Policy.
                </li>
                <li>
                  Instructors do not have a direct contractual relationship with Students. The only information You will receive about Students is what 
                  is provided to You through the Services (“Student Related Data”). You understand and agree that You will indemnify Enduvo for any issues 
                  arising out of Your use of any Student Related Data.
                </li>
                <li>
                  We do not guarantee in any manner the reliability, validity, accuracy or truthfulness of any User Content. You also understand that by 
                  using the Sites, Software, Platform, and Services, you may be exposed to User Content that You consider offensive, indecent, or objectionable. 
                  Enduvo has no responsibility to keep such content from You and no liability for Your access or use of any User Content, 
                  to the extent permissible under applicable law.
                </li>
                <li>
                  The Services may give You access to links to third party platforms (“Third Party Platforms”), either directly or through Courses or Instructors. 
                  Enduvo does not endorse any of these Third Party Platforms and does not control them in any manner. Accordingly, Enduvo does not assume any 
                  liability associated with Third Party Platforms. You need to take appropriate steps to determine whether accessing a Third Party Platform 
                  is appropriate, and to protect Your personal information and privacy on such Third Party Platform.
                </li>
                <li>
                  The Service offers a proprietary online course creation platform that provides tools for Instructors to create, implement, and share 
                  online courses in a variety of disciplines for participation by interested Students. Users have the ability to both create Courses and 
                  access, sign up for, and participate in Courses created by other users. Instructors are free to set all Course fees, and other 
                  Course prerequisites or requirements. Students may sign up for any Courses in which they are eligible for, depending on Course criteria. 
                  Enduvo Courses is not responsible for and cannot be held liable for any Course criteria set by a Instructor. Students should contact 
                  Instructor directly with any questions or concerns relating to a particular Course. For more information on the Service, the different 
                  Course offerings, and to learn how to take full advantage of the Service’s capabilities.
                </li>
                <li>
                  As an Instructor, You represent, warrant, and covenant that:
                  <ul>
                    <li>
                      You will be responsible for all of Your Submitted Content. You further agree that You own or have the necessary licenses, rights, 
                      consents, and permissions, and the authority to authorize Enduvo, to reproduce, distribute, publicly perform (including by means of 
                      a digital audio transmission), publicly display, communicate to the public, promote, market and otherwise use and exploit any of 
                      Your Submitted Content on and through the Services in the manner contemplated by these Instructor Terms;
                    </li>
                    <li>
                      No Submitted Content shall infringe or misappropriate any intellectual property right of a third party;
                    </li>
                    <li>
                      You have the required qualifications, credentials and expertise, including without limitation, education, training, knowledge, 
                      and skill sets, to teach and offer the services You offer on Your Course and through the Services;
                    </li>
                    <li>
                      You will not post or provide any inappropriate, offensive, racist, hateful, sexist, pornographic, false, misleading, incorrect, 
                      infringing, defamatory or libelous content or information
                    </li>
                    <li>
                      You will not upload, post or otherwise transmit any unsolicited or unauthorized advertising, promotional materials, junk mail, spam, 
                      chain letters, pyramid schemes or any other form of solicitation (commercial or otherwise) through the Services or to any User;
                    </li>
                    <li>
                      You will not use the Services for any business other than for providing, teaching and instructional services to Students;
                    </li>
                    <li>
                      You will not engage in any activity that will require Enduvo to obtain any licenses from or pay any royalties to any third party, 
                      including, by way of example and not limitation, the payment of royalties for the public performance of any musical works or sound recordings;
                    </li>
                    <li>
                      You will not copy, modify, distribute, reverse engineer, deface, tarnish, mutilate, hack, or interfere with Company Content and/or 
                      the Services or operations thereof, except as permitted in these Instructor Terms;
                    </li>
                    <li>
                      You will not frame or embed the Services in a manner to embed a free coupon version of your course or other similar functionality 
                      intended to circumvent the Services;
                    </li>
                    <li>
                      You will not impersonate another person or gain unauthorized access to another person’s Account;
                    </li>
                    <li>
                      Your use of the Services are subject to Enduvo’s approval, which We may grant or deny in Our sole discretion;
                    </li>
                    <li>
                      You will not introduce any virus, worm, spyware or any other computer code, file or program that may or is intended to damage or 
                      hijack the operation of any hardware, software or telecommunications equipment, or any other aspect of the Services or operation 
                      thereof; scrape, spider, use a robot or other automated means of any kind to access the Services;
                    </li>
                    <li>
                      You will not interfere with or otherwise prevent other Instructors from providing their services or Courses;
                    </li>
                    <li>
                      You will maintain accurate Account information;
                    </li>
                    <li>
                      You shall respond promptly to Students and ensure a quality of service commensurate with the standards of Your industry 
                      and instruction services in general;
                    </li>
                    <li>
                      You will honor any enrollments through Enduvo at the price and time listed in the Service, and not to refuse enrollments or 
                      participation by any student for any discriminatory or other illegal reason.
                    </li>
                    <li>
                      You grant us the right to place advertisements on your content at our sole discretion.
                    </li>
                    <li>
                      You understand that, if you teach a class, your students will have the ability to post a review of this class. We cannot control 
                      the contents of any such review and will not be held responsible for any information or opinions that a user may include in any such review.
                    </li>
                    <li>
                      You may choose to remove content from your class and will continue to own such content, but we will continue to have the right 
                      to use that content and to provide it to students who have paid for or enrolled in your class.
                    </li>
                    <li>
                      Enduvo reserves the right to, but is under no obligation, to delete the listing of any class at any time and for any reason.
                    </li>
                    <li>
                      You are over the age of 18.
                    </li>
                  </ul>
                </li>
                <li>
                  <p>
                    <span style={{ fontWeight: 'bold' }} >Key Definitions. </span>
                    In this Addendum the following terms shall have the following meanings:
                  </p>
                  <ul>
                    <li>
                      “Course” means a series of lectures or lessons in a particular subject
                    </li>
                    <li>
                      “Student” means individual buying or consuming content
                    </li>
                  </ul>
                </li>
                <li>
                  European Union. In the event that the sale or delivery of a Course or any Submitted Content to any Student in the European Union 
                  is subject to any value added tax (“VAT”), under applicable law, Enduvo will collect and remit the VAT to the competent tax authorities 
                  for sales of such Courses or Submitted Content to Students in the European Union. Enduvo may at its Own discretion increase the Sale Price 
                  where Enduvo is of the view that VAT may be due and Enduvo will have a liability to account for such. You will indemnify and hold Enduvo 
                  harmless against any and all claims by any tax authority for any underpayment of VAT, and any penalties and/or interest thereon on 
                  Your Instructor Revenue or otherwise.
                </li>
                <li>
                  All Other Countries. For sales of any Courses or Submitted Content in countries other than the European Union, You are responsible for 
                  remitting the taxes to the appropriate taxing authority (which may be different to the tax authority in Your own location). 
                  Enduvo is unable to provide You with tax advice and You should consult Your own tax advisor.
                </li>
              </ul>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                19.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontWeight: 'bold' }}>
                Partner Terms of Use Addendum
              </Typography>
            </Stack>
            <Stack>
              <Typography color={light.textColor2} className={classes.textStyle}>
                All Partner users agree to all Terms in the Terms of Use and the following Addendum. In the event of a conflict between 
                this Addendum and the Enduvo Terms of Use, the conflict shall be resolved in favor of this Addendum:
              </Typography>
            </Stack>
            <Stack flexDirection="row">
              <ul className={classes.textStyle}>
                <li>
                  As a Content Provider You are contracting directly with Enduvo, Inc., a Delaware corporation in the United States of America. 
                  Additionally, although We may utilize other Enduvo subsidiaries to facilitate Your payments, Your contract remains between You and Enduvo, Inc.
                </li>
                <li>
                  Any version of these Partner Terms in a language other than English is provided for convenience and you understand
                   and agree that the English language will control if there is any conflict.
                </li>
                <li>
                  You should also carefully review our Privacy Policy before placing an order for products or services through
                   this Site athttps://enduvo.com/privacy-policy/
                </li>
                <li>
                  Enduvo provides a platform upon which Content Providers may create and offer for sale; images, videos, 3D Models, or Content Services 
                  to Instructors and Students in the context of Enduvo’s online marketplace. We are not responsible or liable for any interactions 
                  involved between the Content Providers and Instructors and/or Students who purchase assets or services from Content Providers.
                </li>
                <li>
                  We are not liable for any disputes, claims, losses, injuries, or damage of any kind that may arise out of or relate to conduct 
                  Content Providers, including, but not limited to, any copyright infringements.
                </li>
                <li>
                  Key Definitions. In this Addendum the following terms shall have the following meanings:
                  <ul>
                  <li>
                      “Content Provider” means individual or organization selling content
                    </li>
                    <li>
                      “Content/Assets/Content Assets” means images, videos, 3D Models, or Content Services
                    </li>
                    <li>
                      “Content Services” means services that individual or organization provides; e.g. 3D Modeling, Narration, Production Services
                    </li>
                  </ul>
                </li>
                <li>
                  You grant Enduvo a royalty-free, fully paid up, worldwide license to use (including, without limitation, the rights to: copy, 
                  distribute, transmit, publicly display, publicly perform, reproduce, edit, translate, sublicense and reformat Your Content) 
                  Your Content only in connection with the operation and promotion of Enduvo. You also grant Enduvo the right to publish Your name 
                  in connection with any such use. No compensation will be paid or due You with respect to Enduvo’s or its sublicense’s use of the 
                  materials as licensed above. Enduvo is under no obligation to post or use any materials You may provide, and may remove such materials 
                  at any time in Enduvo ‘s sole discretion. By posting messages, uploading files (including graphics), inputting data, submitting any 
                  feedback or suggestions, or engaging in any other form of communication with or through the Enduvo System, You represent and warrant 
                  that You own or otherwise control the rights necessary to do so and to grant Enduvo the license set forth above, and You will defend 
                  and indemnify Enduvo and its suppliers from any third party claim related to a breach of any of the foregoing representations or warranties.
                </li>
                <li>
                  You grant Enduvo permission to use screen shots of any Web page that contains a link to Enduvo or Enduvo HTML in Enduvo promotional materials, 
                  and represent and warrant that You have sufficient authority to grant the foregoing rights.
                </li>
                <li>
                  You can take your work down whenever you want. However, users that have purchased your content can continue to use it as long as their 
                  license is valid. All Enduvo Content published to the Site is subject to a minimum 12-month commitment.
                </li>
              </ul>
            </Stack>
          </Stack>
          <Stack spacing={2}>
            <Stack flexDirection="row">
              <Typography color={light.textColor2} className={classes.textStyle}>
                19.
              </Typography>
              <Typography color={light.textColor2} className={classes.textStyle} sx={{ fontWeight: 'bold' }}>
                Beta User Terms of Use Addendum [Only Applies to Beta Users]
              </Typography>
            </Stack>
            <Stack>
              <Typography color={light.textColor2} className={classes.textStyle}>
                By becoming a Beta User, you are agreeing to all the provisions. Included in these terms is your Addendum to:
              </Typography>
            </Stack>
            <Stack flexDirection="row">
              <ul className={classes.textStyle}>
                <li>
                  Actively use and evaluate the beta release
                </li>
                <li>
                  Provide the types of feedback requested and respond to questions
                </li>
                <li>
                  Give Enduvo all rights to any feedback you submit
                </li>
                <li>
                  Not disclose that you are participating in this test
                </li>
                <li>
                  Not show the materials to others
                </li>
                <li>
                  Not share copies, pictures, or videos of the test materials in any form
                </li>
                <li>
                  Remove/Uninstall the test materials if requested
                </li>
              </ul>
            </Stack>
            <Stack>
              <p className={classes.textStyle}>
                Participant is an individual or business that desires to participate in “beta testing”, which provides access to confidential materials 
                or services currently in development by Enduvo. Enduvo wishes to obtain the benefit of Participant’s services and reports as a beta 
                tester of Enduvo’s materials or service. In consideration of the premises, and of the mutual covenants and conditions, the parties, agree 
                to the following terms and conditions:
              </p>
            </Stack>
            <Stack flexDirection="row">
              <p className={classes.textStyle}>1.</p>
              <p className={classes.textStyle}>
                <span style={{ fontWeight: 'bold' }}>Beta Test Material.</span>
                Each material or service and documentation related to each such Beta Test Material and/or technology provided by Enduvo including, 
                but not limited to, specifications and other technical information, and all updates or revisions thereto, shall be referred to in the following provisions 
                as the “Beta Test Material.” The parties hereto agree that Participant shall be a beta tester for those Beta Test Materials and may be amended from time to time by Enduvo.
              </p>
            </Stack>
            <Stack flexDirection="row">
              <p className={classes.textStyle}>2.</p>
              <p className={classes.textStyle}>
                <span style={{ fontWeight: 'bold' }}>License.</span>
                Enduvo hereby grants Participant nonexclusive, nontransferable, nonsublicenseable, revocable, limited right to access and use the Services, 
                solely for the purpose of evaluating and testing the Beta Test Material for Enduvo.  The Beta Test Material may not be publicly disclosed, 
                sublicensed, sold, assigned, leased, loaned, or otherwise transferred by Participant to any third party for any reason.  
                All rights not expressly granted to Customer herein are reserved by Vendor and its licensors.
              </p>
            </Stack>
            <Stack>
              <Stack flexDirection="row">
                <p className={classes.textStyle}>3.</p>
                <p className={classes.textStyle} style={{ fontWeight: 'bold' }}>
                  Enduvo’s Obligations.
                </p>
              </Stack>
              <ol className={classes.textStyle}>
                  <li>Enduvo will deliver the Beta Test Material to Participant at Enduvo’s expense.</li>
                  <li>Enduvo will provide the support or warranty service for the Beta Test Material and no other support or services.</li>
                  <li>Enduvo has no obligation to develop or provide any updates or revisions to the Beta Test Material, and Enduvo reserves 
                    the right to alter or adjust performance specifications for the Beta Test Material as it deems necessary or desirable.
                  </li>
                  <li>Enduvo will provide the instructions, safety information, warnings or cautions concerning the Beta Test Material and no other.</li>
                  <li>Enduvo reserves the right to alter or change the Services or any portion of the Services at any time, in whole or in part, 
                    for any reason. You understand and agree that Enduvo is under no obligation to release a final Enduvo application to the general 
                    public or enterprise customer/prospects.
                  </li>
              </ol> 
            </Stack>
            <Stack>
              <Stack flexDirection="row">
                <p className={classes.textStyle}>4.</p>
                <p className={classes.textStyle} style={{ fontWeight: 'bold' }}>
                  Participant’s Obligations.
                </p>
              </Stack>
              <ol className={classes.textStyle}>
                  <li>
                    Participant agrees to test and evaluate the Beta Test Material as requested. Participant agrees to familiarize itself 
                    with the Beta Test Material information provided by Enduvo and to only use or test the Beta Test Material as directed. 
                    Participant will notify Enduvo of any and all functional flaws, errors, anomalies, and problems directly or indirectly 
                    associated with the Beta Test Material known to or discovered by Participant. In addition, Participant agrees to provide 
                    Enduvo with such reports via the methods designated by Enduvo and to promptly respond to any and all reasonable inquiries, 
                    questionnaires, surveys, and other test documents submitted to Participant by Enduvo.
                  </li>
                  <li>
                    If Participant is a company or other entity, Participant shall designate to Enduvo, in writing, an employee or representative 
                    who will serve as the single technical contact and who will be responsible for maintaining communication with the Enduvo. 
                    In addition, the address, where Participant will test the Beta Test Material, will be given to Enduvo in writing. If Participant 
                    changes either its technical contact person or address, it will promptly notify Enduvo in writing of such change.
                  </li>
                  <li>
                    Any feedback, ideas, modifications, suggestions, improvements, and the like made by Participant with respect to 
                    the Beta Test Material (“Supportive Information”) will be the property of Enduvo. Participant agrees to assign, and hereby assigns, 
                    all right, title, and interest worldwide in the Supportive Information and the related intellectual property rights to Enduvo and agrees 
                    to assist Enduvo, at Enduvo’s expense, in perfecting and enforcing such rights. Enduvo may disclose or use Supportive Information for 
                    any purposes whatsoever without any obligation to Participant.
                    <ol>
                      <li>
                        Beta Participant\’s will communicate bugs/issues/suggestions as they’re identified via https://enduvo.zendesk.com/hc/en-us/requests/new 
                        and will participate on (1-2) 30 minute feedback calls over the 4 week Beta period and will complete a brief web survey at the close of the program.
                      </li>
                    </ol>
                  </li>
                  <li>Participant agrees to promptly respond to any and all reasonable inquiries, questionnaires, surveys, and other test documents Enduvo submits to you.</li>
                  <li>
                    Participant is responsible for all usual and customary incidental costs you incur in the ordinary course arising from your participation in the Program 
                    (i.e. internet and phone service) and incurred during Participant’s possession of the Beta Test Material, unless otherwise agreed to in writing by both parties.
                  </li>
                  <li>
                    Participant is responsible for all activity occurring under Participant\’s User accounts and shall abide by all applicable local, state, national, and foreign, 
                    laws, treaties and regulations in connection with Participant\’s use of the Services, including those related to data privacy, data security and the transmission of technical or personal data.
                  </li>
                  <li>
                    Upon completion of the Beta test, participant agrees to provide material, statistics, or information that is not deemed confidential to Enduvo for use in press releases, customer testimonials, 
                    and as a reference in marketing and sales initiatives by Enduvo. Participant may be asked to provide a quote to Enduvo that may be used in a press release, nothing will be published without prior approval.
                  </li>
                  <li>
                    Any breach of Participant\’s obligations under this Addendum will be deemed a material breach.
                  </li>
              </ol> 
            </Stack>
            <Stack>
              <Stack flexDirection="row">
                <p className={classes.textStyle}>5.</p>
                <p className={classes.textStyle} style={{ fontWeight: 'bold' }}>
                  Confidentiality
                </p>
              </Stack>
              <ol className={classes.textStyle}>
                  <li>
                    Participant acknowledges that as a beta tester, Participant may have access to, and Enduvo may disclose to Participant, certain valuable 
                    information belonging to and relating to Enduvo which Enduvo considers confidential, including, but not limited to, information concerning 
                    the Beta Test Material, the Beta Test Material’s trademark(s) and trade name(s), computer programs, user manuals, sales and marketing plans, 
                    business plans, processes, customer lists, and other trade secrets (“Confidential Information”). Participant shall use the Confidential Information 
                    solely for testing purposes and, for a period of three (3) years from Participant’s receipt of the Confidential Information, shall not disclose, 
                    without Enduvo’s written consent, such Confidential Information to third parties or use such Confidential Information for its own benefit or for 
                    the benefit of third parties.
                  </li>
                  <li>
                    If Participant is a company or other entity, Participant shall designate to Enduvo, in writing, an employee or representative 
                    who will serve as the single technical contact and who will be responsible for maintaining communication with the Enduvo. 
                    In addition, the address, where Participant will test the Beta Test Material, will be given to Enduvo in writing. If Participant 
                    changes either its technical contact person or address, it will promptly notify Enduvo in writing of such change.
                  </li>
                  <li>
                    If Participant is a company or other entity, Participant shall disclose Confidential Information only to those of its employees who need to 
                    know such information for the purpose of the agreed-upon beta testing, and shall ensure that its employees observe the confidentiality obligations 
                    in this Section 5. Participant acknowledges that the Beta Test Material contains Confidential Information developed or acquired by Enduvo and that 
                    all rights therein and in other Enduvo Confidential Information remain in Enduvo. Participant will not disclose that it is evaluating or testing or 
                    has evaluated or tested the Beta Test Material to any third party without Enduvo’s prior written consent. In addition, Participant agrees to treat any 
                    communications and reports prepared under this Addenum, including, but not limited to, those prepared in accordance with Section 4, as Confidential 
                    Information and will not divulge the existence or content of such communications or reports to any third party without Enduvo’s prior written consent.
                  </li>
                  <li>
                    This Addendum shall impose no obligation of confidentiality upon Participant with respect to any portion of the Confidential Information which: 
                    (i) now or hereafter, through no act or failure to act on Participant’s part, becomes generally known or available; 
                    (ii) is known to Participant at the time Participant receives same from Enduvo as evidenced by written records; 
                    (iii) is hereafter furnished to Participant by a third party as a matter of right and without restriction on disclosure.
                  </li>
              </ol> 
            </Stack>
            <Stack>
              <Stack flexDirection="row">
                <p className={classes.textStyle}>6.</p>
                <p className={classes.textStyle} style={{ fontWeight: 'bold' }}>
                  Proprietary Rights; No Right to Copy, Modify, or Disassemble.
                </p>
              </Stack>
              <ol className={classes.textStyle}>
                  <li>
                    The Beta Test Material provided by Enduvo and all copies thereof, are proprietary to and the property of Enduvo. All applicable 
                    rights in all copyrights, trademarks, trade secrets, trade names, patents and other intellectual property rights in or associated 
                    with the Beta Test Material are and will remain in Enduvo and Participant shall have no such intellectual property rights in 
                    the Beta Test Material. Participant Material(s) provided by participant such as; 3D Models, Graphics, Videos, ownership will remain
                     the Participant’s and Enduvo will not assume rights to those Materials.
                  </li>
                  <li>
                    Participant may not copy or reproduce the Beta Test Material without Enduvo’s prior written consent, except as reasonably needed 
                    to perform its obligations hereunder and subject to the following restrictions. Participant may not copy or reproduce any software 
                    or documentation provided by Enduvo, without Enduvo’s prior written consent, except as is reasonably needed to perform Participant’s 
                    obligations under this Addendum. Each copy of software or documentation made by Participant must contain Enduvo’s proprietary and 
                    copyright notices in the same form as on the original. Participant shall not remove or deface any portion of any legend provided 
                    on any part of the Beta Test Material.
                  </li>
                  <li>
                    Participant agrees to secure and protect the Beta Test Material and all copies thereof in a manner consistent with the maintenance of 
                    Enduvo’s rights therein and to take appropriate actions by instruction or Addendum with any of its employees or agents permitted access 
                    thereto to satisfy its obligations hereunder.
                  </li>
                  <li>
                    Participant shall not reverse engineer, alter, modify, disassemble or decompile the Beta Test Material, or any part thereof, without Enduvo’s prior written consent.
                  </li>
              </ol> 
            </Stack>
            <Stack flexDirection="row">
                <p className={classes.textStyle}>7.</p>
                <p className={classes.textStyle}>
                  <span className={classes.textStyle} style={{ fontWeight: 'bold' }}>
                  Disclaimer of Warranty.
                  </span>
                   By its nature, the Beta Test Material may contain errors, bugs and other problems that could cause system failure and the testing and 
                  quality assurance of the Beta Test Material may not yet be completed. Because the Beta Test Material is subject to change, Enduvo reserves 
                  the right to alter the Beta Test Material at any time, and any reliance on the Beta Test Material is at Participant’s own risk. 
                  PARTICIPANT ACCEPTS THE BETA TEST MATERIAL “AS IS.” ENDUVO MAKES NO WARRANTY OF ANY KIND REGARDING THE BETA TEST MATERIAL. ENDUVO HEREBY EXPRESSLY 
                  DISCLAIMS ALL IMPLIED AND STATUTORY WARRANTIES, INCLUDING, BUT NOT LIMITED TO, ANY IMPLIED WARRANTY OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, 
                  OR NON-INFRINGEMENT OF THIRD PARTIES RIGHTS.
                </p>
            </Stack>
            <Stack flexDirection="row">
                <p className={classes.textStyle}>8.</p>
                <p className={classes.textStyle}>
                  You agree that you shall not disparage Enduvo, or make any statement or comment, whether oral or written, in any medium (including in social media) 
                  about or concerning Enduvo, especially, but in no way by limitation, if such a statement or comment is intended to or could have a disparaging or 
                  negative interpretation or effect on Enduvo’s business, goodwill, reputation, products or services. You understand and agree that if you violate 
                  this provision, you will be liable to Enduvo immediately and without further notice, for any and all Damages arising therefrom.
                </p>
            </Stack>
            <Stack>
              <Stack flexDirection="row">
                <p className={classes.textStyle}>9.</p>
                <p className={classes.textStyle} style={{ fontWeight: 'bold' }}>
                  Term and Termination.
                </p>
              </Stack>
              <ol>
                  <li className={classes.textStyle}>
                    The term of this Addendum shall begin on the date (“Term Date”) set forth above (or, if no date is given, then the date both parties accept 
                    this Addendum by written signature) and shall continue until terminated as set forth below. Upon termination of this Addendum for any reason, 
                    the three-year obligation to protect Confidential Information, as set forth in Section 5, shall survive such termination.
                  </li>
                  <li className={classes.textStyle}>
                    This Addendum may be terminated at any time for any reason by either party giving ten days prior written notice to the other party. 
                    A particular Beta Test Material Addendum shall automatically terminate upon the general release to the public of the final product derived 
                    from the Beta Test Material by Enduvo, or sooner upon ten days prior written notice by either party. The parties acknowledge that Enduvo is 
                    under no obligation to release any final product or Beta Test Material to the public.
                  </li>
                  <li className={classes.textStyle}>
                    Upon termination of this Addendum or a particular Beta Test Material Addendum, Participant agrees to (a) remove/uninstall the Beta Test Material 
                    and all copies thereof to Enduvo, if requested by Enduvo in writing to do so, within seven days after such termination, or (b) if requested by 
                    Enduvo to do so, certify to Enduvo in writing that the Beta Test Material and all copies thereof have been destroyed, or 
                    (c) purchase the Beta Test Material as set forth in Section 4. The provisions of, and the obligations of the parties under, 
                    Sections 4, 5, 6, 7, 8 and 9, and any other provisions that would normally survive, shall survive the termination of this Addendum.
                  </li>
              </ol> 
            </Stack>
            <Stack flexDirection="row">
                <p className={classes.textStyle}>10.</p>
                <p className={classes.textStyle}>
                  <span className={classes.textStyle} style={{ fontWeight: 'bold' }}>
                    Limitation of Liability.
                  </span>
                  IN NO EVENT SHALL ENDUVO BE LIABLE TO PARTICIPANT OR ANY THIRD PARTY FOR ANY GENERAL, INCIDENTAL, CONSEQUENTIAL, INDIRECT, DIRECT, SPECIAL, 
                  OR PUNITIVE DAMAGES, ARISING OUT OF OR RELATING TO THE BETA TEST MATERIAL OR THE TRANSACTIONS CONTEMPLATED HEREIN.
                </p>
            </Stack>
            <Stack flexDirection="row">
                <p className={classes.textStyle}>11.</p>
                <p className={classes.textStyle}>
                  <span className={classes.textStyle} style={{ fontWeight: 'bold' }}>
                    Exporting Restrictions; United States Government Legends.
                  </span>
                  Beta Test Materials, including Software, provided to Participant may be subject to United States Export Restrictions. 
                  Participant agrees not to export or re-export any Beta Test Material or accompanying documentation in violation of any applicable laws and 
                  regulations of the United States or the country in which Participant obtained them. The software, firmware or other parts of 
                  the Beta Test Material covered by this Addendum may contain strong data encryption code, which cannot be exported outside the United States or Canada. 
                  Participant agrees not to export or re-export, either physically or electronically, an encrypted Beta Test Material or accompanying documentation 
                  without obtaining written authorization from the U.S. Department of Commerce.
                </p>
            </Stack>
            <Stack flexDirection="row">
                <p className={classes.textStyle}>12.</p>
                <p className={classes.textStyle}>
                  <span className={classes.textStyle} style={{ fontWeight: 'bold' }}>
                    Waiver.
                  </span>
                  A waiver of any default hereunder or of any of the terms and conditions of this Addendum shall not be deemed to be a continuing waiver 
                  or a waiver of any other default or of any other term or condition, but shall apply solely to the instance to which such waiver is directed.
                </p>
            </Stack>
            <Stack flexDirection="row">
                <p className={classes.textStyle}>13.</p>
                <p className={classes.textStyle}>
                  <span className={classes.textStyle} style={{ fontWeight: 'bold' }}>
                    Assignment; Severability.
                  </span>
                  Participant agrees not to assign any rights under this Addendum; any attempted assignment shall be null and void and shall result in the 
                  termination of this Addendum. If any part of this Addendum t shall be invalid or unenforceable, such invalidity or unenforceability shall 
                  not affect the validity or enforceability of any other part or provision of this Addendum which shall remain in full force and effect.
                </p>
            </Stack>
            <Stack flexDirection="row">
                <p className={classes.textStyle}>14.</p>
                <p className={classes.textStyle}>
                  <span className={classes.textStyle} style={{ fontWeight: 'bold' }}>
                    Governing Law.
                  </span>
                  The validity, interpretation, and performance of this Addendum shall be controlled by and construed under the laws of the State of Illinois, 
                  as if performed wholly within Illinois and without giving effect to the principles of conflicts of laws of such State. The parties agree that 
                  the federal or state courts located in the State of Illinois where Enduvo is organized shall have exclusive jurisdiction to hear any dispute under this Addendum.
                </p>
            </Stack>
            <Stack flexDirection="row" >
                <p className={classes.textStyle}>15.</p>
                <p className={classes.textStyle}>
                  <span className={classes.textStyle} style={{ fontWeight: 'bold' }}>
                    Entire.
                  </span>
                  This Addendum hereto for certain Beta Test Materials represent the entire Addendum between the parties regarding the subject matter hereof 
                  and supersede any and all prior Addendums between the parties, whether written or oral, regarding the subject matter hereof. 
                  This Addendum may not be modified or amended except by the written acceptance of both parties.
                </p>
            </Stack>
          </Stack>
          <Typography className={classes.subHeading}>Updated July 14, 2021</Typography>
          <Typography className={classes.subHeading}>Enduvo Reserves the Right to update</Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
