import { useEffect, useState, useMemo } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { providerLogin } from "../../features/auth/auth.slice";
import { useAppDispatch } from "../../app/hooks";

const ProviderLoginRedirectPage = () => {

  const useQuery = () => {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
  }

  const [success, setSuccess] = useState("true");
  const query = useQuery();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const token = query.get("token");
    if (token) dispatch(providerLogin({ token }));
  }, []);

  const RedirectToLaunchApp = () => {
    const query = useQuery();
    const success = query.get("success");
    return <Redirect push to={`/sso?success=${success}`}></Redirect>;
  };

  return <RedirectToLaunchApp />;
};

export default ProviderLoginRedirectPage;
