import React, { useState, useRef, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowOutlined";
import Modal from "@mui/material/Modal";
import { LoginPage } from "../../pages/LoginPage/index";
import { SignUpPage } from "../../pages/SignUpPage/index";
import { ForgotPassword } from "../../pages/ForgotPassword";
import { EnduvoButton } from "../../components/EnduvoButton";
import classes from "./styles.module.css";
import { light } from "../../css/color";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "976px",
  // height: '673px',
  bgcolor: "#FFFFFF", //'#282C30', //'background.paper',
  border: "2px solid #000",
  background: "#FFFFFF",
  outline: "none !important",
  borderRadius: 5,
  boxShadow: 24,
};
const images = [
  { value: "/3d/RocketEngine.glb" },
  { value: "/3d/Dental_Trainer_Model_v5.glb" },
  { value: "/3d/red_running_shoes.glb" },
  { value: "/3d//BMW_801.glb" },
  { value: "/3d/heart_with_cut.glb" },
];
const useCaseImages = [
  { value: "/images/dapp-home-usecase-item1.png" },
  { value: "/images/dapp-home-usecase-item2.png" },
  { value: "/images/dapp-home-usecase-item3.png" },
];
const onFocused = {
  width: "19.26px",
  height: "19px",
  borderRadius: "50%",
  background: light.buttonBgColor,
  cursor: "pointer",
};
const notFocused = {
  width: "19.26px",
  height: "19px",
  background: "#464e54",
  borderRadius: "50%",
  cursor: "pointer",
};
const responsive = {
  0: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

export const HomePage = () => {
  const [openLogin, setOpenLogin] = useState(false);
  const [openSignUp, setopenSignUp] = useState(false);
  const [openResetPassword, setResetPasswordOpen] = useState(false);

  useEffect(() => {
    if (window.location.href.endsWith("/early-access/signup")) setopenSignUp(true);
  }, []);

  const handleOpenLogin = () => setOpenLogin(true);
  const handleLoginClose = () => setOpenLogin(false);
  const history = useHistory();
  const handleOpenSignUp = () => {
    const token = localStorage.getItem("enduvo_token");
    if (token) {
      history.push("/download");
    } else setopenSignUp(true);
  };
  const handleSignUpClose = () => setopenSignUp(false);
  const handleResetPasswordOpen = () => setResetPasswordOpen(true);
  const handleResetPasswordClose = () => setResetPasswordOpen(false);
  const [currentImageIndex, setImageIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const updateCurrentSlide = (index: React.SetStateAction<number>) => {
    const currentSlide = currentImageIndex;
    if (currentSlide !== index) {
      setImageIndex(index);
    }
  };
  const slideNext = () => {
    activeIndex < items.length - 1 ? setActiveIndex(activeIndex + 1) : setActiveIndex(0);
    console.log(activeIndex);
  };
  const syncActiveIndex = (e: any) => {
    setActiveIndex(e.item);
  };
  // const items = useCaseImages.map((image, index) => {
  //   console.log(image);
  //   console.log(index);
  //   console.log(activeIndex);
  //   <Box className={classes.useCaseImg}>
  //     <img
  //       width={activeIndex == index ? "674px" : "273px"}
  //       height={activeIndex == index ? "458px" : "186px"}
  //       style={{ paddingLeft: "30px" }}
  //       src={image.value}
  //       alt={image.value}
  //     />
  //   </Box>;
  // });
  const items = [
    <Box className={classes.useCaseImg}>
      <img
        width={activeIndex == 0 ? "674px" : "273px"}
        height={activeIndex == 0 ? "458px" : "186px"}
        style={{ paddingLeft: activeIndex == 0 ? "0px" : "20px", paddingTop: activeIndex == 0 ? "0px" : "272px" }}
        src="/images/dapp-home-usecase-item1.png"
        alt="/images/dapp-home-usecase-item1.png"
      />
    </Box>,
    <Box className={classes.useCaseImg2}>
      <img
        width={activeIndex == 1 ? "674px" : "273px"}
        height={activeIndex == 1 ? "458px" : "186px"}
        style={{
          paddingLeft: activeIndex == 1 ? "0px" : "20px",
          paddingTop: activeIndex == 1 ? "0px" : "272px",
        }}
        src="/images/dapp-home-usecase-item2.png"
        alt="/images/dapp-home-usecase-item2.png"
      />
    </Box>,

    <Box className={classes.useCaseImg3}>
      <img
        width={activeIndex == 2 ? "674px" : "273px"}
        height={activeIndex == 2 ? "458px" : "186px"}
        style={{ paddingLeft: activeIndex == 2 ? "0px" : "20px", paddingTop: activeIndex == 2 ? "0px" : "272px" }}
        src="/images/dapp-home-usecase-item3.png"
        alt="/images/dapp-home-usecase-item3.png"
      />
    </Box>,
  ];
  // const createRef = useRef<HTMLDivElement>(null);
  // const shareRef = useRef<HTMLElement>(null);
  // const learnRef = useRef<HTMLElement>(null);
  // const handleScrollClick = (scrollTo: String) => {
  //   switch (scrollTo) {
  //     case "create":
  //       if (createRef.current) {
  //         createRef.current.scrollIntoView({ behavior: "smooth" });
  //       }
  //       break;
  //     case "share":
  //       if (shareRef.current) {
  //         shareRef.current.scrollIntoView({ behavior: "smooth" });
  //       }
  //       break;
  //     case "learn":
  //       if (learnRef.current) {
  //         learnRef.current.scrollIntoView({ behavior: "smooth" });
  //       }
  //       break;
  //     default:
  //       break;
  //   }
  // };
  return (
    <div
      style={{
        backgroundImage: light.bgColor,
      }}
    >
      <Box
        sx={{
          bgcolor: light.bgColor, //"#0F1213",
          fontFamily: "Product Sans",
          pt: 8,
          pb: 14,
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              position: "absolute",
              ml: "600px",
              mt: "200px",
              width: "447px",
              height: "347px",
              background: "rgba(0, 140, 255, 0.3)",
              filter: "blur(250px)",
            }}
          />
          <Box className={classes.objectLeft1}>
            <img
              height="413.28px"
              width="244.44px"
              src="/images/light/dapp-home-obj1.png"
              alt="/images/light/dapp-home-obj1.png"
            />
            <div className={classes.objectLeft2} style={{ background: light.objectColor1 }} />
          </Box>
          <div className={classes.objectLeft3} style={{ background: light.objectColor3 }} />
          <div className={classes.objectLeft4} style={{ background: light.objectColor2 }} />
          <div className={classes.objectLeft5} style={{ background: light.objectColor3 }} />
          <Box className={classes.objectLeft6}>
            <img height="413.28px" width="244.44px" src="/images/Vector 324.png" alt="/images/Vector 324.png" />
          </Box>
          <div className={classes.objectRight1} style={{ background: light.objectColor4 }} />
          <div className={classes.objectRight2} style={{ background: light.objectColor5 }} />
          <Box className={classes.objectRight4}>
            <img
              height="353.28px"
              width="404.44px"
              src="/images/light/dapp-home-obj3.png"
              alt="/images/light/dapp-home-obj3.png"
            />
          </Box>
          <Typography
            component="h1"
            // variant="h2"
            align="center"
            color={light.textColor1}
            gutterBottom
            sx={{ fontSize: "60px", lineHeight: "62px", fontWeight: "bold" }}
          >
            Your ideas are powerful.
            <br />
            You need a platform that can keep up.
          </Typography>
          <Typography
            sx={{ color: light.textColor2, fontSize: "28px", lineHeight: "27px" }}
            align="center"
            color="white"
            paragraph
          >
            Intuitive. Immersive. Transformative.
          </Typography>
          <Stack sx={{ pt: 4 }} direction="row" spacing={7} justifyContent="center">
            <EnduvoButton
              startIcon={<PlayArrowRoundedIcon />}
              action={handleOpenSignUp}
              buttonText="Try it now"
              width={null}
              height={null}
              disabled={false}
            />
            <Button component={NavLink} to="/" sx={{ color: light.textColor2, textTransform: "none" }}>
              <Typography sx={{ fontSize: "14px", lineHeight: "17px", fontWeight: "bold" }}>Learn More</Typography>
            </Button>
          </Stack>

          <Box
            sx={{
              mt: 8,
              mb: 8,
              p: 2,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box className={classes.blackobj5}>
              <img
                height="188px"
                width="237px"
                src="/images/light/dapp-home-blackobj.png"
                alt="/images/light/dapp-home-blackobj.png"
              />
            </Box>
            <Box className={classes.whiteobj}>
              <img height="224px" width="246px" src="/images/dapp-home-whiteobj.png" alt="/images/dapp-home-whiteobj.png" />
            </Box>
            <Box className={classes.objectRight6}>
              <img height="413.28px" src="/images/light/dapp-home-obj5.png" alt="/images/light/dapp-home-obj5.png" />
            </Box>
            <Box className={classes.objectRight5}>
              <img height="413.28px" src="/images/light/dapp-home-obj4.png" alt="/images/light/dapp-home-obj4.png" />
            </Box>
            <Box className={classes.blueBg} />
            <Box
              sx={{
                p: 2,
                width: "798px",
                height: "553px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backdropFilter: "blur(19px)",
                borderRadius: "20px",
              }}
            >
              <img height="515px" width="759px" src="/images/dapp-home-lap2.png" alt="/images/dapp-home-lap2.png" />
            </Box>
            <Box className={classes.purpleobj}>
              <img
                height="200px"
                width="200pxpx"
                src="/images/light/dapp-home-purpleobj.png"
                alt="/images/light/dapp-home-purpleobj.png"
              />
            </Box>
            <Box className={classes.blackobj}>
              <img
                height="327px"
                width="402px"
                src="/images/light/dapp-home-blackobj.png"
                alt="/images/light/dapp-home-blackobj.png"
              />
            </Box>
          </Box>

          <Stack sx={{ pt: 15 }} direction="row" spacing={6}>
            <Typography
              sx={{
                fontSize: "30px",
                lineHeight: "52px",
                paddingLeft: "20px",
                color: light.textColor3,
                fontWeight: "bold",
                paddingTop: "2px",
              }}
            >
              People retain only <br />
              10% of what they read, <br />
              20% of what they hear and <br />
              30% of what they see...
            </Typography>
            <Stack direction="column" sx={{ pl: 25 }}>
              <Typography
                sx={{
                  fontSize: "36px",
                  lineHeight: "62px",
                  color: light.textColor3,
                  fontWeight: "bold",
                }}
              >
                ...but they remember 90% of what <br />
                they do! <br />
              </Typography>
              <Typography
                sx={{
                  fontSize: "28px",
                  lineHeight: "40px",
                  color: light.textColor2,
                  paddingTop: "30px",
                }}
              >
                By unlocking experiental learning,
                <br />
                your audience will learn up to 4 x faster, <br />
                with higher confidence and recall.
              </Typography>
            </Stack>
          </Stack>
          <Stack sx={{ pt: 4, color: "white", mt: 8 }} direction="row" justifyContent="space-between" alignItems="center">
            <Typography sx={{ fontSize: "60px", lineHeight: "62px", fontWeight: "bold" }} className={classes.textBG}>
              01
            </Typography>
            <Box className={classes.lineSeperator} />
            <Typography sx={{ fontSize: "60px", lineHeight: "62px", fontWeight: "bold" }} className={classes.textBG}>
              02
            </Typography>
            <Box className={classes.lineSeperator} />
            <Typography sx={{ fontSize: "60px", lineHeight: "62px", fontWeight: "bold" }} className={classes.textBG}>
              03
            </Typography>
          </Stack>
          <Stack sx={{ pt: 6, color: "white" }} direction="row">
            <Stack sx={{ color: "white" }} direction="row" spacing={18} justifyContent="space-evenly" alignItems="flex-start">
              <div className={classes.options}>
                <Stack sx={{ pt: 4 }} direction="column" justifyContent="space-evenly" spacing={1} className={classes.options}>
                  {/* <div className={classes.createIcon}>
                    <img width="43px" height="45px" src="/images/light/create-icon.png" alt="/images/light/create-icon.png" />
                  </div> */}{" "}
                  <div>
                    <Stack
                      sx={{ color: "white", marginLeft: "25px" }}
                      direction="column"
                      spacing={2}
                      className={classes.createIcon}
                    >
                      <div className={classes.objectBox1} style={{ background: light.boxColor1 }} />
                      <Box className={classes.objectBox2}>
                        <img
                          height="206px"
                          width="223px"
                          src="/images/light/dapp-signup-box.png"
                          alt="/images/light/dapp-signup-box.png"
                        />
                      </Box>
                    </Stack>
                  </div>
                  <Typography sx={{ textAlign: "center", fontSize: "30px", lineHeight: "62px", pt: 4 }}>Create</Typography>
                  <Typography sx={{ textAlign: "center", fontSize: "20px", lineHeight: "27px", pb: 3 }}>
                    Easily produce interactive experiences with our drag-and-drop features.
                  </Typography>
                </Stack>
              </div>
              <div className={classes.options}>
                <Stack sx={{ pt: 4 }} direction="column" spacing={1} className={classes.options}>
                  {/* <div className={classes.createIcon}>
                    <img width="43px" height="43px" src="/images/light/share-icon.png" alt="/images/light/share-icon.png" />
                  </div> */}
                  <Stack sx={{ color: "white" }} direction="column" className={classes.createIcon} spacing={2}>
                    <div className={classes.objectBox1} style={{ background: light.boxColor1 }} />
                    <Box className={classes.elipse3} />
                    <Stack className={classes.buttonShare}>
                      <Stack
                        sx={{
                          color: "white",
                          height: "36px",
                          width: "95px",
                          ml: "100px",
                          textTransform: "none",
                          borderRadius: "5px",
                          background: light.buttonBgColor,
                          justifyContent: "center",
                        }}
                        alignItems="center"
                        flexDirection="row"
                      >
                        <img
                          height="18px"
                          width="18px"
                          src="/images/dapp-signup-shareIcon.png"
                          alt="/images/dapp-signup-shareIcon.png"
                        />
                        <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                          Share
                        </Typography>
                      </Stack>
                      <div className={classes.youtube}>
                        <img
                          height="15.86px"
                          width="21.88px"
                          src="/images/dapp-signup-youtube.png"
                          alt="/images/dapp-signup-youtube.png"
                        />
                          
                        <Typography sx={{ fontSize: "9px", whiteSpace: "nowrap", mt: "4px" }}>Youtube</Typography>
                      </div>
                      <div className={classes.Dropbox}>
                        <img
                          height="19.97px"
                          width="20.99px"
                          src="/images/dapp-signup-dropbox.png"
                          alt="/images/dapp-signup-dropbox.png"
                        />
                        <Typography sx={{ fontSize: "9px", whiteSpace: "nowrap", mt: "4px" }}>Dropbox</Typography>
                      </div>
                      <div className={classes.GoogleDrive}>
                        <img
                          height="19.94px"
                          width="23.85px"
                          src="/images/dapp-signup-googledrive.png"
                          alt="/images/dapp-signup-googledrive.png"
                        />
                        <Typography sx={{ fontSize: "8px", whiteSpace: "nowrap", mt: "4px" }}>Google Drive</Typography>
                      </div>
                      <div className={classes.OneDrive}>
                        <img
                          height="14.42px"
                          width="22.5px"
                          src="/images/dapp-signup-onedrive.png"
                          alt="/images/dapp-signup-onedrive.png"
                        />
                        <Typography sx={{ fontSize: "9px", whiteSpace: "nowrap", mt: "4px" }}>OneDrive</Typography>
                      </div>
                    </Stack>
                  </Stack>
                  <Typography sx={{ textAlign: "center", fontSize: "30px", lineHeight: "62px", pt: 4 }}>Share</Typography>
                  <Typography sx={{ textAlign: "center", fontSize: "20px", lineHeight: "27px", pb: 3 }}>
                    Distribute your knowledge with anyone inside or outside your organization.
                  </Typography>
                </Stack>
              </div>
              <div className={classes.options}>
                <Stack sx={{ pt: 4 }} direction="column" spacing={1} className={classes.options}>
                  {/* <div className={classes.createIcon}>
                    <img
                      width="42.69px"
                      height="41.96px"
                      src="/images/light/learn-icon.png"
                      alt="/images/light/learn-icon.png"
                    />
                  </div> */}
                  <Stack sx={{ color: "white" }} direction="column" spacing={2} className={classes.createIcon}>
                    <Stack>
                      <Box className={classes.elipse3} />
                      <Box className={classes.lap1Border} />
                      <Box className={classes.lap2Border} />
                      <Box className={classes.lap1}>
                        <img
                          height="144.86px"
                          width="212.94px"
                          src="/images/dapp-signup-lap.png"
                          alt="/images/dapp-signup-lap.png"
                        />
                      </Box>
                      <Box className={classes.lap2}>
                        <img
                          height="86.3px"
                          width="126.85px"
                          src="/images/dapp-signup-lap2.png"
                          alt="/images/dapp-signup-lap2.png"
                        />
                      </Box>
                      <Box className={classes.vr}>
                        <img
                          height="111.56px"
                          width="176.56px"
                          src="/images/dapp-signup-vr.png"
                          alt="/images/dapp-signup-vr.png"
                        />
                      </Box>
                    </Stack>
                  </Stack>
                  <Typography sx={{ textAlign: "center", fontSize: "30px", lineHeight: "62px", pt: 4 }}>Experience</Typography>
                  <Typography sx={{ fontSize: "20px", lineHeight: "27px", pb: 3, textAlign: "center" }}>
                    Boost your understanding by engaging with experts around the globe.
                  </Typography>
                </Stack>
              </div>
            </Stack>
            <div className={classes.objectRight7} style={{ background: light.objectColor3, marginLeft: "15px" }} />
          </Stack>
        </Container>
        <Modal
          open={openSignUp || openLogin || openResetPassword}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                position: "absolute",
                marginTop: "15px",
                marginLeft: "930px",
                marginRight: "20px",
                cursor: "pointer",
                zIndex: 200,
              }}
              onClick={() => (openSignUp ? handleSignUpClose() : openLogin ? handleLoginClose() : handleResetPasswordClose())}
            >
              <img
                height="32px"
                width="32px"
                src="/images/dapp-signup-close1.png"
                alt="/images/dapp-signup-close1.png"
                className={classes.closeButton}
                onClick={() => (openSignUp ? handleSignUpClose() : handleLoginClose())}
              />
            </div>
            {openSignUp ? <SignUpPage closeModal={handleSignUpClose} openLoginModal={handleOpenLogin} /> : null}
            {openLogin ? (
              <LoginPage
                closeModal={handleLoginClose}
                openRegisterModal={handleOpenSignUp}
                getDownloadLinkUrl={handleOpenSignUp}
                openResetPasswordModal={handleResetPasswordOpen}
              />
            ) : null}
            {openResetPassword ? (
              <ForgotPassword
                closeModal={handleResetPasswordClose}
                openRegisterModal={handleOpenSignUp}
                openLoginModal={handleOpenLogin}
              />
            ) : null}
          </Box>
        </Modal>
      </Box>
      <div
        style={{
          backgroundImage: light.bgColor2,
          color: "white",
          marginTop: "-150px",
          paddingTop: "40px",
          paddingBottom: "20px",
        }}
      >
        <Container maxWidth="lg">
          <Typography sx={{ fontSize: "60px", lineHeight: "62px", fontWeight: "bold", textAlign: "center" }}>
            The world's most critical topics <br />
            are taught in Enduvo.
          </Typography>
          <Stack sx={{ pt: 6, color: "white" }} direction="row" spacing={2}>
            <Stack sx={{ width: "100%" }}>
              <Typography sx={{ fontSize: "28px", lineHeight: "31px", textAlign: "center" }}>
                From heart surgeons to aerospace engineers, Enduvo's easy-to-use platform
                <br /> makes complex subjects accessible to everyone.
              </Typography>
              <div className={classes.objectRight8}>
                <div
                  className={classes.objectRight7}
                  style={{
                    background: light.objectColor8,
                    transform: "rotate(0deg)",
                    width: "200px",
                    height: "40px",
                    marginBottom: "-228px",
                    marginTop: "130px",
                  }}
                />
                <img
                  height="228.18px"
                  width="393.25px"
                  src="/images/light/dapp-home-obj6.png"
                  alt="/images/light/dapp-home-obj6.png"
                />
                <div className={classes.objectRight9}>
                  <img
                    height="188.18px"
                    width="353.25px"
                    src="/images/light/dapp-home-obj7.png"
                    alt="/images/light/dapp-home-obj7.png"
                  />
                </div>
              </div>
            </Stack>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="flex-end" sx={{ mt: "100px", mb: "100px" }}>
            <div className={classes.elipse1} style={{ background: light.elipseColor1 }} />
            <AliceCarousel
              autoHeight
              autoWidth
              disableDotsControls
              disableButtonsControls
              activeIndex={activeIndex}
              onSlideChanged={syncActiveIndex}
              infinite
              responsive={responsive}
              items={items}
            />
            <Stack className={classes.createIcon1} sx={{ cursor: "pointer" }} onClick={slideNext}>
              <img width="43px" height="45px" src="/images/arrow.png" alt="/images/arrow.png" />
            </Stack>
          </Stack>
        </Container>
      </div>
      <Box
        sx={{
          color: "white",
          pb: 6,
        }}
      >
        <Container maxWidth="lg">
          <Stack sx={{ pb: 10 }}>
            <Box className={classes.vectorBG}>
              <img height="944px" width="100%" src="/images/3d.png" alt="/images/3d.png" />
            </Box>
            <Stack className={classes.vectorBGContainer}>
              <Stack spacing={9}>
                <Typography
                  sx={{
                    fontSize: "60px",
                    lineHeight: "62px",
                    textAlign: "center",
                    fontWeight: "bold",
                    color: light.textColor2,
                  }}
                >
                  Try it... <br />
                  You'll never go back.
                </Typography>
                <Typography
                  sx={{
                    fontSize: "28px",
                    lineHeight: "36px",
                    textAlign: "center",
                    color: light.textColor2,
                  }}
                >
                  Communicate your ideas with anyone in the world, at <br />
                  anytime, as though you were in the room with them.
                </Typography>
                <Stack direction="row" spacing={20} sx={{ pl: 15 }}>
                  <Stack spacing={2}>
                    {images.map((item, index) => (
                      <Box
                        key={index}
                        style={currentImageIndex == index ? onFocused : notFocused}
                        onClick={() => setImageIndex(index)}
                      ></Box>
                    ))}
                  </Stack>
                  <Stack sx={{ position: "absolute" }}>
                    <Carousel
                      autoPlay={true}
                      selectedItem={currentImageIndex}
                      interval={8000}
                      showIndicators={false}
                      showArrows={false}
                      showThumbs={false}
                      stopOnHover={true}
                      showStatus={false}
                      axis="vertical"
                      infiniteLoop={true}
                      onChange={updateCurrentSlide}
                    >
                      {images.map((item, index) => (
                        <div style={{ height: "584px", width: "584px" }}>
                          {/* @ts-ignore */}
                          <model-viewer
                            bounds="tight"
                            id="reveal"
                            loading="eager"
                            src={item.value}
                            camera-controls
                            style={{ width: "584px", height: "584px" }}
                            disable-zoom
                          >
                            <div className="progress-bar hide" slot="progress-bar"></div>
                            <div id="reveal-poster" slot="poster"></div>

                            {/* @ts-ignore */}
                          </model-viewer>
                          <div className={classes.gradientStyle}></div>
                        </div>
                      ))}
                    </Carousel>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="column" spacing={4} paddingTop={20}>
              <Stack sx={{ alignItems: "center" }}>
                {" "}
                <EnduvoButton
                  startIcon={<PlayArrowRoundedIcon />}
                  action={handleOpenSignUp}
                  buttonText="Start now for free"
                  width="650px"
                  height="76px"
                  disabled={false}
                />
              </Stack>
              <Stack spacing={4}>
                <Typography
                  sx={{
                    fontSize: "28px",
                    lineHeight: "36px",
                    color: light.textColor2,
                    paddingTop: "30px",
                  }}
                >
                  Create and share your immersive experiences for free.
                  <br />
                  Get access to the same easy-to-use features used by the world's most critical educators.
                </Typography>
              </Stack>
              {/* <Stack sx={{ alignItems: "center", paddingTop: "236px" }}>
                <img src="/images/light/Image.png" alt="/images/light/Image.png" />
              </Stack> */}
            </Stack>
          </Stack>
          <Box sx={{ pb: 6 }}>
            <Stack className={classes.contact} style={{ background: light.bgColor3 }} direction="row">
              <Stack direction="row" spacing={7}></Stack>
              <Box className={classes.contactBg1} />
            </Stack>
          </Box>
          <Box sx={{ pt: 8, pb: 12 }}>
            <Box className={classes.companyBg1} />
            <Box className={classes.companyBg2} />
            <Box className={classes.companyBg3} />
            <Stack className={classes.company} direction="row">
              <Grid container rowSpacing={8} columnSpacing={5.5}>
                <div className={classes.companyImages}>
                  <img
                    style={{ WebkitFilter: "grayscale(1)", filter: "grayscale(1) contrast(40%) brightness(50%)" }}
                    height="53.3px"
                    width="129.73px"
                    src="/images/companyPngs/nstxl.png"
                    alt="/images/companyPngs/nstxl.png"
                  />{" "}
                </div>
                <div className={classes.companyImages}>
                  <img
                    style={{ WebkitFilter: "grayscale(1) contrast(90%)", filter: "grayscale(1) contrast(10%) brightness(50%)" }}
                    height="94px"
                    width="106.86px"
                    src="/images/companyPngs/nih.png"
                    alt="/images/companyPngs/nih.png"
                  />{" "}
                </div>
                <div className={classes.companyImages}>
                  <img
                    style={{ WebkitFilter: "grayscale(1) ", filter: "grayscale(1) " }}
                    height="30.73px"
                    width="129.73px"
                    src="/images/companyPngs/trideum.png"
                    alt="/images/companyPngs/trideum.png"
                  />
                </div>
                <div className={classes.companyImages}>
                  <img
                    style={{
                      WebkitFilter: "grayscale(1) contrast(90%)",
                      filter: "grayscale(1) contrast(100%) brightness(50%)",
                    }}
                    height="28.68px"
                    width="129.73px"
                    src="/images/companyPngs/exelon.png"
                    alt="/images/companyPngs/exelon.png"
                  />{" "}
                </div>
                <div className={classes.companyImages}>
                  <img
                    style={{
                      WebkitFilter: "grayscale(1) contrast(90%)",
                      filter: "grayscale(1) contrast(100%) brightness(50%)",
                    }}
                    height="48.1px"
                    width="129.73px"
                    src="/images/companyPngs/osf.png"
                    alt="/images/companyPngs/osf.png"
                  />{" "}
                </div>
                <div className={classes.companyImages}>
                  <img
                    style={{
                      WebkitFilter: "grayscale(1) contrast(90%)",
                      filter: "grayscale(1) contrast(100%) brightness(50%)",
                    }}
                    height="101.43px"
                    width="129.73px"
                    src="/images/companyPngs/USAF.png"
                    alt="/images/companyPngs/USAF.png"
                  />
                </div>
                <div className={classes.companyImages}>
                  <img
                    style={{
                      WebkitFilter: "grayscale(1) contrast(60%)",
                      filter: "grayscale(1) contrast(100%) brightness(50%)",
                    }}
                    height="72.99px"
                    width="129.73px"
                    src="/images/companyPngs/deloitte.png"
                    alt="/images/companyPngs/deloitte.png"
                  />{" "}
                </div>
                <div className={classes.companyImages}>
                  <img
                    style={{
                      WebkitFilter: "grayscale(1) contrast(100%)",
                      filter: "grayscale(1) contrast(100%) brightness(50%)",
                    }}
                    height="69.54px"
                    width="69.54px"
                    src="/images/companyPngs/hp.png"
                    alt="/images/companyPngs/hp.png"
                  />{" "}
                </div>
                <div className={classes.companyImages}>
                  <img
                    style={{
                      WebkitFilter: "grayscale(1) contrast(100%)",
                      filter: "grayscale(1) contrast(100%) brightness(50%)",
                    }}
                    height="69.54px"
                    width="69.54px"
                    src="/images/companyPngs/dell.png"
                    alt="/images/companyPngs/dell.png"
                  />{" "}
                </div>
                <div className={classes.companyImages}>
                  <img
                    style={{
                      WebkitFilter: "grayscale(1) contrast(100%) invert(1)",
                      filter: "grayscale(1) contrast(100%) brightness(100%)",
                    }}
                    height="25.95px"
                    width="129.73px"
                    src="/images/companyPngs/afwerx.png"
                    alt="/images/companyPngs/afwerx.png"
                  />{" "}
                </div>
                <div className={classes.companyImages}>
                  <img
                    style={{
                      WebkitFilter: "grayscale(1) contrast(100%) ",
                      filter: "grayscale(1)) contrast(100%) brightness(100%)",
                    }}
                    height="61.33px"
                    width="129.73px"
                    src="/images/companyPngs/columbiaUni.png"
                    alt="/images/companyPngs/columbiaUni.png"
                  />{" "}
                </div>
                <div className={classes.companyImages}>
                  <img
                    style={{
                      WebkitFilter: "grayscale(1) contrast(60%) ",
                      filter: "grayscale(1) contrast(70%) brightness(50%)",
                    }}
                    height="60.64px"
                    width="129.73px"
                    src="/images/companyPngs/vive.png"
                    alt="/images/companyPngs/vive.png"
                  />{" "}
                </div>
                <div className={classes.companyImages}>
                  <img
                    style={{
                      WebkitFilter: "grayscale(1) contrast(70%) ",
                      filter: "grayscale(1) contrast(100%) brightness(40%)",
                    }}
                    height="32.43px"
                    width="129.73px"
                    src="/images/companyPngs/illinoisUni.png"
                    alt="/images/companyPngs/illinoisUni.png"
                  />{" "}
                </div>

                <div className={classes.companyImages}>
                  <img
                    style={{
                      WebkitFilter: "grayscale(1) contrast(50%) ",
                      filter: "grayscale(1) contrast(100%) brightness(20%)",
                    }}
                    height="50.6px"
                    width="129.73px"
                    src="/images/companyPngs/csesw.png"
                    alt="/images/companyPngs/csesw.png"
                  />{" "}
                </div>
                <div className={classes.companyImages} style={{ marginLeft: "-20px" }}>
                  <img
                    style={{
                      WebkitFilter: "grayscale(1) contrast(100%) ",
                      filter: "grayscale(1) contrast(100%) brightness(60%)",
                    }}
                    height="129.73px"
                    width="129.73px"
                    src="/images/companyPngs/sbi.png"
                    alt="/images/companyPngs/sbi.png"
                  />{" "}
                </div>
                <div className={classes.companyImages}>
                  <img
                    style={{ WebkitFilter: "grayscale(1)", filter: "grayscale(1) contrast(100%) brightness(40%) " }}
                    height="27.18px"
                    width="129.73px"
                    src="/images/companyPngs/vrar.png"
                    alt="/images/companyPngs/vrar.png"
                  />{" "}
                </div>
                <div className={classes.companyImages}>
                  <img
                    style={{
                      WebkitFilter: "grayscale(1) contrast(100%) invert(1)",
                      filter: "grayscale(1) contrast(100%) brightness(120%) invert(1)",
                    }}
                    height="35.51px"
                    width="129.73px"
                    src="/images/companyPngs/dynepic.png"
                    alt="/images/companyPngs/dynepic.png"
                  />{" "}
                </div>
              </Grid>
            </Stack>
          </Box>
        </Container>
      </Box>
    </div>
  );
};
