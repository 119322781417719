import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../app/config";

export interface teams {
  status: string;
}

const initialState: teams = {
  status: "",
};

export interface VerifyInviteLinkBody {
  invite_key: string;
}

export const verifyInviteLink = createAsyncThunk("teams/verifyInviteLink", async (data: VerifyInviteLinkBody) => {
  const response = await axios.get(`${config.baseURL}/api/teams/verify-invite-link?invite_key=${data.invite_key}`);
  return response.data;
});

const teamsSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(verifyInviteLink.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(verifyInviteLink.fulfilled, (state, action) => {
      // if(action.payload.success){
      //   state.data = action.payload.data;
      // }
      state.status = "success";
    });
    builder.addCase(verifyInviteLink.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default teamsSlice.reducer;
