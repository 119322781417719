import { Typography, Box, Container, CircularProgress } from '@mui/material';
import { light } from '../../css/color';

import { useAssignUserToSession } from './useAssignUserToSession';

export const Login = () => {
  useAssignUserToSession();

  return (
    <Box sx={{ padding: 8 }} >
      <Container sx={{ height: '52vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', }} maxWidth="lg">
        <Box sx={{ padding: 2 }}>
          <Typography
            sx={{ color: light.textColor2, fontSize: "28px", lineHeight: "27px" }}
            align="center"
            paragraph
          >
            Logging you in to Enduvo...
          </Typography>
        </Box>
        <Box>
          <CircularProgress color="inherit"/>
        </Box>
      </Container>
    </Box>
  );
}
