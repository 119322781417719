import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../app/config";

export interface Enquiry {
  status: string;
}

const initialState: Enquiry = {
  status: "",
};

export interface EnquiryBody {
  first_name: string;
  last_name: string;
  email: string;
  title: string;
  phone: string;
  company: string;
  industry: string;
  recieve_information: boolean;
}

export const createEnquiry = createAsyncThunk("auth/enquiry", async (data: EnquiryBody) => {
  const response = await axios.post(`${config.baseURL}/api/enquiry`, { 
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    title: data.title,
    phone: data.phone,
    company: data.company,
    industry: data.industry,
    recieve_information: data.recieve_information,
  });
  return response.data;
});

const enquirySlice = createSlice({
  name: "enquiry",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createEnquiry.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(createEnquiry.fulfilled, (state, action) => {
      if(action.payload.success) state.status = "success";
    });
    builder.addCase(createEnquiry.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default enquirySlice.reducer;
