import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppDispatch, useTypedSelector } from "../../app/hooks";
import isEmail from "validator/lib/isEmail";
import Grid from "@mui/material/Grid";

import { EnduvoButton } from "../../components/EnduvoButton";
import { getCommonAppConfig } from "../../features/commonAppConfig/commonAppConfig.slice";
import { createEarlyAccessUser } from "../../features/auth/auth.slice";
import { light } from "../../css/color";
import classes from "./styles.module.css";
import { validatePhoneNumber } from "../../utilities/utils";

export const EarlyAccessSignUp = () => {
  const dispatch = useAppDispatch();
  const [successMsg, setsuccessMsg] = React.useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [business_name, setBusinessName] = useState("");

  const [howDidYouFindUsDropdownList, sethowDidYouFindUsDropdownList] = useState<any[]>([]);
  const [how_did_you_find_us, sethowDidYouFindUs] = useState("Choose");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const setInitialData = async () => {
    const response = await dispatch(getCommonAppConfig());
    if (response && response.payload) {
      const appConfigList = response.payload.data[0];
      if (appConfigList && appConfigList.how_did_you_find_us) {
        const howDidYouFindUsDropdownList: string[] = [];
        appConfigList.how_did_you_find_us.forEach((element: string) => {
          howDidYouFindUsDropdownList.push(element);
        });
        sethowDidYouFindUsDropdownList(howDidYouFindUsDropdownList);
      }
    }
  };

  useEffect(() => {
    setInitialData();
  }, []);

  const submit = async () => {
    if (first_name && last_name && email && business_name) {
      if (!isEmail(email)) return setErrorMessage("Email is not valid.");
      setErrorMessage("");
      const userObject = {
        first_name,
        last_name,
        email,
        title,
        phone: phoneNumber,
        business_name,
        how_did_you_find_us: how_did_you_find_us === "Choose" ? "" : how_did_you_find_us,
      };
      setIsLoading(true);
      const response = await dispatch(createEarlyAccessUser(userObject));
      setIsLoading(false);
      if (!response) return setErrorMessage("Please try afyer some time.");
      if (!response.payload.success) return setErrorMessage(response.payload.error.message);
      if (response.payload.success) setsuccessMsg(response.payload.success);
    } else return setErrorMessage("Enter required fields.");
  };

  const onChangeValue = (number: string) => setPhoneNumber(validatePhoneNumber(number));

  const earlyAccessTemplate = (
    <Box sx={{ flexGrow: 1, justifyContent: "center", mt: -3 }} pb={30}>
      <Stack alignItems="center" sx={{ display: "flex", justifyContent: "center" }}>
        <Grid
          item
          xs={8}
          sm={8}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 400,
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Typography sx={{ fontSize: "60px", lineHeight: "62px", fontWeight: 700, color: light.textColor2 }}>
            Sign up now to request early
          </Typography>
          <Typography sx={{ fontSize: "60px", lineHeight: "62px", fontWeight: 700, color: light.textColor2 }}>
            access to Enduvo 2.0!
          </Typography>
        </Grid>
      </Stack>
      <Grid container spacing={3} alignItems="center" sx={{ alignItems: "center" }} pb={4} pt={6}>
        <Grid container item spacing={3} sx={{ justifyContent: "center" }}>
          <Grid item xs={8} sm={3} md={3} lg={3} xl={3} sx={{ justifyContent: "center" }}>
            <Stack flexDirection="row" mb={1}>
              <Typography sx={{ fontSize: "14px", color: light.textColor4, lineHeight: "16px" }}>First Name</Typography>
              {first_name ? null : (
                <Typography sx={{ fontSize: "14px", color: light.errorTextColor, lineHeight: "16px" }}>*</Typography>
              )}
            </Stack>
            <TextField
              disabled={isLoading}
              fullWidth
              onChange={(e) => setFirstName(e.target.value)}
              InputProps={{ style: { height: "50px" } }}
              id="outlined-basic"
              placeholder="Enter name here..."
              className={classes.inputField}
              value={first_name}
            />
          </Grid>
          <Grid item xs={8} sm={3} md={3} lg={3} xl={3} sx={{ justifyContent: "center" }}>
            <Stack flexDirection="row" mb={1}>
              <Typography sx={{ fontSize: "14px", color: light.textColor4, lineHeight: "16px" }}>Last Name</Typography>
              {last_name ? null : (
                <Typography sx={{ fontSize: "14px", color: light.errorTextColor, lineHeight: "16px" }}>*</Typography>
              )}
            </Stack>
            <TextField
              disabled={isLoading}
              fullWidth
              onChange={(e) => setLastName(e.target.value)}
              InputProps={{ style: { height: "50px" } }}
              id="outlined-basic"
              placeholder="Enter name here..."
              className={classes.inputField}
              value={last_name}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3} sx={{ justifyContent: "center" }}>
          <Grid item xs={8} sm={3} md={3} lg={3} xl={3} sx={{ justifyContent: "center" }}>
            <Stack flexDirection="row" mb={1}>
              <Typography sx={{ fontSize: "14px", color: light.textColor4, lineHeight: "16px" }}>Business Name</Typography>
              {business_name ? null : (
                <Typography sx={{ fontSize: "14px", color: light.errorTextColor, lineHeight: "16px" }}>*</Typography>
              )}
            </Stack>
            <TextField
              disabled={isLoading}
              fullWidth
              onChange={(e) => setBusinessName(e.target.value)}
              InputProps={{ style: { height: "50px" } }}
              id="outlined-basic"
              placeholder="Enter name here..."
              className={classes.inputField}
              value={business_name}
            />
          </Grid>
          <Grid item xs={8} sm={3} md={3} lg={3} xl={3} sx={{ justifyContent: "center" }}>
            <Stack flexDirection="row" mb={1}>
              <Typography sx={{ fontSize: "14px", color: light.textColor4, lineHeight: "16px" }}>Title</Typography>
            </Stack>
            <TextField
              disabled={isLoading}
              fullWidth
              onChange={(e) => setTitle(e.target.value)}
              InputProps={{ style: { height: "50px" } }}
              id="outlined-basic"
              placeholder="Enter title here..."
              className={classes.inputField}
              value={title}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3} sx={{ justifyContent: "center" }}>
          <Grid item xs={8} sm={6} md={6} lg={6} xl={6} sx={{ justifyContent: "center" }}>
            <Stack flexDirection="row" mb={1}>
              <Typography sx={{ fontSize: "14px", color: light.textColor4, lineHeight: "16px" }}>Email address</Typography>
              {email ? null : (
                <Typography sx={{ fontSize: "14px", color: light.errorTextColor, lineHeight: "16px" }}>*</Typography>
              )}
            </Stack>
            <TextField
              disabled={isLoading}
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
              InputProps={{ style: { height: "50px" } }}
              id="outlined-basic"
              placeholder="Enter email here..."
              className={classes.inputField2}
              value={email}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={3} sx={{ justifyContent: "center" }}>
          <Grid item xs={8} sm={3} md={3} lg={3} xl={3} sx={{ justifyContent: "center" }}>
            <Stack flexDirection="row" mb={1}>
              <Typography sx={{ fontSize: "14px", color: light.textColor4, lineHeight: "16px" }}>Phone number</Typography>
            </Stack>
            <TextField
              disabled={isLoading}
              fullWidth
              onChange={(e) => onChangeValue(e.target.value)}
              InputProps={{ style: { height: "50px" } }}
              id="outlined-basic"
              placeholder="Enter phone here..."
              className={classes.inputField}
              value={phoneNumber}
            />
          </Grid>
          <Grid item xs={8} sm={3} md={3} lg={3} xl={3} sx={{ justifyContent: "center" }}>
            <Stack flexDirection="row" mb={1}>
              <Typography sx={{ fontSize: "14px", color: light.textColor4, lineHeight: "16px" }}>
                How did you find us?
              </Typography>
            </Stack>
            <TextField
              disabled={isLoading}
              fullWidth
              onChange={(e) => sethowDidYouFindUs(e.target.value)}
              select
              InputProps={{ style: { height: "50px", color: how_did_you_find_us == "Choose" ? "#00000050" : "#000" } }}
              className={classes.inputField}
              value={how_did_you_find_us}
            >
              <MenuItem value="Choose" disabled>
                Choose
              </MenuItem>
              {howDidYouFindUsDropdownList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
        </Grid>
        {isLoading ? (
          <Grid container item spacing={3} sx={{ justifyContent: "center" }}>
            <Grid item sx={{ justifyContent: "center" }}>
              <CircularProgress size={25} sx={{ color: "#939393" }} />
            </Grid>
          </Grid>
        ) : null}
        <Grid container item spacing={3} sx={{ justifyContent: "center" }}>
          <Grid item xs={8} sm={6} md={6} lg={6} xl={6} sx={{ justifyContent: "center" }}>
            <EnduvoButton
              startIcon={null}
              action={submit}
              buttonText="Submit My Request"
              width={null}
              height={null}
              disabled={isLoading}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item sx={{ justifyContent: "center" }}>
        <Grid item xs={6} sm={6} sx={{ zIndex: 400, flexDirection: "column", marginLeft: 2 }}>
          <Typography variant="caption" color="red">
            {errorMessage}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
  const successTemplate = () => {
    return (
      <Box sx={{ pt: 15, pb: 60 }}>
        <div
          style={{
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img src="/images/early-signup-success-img.png" alt="enduvo-logo-img" height="143px" width="143px" />
        </div>
        <Stack alignItems="center" sx={{ display: "flex", justifyContent: "center" }}>
          <Grid
            item
            xs={8}
            sm={8}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 400,
              flexDirection: "column",
              textAlign: "center",
            }}
          >
            <Typography sx={{ fontSize: "30px", lineHeight: "62px", fontWeight: 700, color: light.textColor2 }}>
              We’ve received your request.
            </Typography>
            <Typography sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: 400, color: light.textColor2 }}>
              Stay tuned - a member of our team will contact
            </Typography>
            <Typography sx={{ fontSize: "16px", lineHeight: "20px", fontWeight: 400, color: light.textColor2 }}>
              you soon to get started!
              <span role="img" aria-label="smile">
                &#128522;
              </span>
            </Typography>
          </Grid>
        </Stack>
      </Box>
    );
  };

  return (
    <Box sx={{ backgroundImage: light.bgColor, paddingTop: "31px", overflow: "hidden" }}>
      <Stack sx={{ pb: 10, flexDirection: "row", overflow: "hidden" }}>
        <Stack sx={{ position: "absolute", left: "6%", marginTop: -4 }}>
          <img
            style={{
              position: "absolute",
              marginTop: 40,
              marginLeft: 100,
              WebkitFilter: "grayscale(1) contrast(60%) ",
              filter: "drop-shadow(176.13px 161.752px 107.835px rgba(0, 0, 0, 0.26)) brightness(65%)",
            }}
            height="42px"
            width="42.17px"
            src="/images/Mil-Signup-sphere1.png"
            alt="/images/Mil-Signup-sphere1.png"
          />
          <img height="394.27px" width="241.35px" src="/images/Mil_Signup-vector1.png" alt="/images/Mil_Signup-vector1.png" />
        </Stack>

        <Stack sx={{ position: "absolute", left: "62%", top: "35%" }}>
          <img height="48.72px" width="278.9px" src="/images/Mil-Signup-vector2.png" alt="/images/Mil-Signup-vector2.png" />
        </Stack>
        <Stack sx={{ position: "absolute", left: "0%", top: "70%", bottom: "10%" }}>
          <img
            style={{ position: "absolute" }}
            height="369.27px"
            width="506px"
            src="/images/Mil-Signup-Object1.png"
            alt="/images/Mil-Signup-Object1.png"
          />
        </Stack>
        <Stack sx={{ position: "absolute", left: "100%", top: "47%" }}>
          <img
            style={{ position: "absolute", right: "15%" }}
            height="509px"
            width="463.81px"
            src="/images/Mil-Signup-Elipse.png"
            alt="/images/Mil-Signup-Elipse.png"
          />
        </Stack>
        <Stack sx={{ position: "absolute", left: "100%", top: "30%" }}>
          <img
            style={{ position: "absolute", right: "15%" }}
            height="401.92px"
            width="209.36px"
            src="/images/Mil-Signup-Img.png"
            alt="/images/Mil-Signup-Img.png"
          />
        </Stack>
        <Stack
          sx={{
            position: "absolute",
            right: "6%",
            top: "80%",
            filter: "blur(20px) drop-shadow(0px 0px 0px #ffffff)",
          }}
        >
          <img
            // style={{ position: 'absolute', left: '90%' }}
            height="253px"
            width="253px"
            src="/images/Mil-Signup-Elipse2.png"
            alt="/images/Mil-Signup-Elipse2.png"
          />
        </Stack>
        <Stack sx={{ position: "absolute", left: "38%", top: "60%", bottom: "-30%" }}>
          <img height="403.5px" width="479.27px" src="/images/Mil-Signup-vector3.png" alt="/images/Mil-Signup-vector3.png" />
        </Stack>
      </Stack>
      {successMsg ? successTemplate() : earlyAccessTemplate}
    </Box>
  );
};
