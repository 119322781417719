
export const light = {
    bgColor: "linear-gradient(180deg, #E0E3E5 0%, #BDC1C6 48.96%, #989EA6 100%)",
    bgColor2: 'linear-gradient(268.34deg, #8491a3 1.75%, #717d8f 119.44%, #847060 147.53%)',
    bgColor3: 'rgba(255, 255, 255, 0.44)',
    bgColor4: '#ECECF6',
    bgColor5: '#E5E5E5',
    buttonBgColor: 'linear-gradient(135deg, #F1BE68 0%, #DA8A42 100%)',
    textColor1: "#1A1C1F",
    textColor2: "#282C30",
    textColor3: "#0F1213",
    textColor4: '#343841',
    textColor5: '#828282',
    errorTextColor: '#D75205',
    objectColor1: 'linear-gradient(180deg, #CFC0B6 0%, #898D97 100%)', //Grey 
    objectColor2: 'linear-gradient(180deg, #2B72AC 0%, #343841 0.01%, rgba(94, 102, 115, 0.24) 100%)', //Grey
    objectColor3: 'linear-gradient(180deg, rgba(94, 102, 115, 0) 0%, rgba(52, 56, 65, 0.6) 100%)', 
    objectColor4: 'linear-gradient(180.02deg, rgba(52, 56, 65, 0) 0.02%, rgba(218, 138, 66, 0.68) 99.98%)',
    objectColor5: 'linear-gradient(174.47deg, rgba(237, 123, 57, 0.33) -3.04%, #989EA6 95.66%)',
    objectColor6: 'linear-gradient(161.48deg, #5E6673 0.15%, rgba(94, 102, 115, 0) 94.66%)',
    objectColor7: 'linear-gradient(180deg, rgba(94, 102, 115, 0) 0%, #343841 100%)',
    objectColor8: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 0.18) 100%)',
    objectColor9: 'linear-gradient(170.13deg, rgba(147, 151, 159, 0) 3.66%, rgba(253, 145, 106, 0.4) 18.13%, rgba(52, 56, 65, 0.4) 90.48%)',
    boxColor1: 'linear-gradient(174.33deg, #989EA6 4.51%, rgba(255, 255, 255, 0.75625) 54.59%, rgba(237, 123, 57, 0.64) 78.47%)',
    elipseColor1: '#2B99FF',
    // objectColor4: 'linear-gradient(180deg, rgba(52, 56, 65, 0) 41.15%, #D5BEAB 100%)',linear-gradient(170.13deg, rgba(147, 151, 159, 0) 3.66%, rgba(253, 145, 106, 0.4) 18.13%, rgba(52, 56, 65, 0.4) 90.48%)
};

export const dark = {
    bgColor:"#0F1213",
    bgColor2: "#1A1C1F",
    bgColor3: 'linear-gradient(136.37deg, #2A1ECD 0%, #D038EB 138.56%)',
    bgColor4:"#0F1213",
    bgColor5: '#0F1213',
    buttonBgColor: "linear-gradient(136.37deg, #2A1ECD 0%, #D038EB 138.56%);",
    textColor1: "#1A1C1F",
    textColor2: "#A4A4A4",
    textColor3: "#0F1213",
    textColor4: '#343841',
    textColor5: '#828282',
    errorTextColor: 'red',
    objectColor1: 'linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%)', //Purple
    objectColor2: 'linear-gradient(180deg, rgba(42, 30, 205, 0) 0%, #2A1ECD 100%)', //blue
    objectColor3: 'linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%)', //Faded Purple shade
    objectColor4: 'linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%)',
    objectColor5: 'linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%)',
    objectColor6: 'linear-gradient(161.48deg, #2A1ECD 0.15%, rgba(42, 30, 205, 0) 94.66%)',
    objectColor7: 'linear-gradient(161.48deg, #2A1ECD 0.15%, rgba(42, 30, 205, 0) 94.66%)',
    objectColor8: 'linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%)',
    objectColor9: 'linear-gradient(180deg, rgba(208, 56, 235, 0) 0%, #D038EB 100%)',
    boxColor1: '#1A1C1F',
    elipseColor1: 'linear-gradient(136.37deg, #2A1ECD 0%, #D038EB 138.56%)',
};