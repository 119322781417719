import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { light } from "../../css/color";
import classes from "./styles.module.css";
import React from "react";
import Stack from "@mui/material/Stack";
export const PrivacyPolicy = () => {
  return (
    <Box sx={{ backgroundImage: light.bgColor, paddingTop: "36px", overflow: "hidden" }}>
      <Stack pl={12} pr={8}>
        <Stack sx={{ width: "89%", marginLeft: "50px" }} pl={3}>
          <Typography sx={{ fontSize: "62px", color: "#282C30", fontWeight: "bold", paddingTop: "50px" }}>
            Privacy Policy
          </Typography>
          <Stack direction="column" pt={4}></Stack>
          <Typography sx={{ fontSize: "15px", color: "#282C30", fontStyle: "italic" }}>Updated July 14, 2021</Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            This Global Privacy Policy describes the Enduvo, Inc., its subsidiaries and affiliates (collectively, “Enduvo”)
            practices related to personal data privacy and security of Enduvo, including the types of personal information we
            collect, how we use the information, with whom we share it and the rights you may have about the use of the
            information. We further describe the security measures we implement to protect the security of the information and
            how you can contact us about our privacy practices. We will treat all your Personal Information (which is
            information pertaining to an identifiable individual, also known as “personal data”, “personally identifiable
            information” or “PII”, depending on your jurisdiction) as confidential (although we reserve the right to disclose
            this information in the circumstances set out below). As a company, Enduvo shares your concerns about maintaining
            the privacy of personal information collected on the Internet. This document describes the use of any Enduvo
            Website, e-commerce tool or the purchase of its goods or services constitutes and the required consent for Enduvo’s
            collection and use of personal data as outlined below in connection with its family of websites (the “Website”).
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            The Enduvo entity responsible for the collection and use of your personal information for the purposes described in
            this Global Privacy Policy is mentioned in the « ABOUT « section.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            1. Collection of Personal Information
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Enduvo generally collects business contact information from or about you. The types of Personal Information Enduvo
            may obtain or that you may choose to provide include:
          </Typography>
          <ul className={classes.contentStyle} style={{ lineHeight: "27px" }}>
            <li>
              <Typography>Contact information (such as name, postal or e-mail address, and phone number)</Typography>
            </li>
            <li>
              <Typography>Business contact information (such as job title, department, and name of organization)</Typography>
            </li>
            <li>
              <Typography>
                The content you provide (such as photographs, comments or other information provided via web forms).
              </Typography>
            </li>
          </ul>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Enduvo and our service providers may collect certain information by using automated means, such as cookies when you
            visit our websites. The information we collect in this way may include:
          </Typography>
          <ul className={classes.contentStyle} style={{ lineHeight: "27px" }}>
            <li>
              <Typography>
                The home server domain names, type of computer, and type of web browser of users to the website;
              </Typography>
            </li>
            <li>
              <Typography>Aggregate and user-specific information on which pages users access.</Typography>
            </li>
          </ul>
          <Typography className={classes.contentStyle} style={{ lineHeight: "27px" }}>
            Internet cookies can save data about individual users, such as your name, password, user- name, screen preferences,
            the pages of a site viewed by you, and the advertisements viewed or clicked by you. When you revisit the website,
            Enduvo may recognize you by the Internet cookie and customize your experience accordingly.
          </Typography>
          <Typography className={classes.contentStyle} style={{ marginTop: "15px", lineHeight: "27px" }}>
            You may decline Internet cookies, if any, by using the appropriate feature of their Web client software, if
            available. However, declining to use cookies may impede the ability of the website to function properly. When you
            perform a search within the website, Enduvo may record information identifying you or linking you to the search
            performed. Enduvo may also record limited information associated with every search request made by you and use that
            information to, among other things, solve technical problems with the service and to calculate overall usage
            statistics.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            2. Use of Personal Information Collected
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Enduvo may use Personal Information it collects from you or about you to:
          </Typography>
          <ul className={classes.contentStyle} style={{ lineHeight: "27px" }}>
            <li>
              <Typography>
                Establish, develop and preserve its business relationship respectively with you and other individuals with whom
                it deals,
              </Typography>
            </li>
            <li>
              <Typography>Create, establish and administer your accounts,</Typography>
            </li>
            <li>
              <Typography>Respond to your inquiries,</Typography>
            </li>
            <li>
              <Typography>
                Provide the products or services that it offers, or receive the products or services that it requests, and
                ensure that all related transactions are supported and implemented,
              </Typography>
            </li>
            <li>
              <Typography>
                Authenticate the identity of customers contacting Enduvo by telephone, electronic means or otherwise,
              </Typography>
            </li>
            <li>
              <Typography>Meet legal and regulatory requirements; and/or</Typography>
            </li>
            <li>
              <Typography>
                Allow Enduvo to meet contractual requirements relating to the products and services provided to customers.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.contentStyle} style={{ lineHeight: "27px" }}>
            Enduvo may also use Personal Information it collects for internal purposes, specifically, for:
          </Typography>
          <ul className={classes.contentStyle} style={{ lineHeight: "27px" }}>
            <li>
              <Typography>Solving technical problems,</Typography>
            </li>
            <li>
              <Typography>Calculating overall usage statistics,</Typography>
            </li>
            <li>
              <Typography>
                Marketing and promotional purposes, product development, and content improvement. Unless otherwise required by
                law, we will obtain your opt-in consent before we send you communications related to marketing and promotion.
              </Typography>
            </li>
          </ul>
          <Typography sx={{ fontSize: "24px", color: "#282C30", fontWeight: "bold" }}>
            3. Disclosure of Personal Information
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Unless noted otherwise on this website, Enduvo will not disclose to third parties any Personal Information, such as
            names, postal and e-mail addresses, telephone numbers, and other personal information which you voluntarily provide
            to Enduvo, except to the extent (i) permitted by law; (ii) necessary to comply with applicable laws or legal
            processes (e.g., subpoenas); (iii) in connection with a merger, acquisition, or liquidation of Enduvo, in accordance
            with applicable laws; (iv) or to protect the rights or property of Enduvo. Enduvo will cooperate with law
            enforcement authorities. Student Information provided to Instructors.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            Enduvo only provides Instructors with the following information about the Students enrolled in their Courses: (1)
            name, (2) email address, and (3) the Instructor’s Course in which the Student has enrolled. Enduvo does not disclose
            any other information about Students to Instructors, and Students assume full responsibility for the disclosure and
            use of any other personal information the Student chooses to disclose to any Instructor or other User on the Sites,
            which at all times shall be subject to the terms of our Privacy Policy.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            4. Rights regarding the processing of Personal Data
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            You have certain rights regarding the personal information Enduvo maintains about you. We also offer you certain
            choices about what personal information we collect from you, how we use that information, and how we communicate
            with you.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            You may opt out of the collection and use of certain information, which Enduvo collect by automated means when you
            visit our websites.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            Enduvo will comply with anti-spam laws which apply in your jurisdiction. Moreover, users may at any time opt out of
            having their Personal Information used by Enduvo to send promotional correspondence to the user, by contacting
            Enduvo by email or postal mail at the address(es) below:
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            Legal Department
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Enduvo, Inc.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            2601 W. Lake Ave.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Suite A6-110
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Peoria, Illinois USA
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            61615
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            To the extent provided by applicable law, you may withdraw any consent you previously provided to Enduvo, or object
            at any time on legitimate grounds, to the processing of your Personal Information. You may withdraw any data
            protection consent that you have granted to us at any time with effect for the future by sending an email to the
            address mentioned above.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            5. Collection of Personal Information from Children
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            This section specifies how Enduvo, Inc. collects and uses information provided by and about children who use our
            platform, including immersive content viewing and creation.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", fontStyle: "italic", lineHeight: "27px" }}>
            What information do we collect about children?
          </Typography>
          <ul className={classes.contentStyle} style={{ lineHeight: "27px" }}>
            <li>
              <Typography> Child’s full name</Typography>
            </li>
            <li>
              <Typography>Date of birth</Typography>
            </li>
            <li>
              <Typography>Email address</Typography>
            </li>
            <li>
              <Typography>Parent email</Typography>
            </li>
            <li>
              <Typography>IP addresses</Typography>
            </li>
          </ul>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            The information collected will be used to improve your child’s experience, including notifications about new
            featured content or platform features. Children’s email addresses will not be displayed on the site. Our sign-up
            defaults children as Students on the platform, capable only of viewing immersive content. They are assigned to the
            Enduvo Kids workspace, which has curated content that has been reviewed and approved as appropriate for children.
            This is unlike Enduvo’s public workspace, in which anyone can publish content. Users under 13 are not able to access
            Enduvo’s public workspace directly.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", fontStyle: "italic", lineHeight: "27px" }}>
            What information do we collect about parents?
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            We collect parent emails so we can ask their permission to grant/deny use of our services. We also may give them
            information about how to help children get started or about their account.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", fontStyle: "italic", lineHeight: "27px" }}>
            What information do we collect about devices?
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            When you use our platform and especially when you submit feedback/bugs, we collect information about your device
            like the operating system, processor and graphics card. We also note your IP address.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", fontStyle: "italic", lineHeight: "27px" }}>
            In-app Notifications
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            There are no in-app notifications at this time.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", fontStyle: "italic", lineHeight: "27px" }}>
            In-app Purchases
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            At this time, the platform is free for educators sharing their expertise and students who can explore this immersive
            content and there are no in-app purchases. As such, we do not store any financial information in children’s accounts
            at this time. In the future, the Enduvo platform may sell premium content, at which time our policy will be updated.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", fontStyle: "italic", lineHeight: "27px" }}>
            How do we share your information?
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            For children under 13, Enduvo does not share any user-specific information. We may share with instructors aggregated
            data regarding number of Students in the workspace and non-user specific information about course enrollment and
            completion.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            6. Cross-border Transfer of Personal Information
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            In some cases, Personal Information that we manage may be transferred, processed and stored outside of your country
            of residence, including the United States and therefore, your Personal Information may be available to government
            authorities under lawful orders and laws applicable in such foreign jurisdictions. Countries to which your Personal
            Information is transferred may not have the same data protection laws as the country of residence. When we transfer
            your information to other countries, we will protect Personal Information as described in this Global Privacy
            Policy. We comply with applicable legal requirements providing adequate safeguards for the transfer of personal
            information to countries outside of the European Economic Area (“EEA”) or Switzerland.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            7. Security of Data
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            The security of individuals’ personal information is important to Enduvo. We are committed to protecting the
            information we collect. We have put in place appropriate technical and organizational measures to protect the
            information you provide or we collect against accidental, unlawful or unauthorized destruction, loss, alteration,
            access, disclosure or use. We use SSL encryption on our websites via which we collect Personal Information.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            8. European Union Data Protection
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Residents in the European Union are entitled to certain rights with respect to personal information that we hold
            about them:
          </Typography>
          <ul className={classes.contentStyle} style={{ lineHeight: "27px" }}>
            <li>
              <Typography>
                <span style={{ fontWeight: "bold" }}> Right of access and portability.</span> The right to obtain access to your
                personal information, along with certain related information, and to receive that information in a commonly used
                format and to have it transferred to another data controller;
              </Typography>
            </li>
            <li>
              <Typography>
                <span style={{ fontWeight: "bold" }}>Right to rectification.</span>
                The right to obtain rectification of your personal information without undue delay where that personal
                information is inaccurate or incomplete;
              </Typography>
            </li>
            <li>
              <Typography>
                <span style={{ fontWeight: "bold" }}>Right to erasure. </span>
                The right to obtain the erasure of your personal information without undue delay in certain circumstances, such
                as where the personal information is no longer necessary in relation to the purposes for which it was collected
                or processed;
              </Typography>
            </li>
            <li>
              <Typography>
                <span style={{ fontWeight: "bold" }}>Right to restriction.</span>
                The right to obtain the restriction of the processing undertaken by us on your personal information in certain
                circumstances, such as where the accuracy of the personal information is contested by you, for a period enabling
                us to verify the accuracy of that personal information;
              </Typography>
            </li>
            <li>
              <Typography>
                <span style={{ fontWeight: "bold" }}>Right to object.</span>
                The right to object, on grounds relating to your particular situation, to the processing of your personal
                information, and to object to the processing of your personal information for direct marketing purposes, to the
                extent it is related to such direct marketing.
              </Typography>
            </li>
          </ul>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            You may also have the right to make a complaint to the relevant Supervisory Authority. A list of Supervisory
            Authorities is available here: "{" "}
            <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm" target="_blank">
              http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
            </a>
            ". If you need further assistance regarding your rights, please contact us using the contact information provided
            below and we will consider your request in accordance with applicable law. In some cases, our ability to uphold
            these rights for you may depend upon our obligations to process personal information for security, safety, fraud
            prevention reasons, compliance with regulatory or legal requirements, or because processing is necessary to deliver
            the services you have requested. Where this is the case, we will inform you of specific details in response to your
            request.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            9. Updates to our Global Privacy Notice
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Our Privacy Policy may change from time to time. We will post any privacy policy changes on our Website and, if the
            changes are significant, we will provide a more prominent notice.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            10. How to Contact Us
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Should you have any questions or concerns about this Privacy Policy or the collection of information or if you would
            like Enduvo to update or correct your information or preferences please email us at "
            <a href="mailto:Legal@enduvo.com" target="_blank">
              Legal@enduvo.com
            </a>
            " or write to us at:
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Legal Department
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Enduvo, Inc.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            2601 W. Lake Ave.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Suite A6-110
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Peoria, Illinois USA
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            61615
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            Notice to California Residents
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            This notice supplements Enduvo’s privacy policy, applies only to California residents and is effective as of January
            1, 2020. This California Privacy Notice sets forth the disclosures and rights for California consumers regarding
            their personal information, as required by the California Consumer Privacy Act (“CCPA”). Terms used in this
            California Privacy Notice have the same meanings given in the CCPA and the regulations of the Attorney General
            implementing the CCPA, unless otherwise defined. California Civil Code 1798.115(c), 1798.130(a)(5)(c), 1798.130(c),
            and 1798.140 indicate that organizations should disclose whether certain categories of information are “sold” or
            transferred for an organization’s “business purpose” as those terms are defined under California law.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            The CCPA provides California residents with the following additional rights:
          </Typography>
          <ul className={classes.contentStyle} style={{ lineHeight: "27px" }}>
            <li>
              <Typography>
                Right to Know: California residents may request disclosure of the specific pieces and/or categories of personal
                information that the business has collected about them, the categories of sources for that personal information,
                the business or commercial purposes for collecting the information, the categories of personal information that
                we have disclosed, and the categories of third parties with which the information was shared.
              </Typography>
            </li>
            <li>
              <Typography>
                Right to Opt-Out: To the extent that Enduvo “sells” personal information(as that term is defined under the
                CCPA), California residents are entitled to opt-out of the “sale” of data at any time (see below for more
                information).
              </Typography>
            </li>
          </ul>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            If you would like to manage, change, limit, or delete your personal information, you can do so via your Enduvo
            account settings. Alternatively, you can exercise any of the rights above by contacting us by submitting a message
            through our through one of the channels listed below under “Contact”. Once you contact us to exercise any of your
            rights, we will confirm receipt of your request. We do not discriminate against California consumers who exercise
            any of their rights described in this policy.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            Limiting use of, or deleting, your personal information may impact features and uses that rely on that information.
            However, we will not discriminate against you for exercising any of your rights, including otherwise denying you
            goods or services, providing you with a different level or quality of services, or charging you different prices or
            rates for services. If you need further assistance, you can contact Enduvo through one of the channels listed below
            under “Contact”. We will respond to your request within a reasonable timeframe.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            Please note that we may verify your identity before we are able to process any of the requests described in this
            Section, and in our discretion, deny your request if we are unable to verify your identity. As a part of this
            process, government or other identification may be required. You may designate an authorized agent to make a request
            on your behalf. In order to designate an authorized agent to make a request on your behalf, you must provide a valid
            power of attorney, the requester’s valid government issued identification, and the authorized agent’s valid
            government issued identification, and we may verify the authenticity of the request directly with you.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            Email & Messages
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            You may also control the receipt of certain types of communications from Enduvo in your account settings. Enduvo may
            send you messages about the Services or your activity. Some of these messages are required, service-related messages
            for members (such as transactional messages or legal notices). If you no longer wish to use the Services or receive
            service- related messages (except for legally required notices), then you may close your account. Use one of the
            channels listed below under “Contact” to close your account.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>Cookies</Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            We use both technically necessary (for the functioning and security of the Services) and non- technically necessary
            cookies and similar technologies.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            Additional Disclosures for California Residents
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            Notice of Collection
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            In addition to the Rights & Choices described above, the CCPA requires disclosure of the categories of personal
            information collected over the past 12 months. While this information is provided in greater detail in the
            “Information Collected or Received Section above, the categories of personal information that we have collected – as
            described by the CCPA – are:
          </Typography>
          <ul className={classes.contentStyle} style={{ lineHeight: "27px" }}>
            <li>
              <Typography>
                Identifiers, including name, email address, IP address, and an ID or number assigned to your account.
              </Typography>
            </li>
            <li>
              <Typography>
                Other individual records such as phone number, billing address, or credit or debit card information. This
                category includes personal information protected under pre-existing California law (Cal. Civ. Code 1798.80(e)),
                and overlaps with other categories listed here.
              </Typography>
            </li>
            <li>
              <Typography>
                Demographics, such as your age or gender. This category includes data that may qualify as protected
                classifications under other California or federal laws.
              </Typography>
            </li>
            <li>
              <Typography>Commercial information, including purchases and engagement with the Services.</Typography>
            </li>
            <li>
              <Typography>
                Internet activity, including your interactions with our Services and what led you to our Services.
              </Typography>
            </li>
            <li>
              <Typography>Sensory visual data, such as pictures posted on our Service.</Typography>
            </li>
            <li>
              <Typography>Geolocation data provided through location enabled services such as WiFi and GPS.</Typography>
            </li>
            <li>
              <Typography>Inferences, including information about your interests, preferences and favorites</Typography>
            </li>
          </ul>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            The Purposes for Our Collection
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            We collect and use these categories of personal information for our business and commercial purposes, including
            providing and improving the Services, maintaining the safety and security of the Services, processing purchase and
            sale transactions, and for advertising and marketing services.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            Third Party Marketing and Advertising and Your Rights (Opt-Out of “Sale”)
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Enduvo does not sell personal information to third parties for monetary value. However, the term “sale” is defined
            broadly under the California Consumer Privacy Act. To the extent that “sale” under the CCPA is interpreted to
            include interest based advertising or other data uses described in the “Information Uses, Sharing and Disclosure”
            Section above, we will comply with applicable law as to those activities. To opt-out of receiving interest based
            messages, you can exercise your choice by using your account privacy settings, or the general privacy settings link
            located at the footer of our homepage and most pages of the Site. The right to opt-out of interest based marketing
            is available to all Enduvo users.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            Do Not Track Signals
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Your browser settings may allow you to automatically transmit a “Do Not Track” signal to online services you visit.
            Note, however, there is no industry consensus as to what site and app operators should do with regard to these
            signals. Accordingly, unless and until the law is interpreted to require us to do so, we do not monitor or take
            action with respect to “Do Not Track” signals. For more information on “Do Not Track,” visit "
            <a href="http://www.allaboutdnt.com" target="_blank">
              http://www.allaboutdnt.com
            </a>
            "
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            Shine the Light
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            California law entitles residents to ask for a notice describing what categories of personal information we share
            with third parties for their own direct marketing purposes. Other than to Instructor’s on Enduvo, who must obtain
            your separate consent for their own marketing off of Enduvo, or unless you request us to or consent to it, Enduvo
            does not share any personal information to third parties for their own direct marketing purposes. If you have
            questions about these practices, you can contact us through one of the channels listed in the “Contact” Section
            below.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>
            Accessibility
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            If you have a disability and would like to access this policy in an alternative format, please contact us using one
            of the channels listed in the “Contact” section below.
          </Typography>
          <Typography sx={{ fontSize: "24px", color: "#282C30", marginTop: "18px", fontWeight: "bold" }}>CONTACT</Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            1. Making A Request
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            To request the specific pieces of Personal Information we have collected about you in the prior 12 months, please
            mail or email us at privacy@enduvo.com.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            We will provide the requested information free of charge up to two (2) times in a 12-month period, but please note
            that where we receive unfounded, excessive, or repetitive requests, we may elect to decline the request. Note also
            that we will not retain information we do not ordinarily retain in the course of our regular business practices.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            2. Verifying Requests to Access
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            Please note that we will take steps to verify your identity in order to fulfill your request. We will first verify
            your email address is active and we will take steps to verify the information you provide to us.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            In order to verify your identity, we will request that you provide: your name and email address/phone number
            (depending on what you have used to interact with Enduvo’s Sites).
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            In order to have us provide specific pieces of information, we will require a signed declaration under penalty of
            perjury that you are the consumer whose Personal Information is the subject of the request.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            If we are unable to verify that the individual submitting the request is the same individual about whom we have
            collected information (or someone authorized by that individual to act on their behalf), we will not be able to
            provide the requested information.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            -Right To Delete
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            The CCPA also provides a right to request that we delete personal information we have collected about you and for us
            to direct service providers to do the same, with certain exceptions as set forth in the CCPA. Note that you may also
            delete certain Personal Information you have provided to us through your online account.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            When processing a request to delete, we may erase some information and we may de-identify some information (when we
            de-identify information, we implement technical safeguards and business processes that prohibit re-identification,
            business processes to prevent inadvertent release of de-identified information and will make no attempt to
            re-identify the information).
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            Right To Opt-Out Of The Sale Of Personal Information
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            The CCPA also provides a right to California residents to request to opt-out of the sale of Personal Information.
            Enduvo engages in online advertising practices that may potentially be considered “sales” under the CCPA.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            If you are a California resident and would like to opt-out of the sale of Personal Information, please mail or email
            us at privacy@enduvo.com.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            n addition, as we state in our privacy policy, some of the companies we work with for analytics and advertising
            purposes are members of the Network Advertising Initiative (“NAI”) or the Digital Advertising Alliance (“DAA”)
            Self-Regulatory Program for Online Behavioral Advertising and you may want to visit the following webpages, which
            provide “opt-out” mechanisms for participating companies: "
            <a href="http://networkadvertising.org/managing/opt_out.asp" target="_blank">
              http://networkadvertising.org/managing/opt_out.asp
            </a>
            " and "
            <a href="http://www.aboutads.info/choices/" target="_blank">
              http://www.aboutads.info/choices/
            </a>
            " You may opt-out from interest based advertising on your mobile device through your mobile device settings.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            Right to Non-Discrimination for the Exercise of a Consumer’s Privacy Rights
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            California consumers have the right to not receive discriminatory treatment for exercising CCPA rights. We will not
            discriminate against you for exercising your CCPA rights.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            Contact for More Information
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            You can contact us for more information by writing us at Enduvo, Inc.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            2601 W. Lake Ave.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Suite A6-110
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Peoria, Illinois USA
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            61615
          </Typography>
          <Typography className={classes.contentStyle} sx={{ lineHeight: "27px" }}>
            Re: Privacy Policy–California Consumer Privacy Act
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            Or Email: privacy@enduvo.com.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            2. Other California Rights/Your California Privacy Rights
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", lineHeight: "27px" }}>
            California Civil Code Section 1798.83 permits residents of California to request certain details about how their
            personal information has been shared during the calendar year with third parties for those third-parties’ own direct
            marketing purposes, unless the business permits California residents to opt in to, or opt out of, this type of
            sharing. Enduvo qualifies for this alternative option. As noted above, we will not share personal information about
            you with third parties for their own direct marketing purposes if you do not opt-in, or if you choose to opt-out, at
            the time Enduvo offers you that choice.
          </Typography>
          <Typography className={classes.contentStyle} sx={{ marginTop: "18px", marginBottom: "20px", lineHeight: "27px" }}>
            If you are a California resident and you have questions about our practices with respect to sharing personal
            information with third parties for their direct marketing purposes and your ability to exercise choice, please write
            us at Attention Legal, at Enduvo, Inc., 2601 W. Lake Ave., Suite A6-110, Peoria, Illinois USA 61615 or by email at:
            privacy@enduvo.com. You must put the statement “Your California Privacy Rights” in the subject field of your email
            or in the address line of your envelope. You must include your name, street address, city, state, and ZIP code. We
            are not responsible for notices that are not labeled or sent properly, or do not have complete information
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
};
