import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import isEmpty from "validator/lib/isEmpty";

import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import Modal from "@mui/material/Modal";

import { useAppDispatch } from "../../app/hooks";
import { register } from "../../features/auth/auth.slice";
import { RightSideUi } from "../../components/RightSideUi/index";
import { light } from "../../css/color";
import classes from "./styles.module.css";
import { EnduvoButton } from "../../components/EnduvoButton";
import config from "../../app/config";

interface SignUpPageParams {
  closeModal: () => void;
  openLoginModal: () => void;
}

export const SignUpPage = (props: SignUpPageParams) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [organization, setOrganization] = useState("");
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const submit = async () => {
    const emptyOptions = { ignore_whitespace: true };
    if (isEmpty(email, emptyOptions)) return setErrorMessage("Email is required.");
    else if (!isEmail(email)) return setErrorMessage("Email is not valid.");
    else if (isEmpty(name, emptyOptions)) return setErrorMessage("First name is required.");
    else if (isEmpty(lastName, emptyOptions)) return setErrorMessage("Last name is required.");
    else if (isEmpty(organization, emptyOptions)) return setErrorMessage("Organization name is required.");
    else if (!agreeTerms) return setErrorMessage("Please agree to Terms of Service and Privacy Policy.");
    else setErrorMessage("");
    const userObject = { name, last_name: lastName, email, organization };
    const response = await dispatch(register(userObject));
    if (!response.payload.success) return setErrorMessage(response.payload.error.message);
    else setErrorMessage("");
    setOpenSuccessModal(true);
  };

  const openLogin = () => {
    props.openLoginModal();
    props.closeModal();
  };

  const gotoMilitarySignUp = () => {
    props.closeModal();
    history.push("/mil-signup");
  };
  const goToDownload = () => {
    props.closeModal();
    history.push("/download");
  };

  const microsoftSubmit = () => {
    window.location.href = `${config.baseURL}/api/auth/microsoft`;
  };

  const googleSubmit = () => {
    window.location.href = `${config.baseURL}/api/auth/google`;
  };

  return (
    <Stack direction="row">
      <Stack
        sx={{
          borderRadius: 5,
          width: "530px",
          alignItems: "center",
          bgcolor: light.bgColor,
          mt: "40px",
        }}
      >
        <Stack spacing={1} direction="column" justifyContent="center" sx={{ width: "380px", pb: 2, pt: 1 }}>
          <Typography variant="h4" component="h2" color={light.textColor1}>
            It’s awesome to have you here!
          </Typography>
          <Typography variant="body2" color={light.textColor2}>
            Let's get started with a few steps.
          </Typography>
          <Typography variant="caption" color="#A4A4A4">
            Email
          </Typography>
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            required
            InputProps={{ style: { height: "50px" } }}
            id="outlined-basic"
            placeholder="name@gmail.com"
            type="text"
            name="email"
            value={email}
            className={classes.inputField}
          />
          <Stack direction="row" spacing={1.2}>
            <Stack>
              <Typography variant="caption" color="#A4A4A4">
                First name
              </Typography>
              <TextField
                onChange={(e) => setName(e.target.value)}
                InputProps={{ style: { height: "50px" } }}
                id="outlined-basic"
                placeholder="Enter first name..."
                className={classes.inputField}
                value={name}
              />
            </Stack>
            <Stack>
              <Typography variant="caption" color="#A4A4A4">
                Last name
              </Typography>
              <TextField
                onChange={(e) => setLastName(e.target.value)}
                InputProps={{ style: { height: "50px" } }}
                id="outlined-basic"
                placeholder="Enter last name..."
                className={classes.inputField}
                value={lastName}
              />
            </Stack>
          </Stack>
          <Typography variant="caption" color="#A4A4A4">
            Organization
          </Typography>
          <TextField
            onChange={(e) => setOrganization(e.target.value)}
            InputProps={{ style: { height: "50px" } }}
            id="outlined-basic"
            placeholder="Enter organization name..."
            className={classes.inputField}
            value={organization}
          />
          <Box
            // onClick={() => setAgreeTerms(!agreeTerms)}
            sx={{ textTransform: "initial", pl: 0, pt: 1, pb: 1, display: "flex", justifyContent: "start" }}
          >
            <Stack direction="row" spacing={1}>
              <Radio
                sx={{ bgcolor: "white", width: "15px", height: "15px" }}
                checked={agreeTerms}
                value="a"
                onClick={() => setAgreeTerms(!agreeTerms)}
                name="radio-buttons"
              />
              <Typography variant="body2" color="#797979" sx={{ whiteSpace: "nowrap" }}>
                I agree to the
              </Typography>
              <Typography variant="body2" color="transparent" sx={{ whiteSpace: "nowrap" }} className={classes.body3}>
                Terms of Service
              </Typography>
              <Typography variant="body2" color="#797979" sx={{ whiteSpace: "nowrap" }}>
                and
              </Typography>
              <Typography variant="body2" color="transparent" sx={{ whiteSpace: "nowrap" }} className={classes.body3}>
                Privacy Policy
              </Typography>
            </Stack>
          </Box>
          <Stack>
            <EnduvoButton startIcon={null} action={submit} buttonText="Register" width={null} height={null} disabled={false} />
          </Stack>
          <Typography variant="caption" color="red">
            {errorMessage}
          </Typography>
          <Modal open={openSuccessModal} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box
              sx={{
                position: "absolute" as "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "576px",
                height: "233px",
                bgcolor: "#282C30", //'background.paper',
                border: "2px solid #000",
                borderRadius: 5,
                boxShadow: 24,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box sx={{ pl: 6, pt: 8 }}>
                <Typography sx={{ fontSize: "20px" }} color="white">
                  {`A set password link has been sent to your personal email,\n  ${email}`}
                </Typography>
                <Stack>
                  <EnduvoButton
                    startIcon={null}
                    action={goToDownload}
                    buttonText="Ok"
                    width="486px"
                    height={null}
                    disabled={false}
                  />
                </Stack>
              </Box>
            </Box>
          </Modal>
          {/* <Stack direction="row" spacing={2.75} sx={{ justifyContent: "center" }}>
                  <Stack direction="column" spacing={1.25} sx={{ justifyContent: "center" }}>
                    <Button
                      onClick={microsoftSubmit}
                      className={classes.buttonIcon}
                      sx={{ borderRadius: "20px", border: "1px solid #A4A4A4", ":hover": { bgcolor: "#f0f0f0" } }}
                    >
                      <img src="/images/microsoft.png" alt="/images/microsoft.png" />
                    </Button>
                    <Typography variant="body2" color="#797979" align="center">
                      Microsoft
                    </Typography>
                  </Stack>
                  <Stack direction="column" spacing={1.25} sx={{ justifyContent: "center" }}>
                    <Button
                      onClick={googleSubmit}
                      className={classes.buttonIcon}
                      sx={{ borderRadius: "20px", border: "1px solid #A4A4A4", ":hover": { bgcolor: "#f0f0f0" } }}
                    >
                      <img src="/images/google.png" alt="/images/google.png" />
                    </Button>
                    <Typography variant="body2" color="#797979" align="center">
                      Google
                    </Typography>
                  </Stack>
                </Stack> */}
          <Stack justifyContent="space-between" pb={5}>
            <Stack direction="row" spacing={4} sx={{ justifyContent: "center" }}>
              <Typography
                variant="body2"
                color="transparent"
                className={classes.body3}
                sx={{ cursor: "pointer" }}
                onClick={gotoMilitarySignUp}
              >
                Military Sign up
              </Typography>
            </Stack>
            <Stack direction="row" spacing={1} sx={{ justifyContent: "center" }}>
              <Typography variant="body2" color="#797979">
                Have an account?
              </Typography>
              <Typography
                sx={{ cursor: "pointer" }}
                variant="body2"
                color="transparent"
                className={classes.body3}
                onClick={openLogin}
              >
                Login
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
      <RightSideUi />
    </Stack>
  );
};
