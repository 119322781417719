import React from "react";
import { useHistory, NavLink } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { SignUpPage } from "../../pages/SignUpPage/index";
import { LoginPage } from "../../pages/LoginPage/index";
import { useAppDispatch, useTypedSelector } from "../../app/hooks";
import { download } from "../../features/download/download.slice";
import { userLogout } from "../../features/auth/auth.slice";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { light } from "../../css/color";
import { ForgotPassword } from "../../pages/ForgotPassword";
import classes from "./styles.module.css";
import { EnduvoButton } from "../../components/EnduvoButton";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "976px",
  // height: '673px',
  bgcolor: "#FFFFFF", //'background.paper',
  background: "#FFFFFF",
  border: "2px solid #000",
  // background: '#282C30',
  borderRadius: 5,
  boxShadow: 24,
  // pb: 10,
};

export const Navigation = () => {
  // const token = localStorage.getItem('enduvo_token');
  const { token } = useTypedSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const [openLogin, setLoginOpen] = React.useState(false);
  const [openResetPassword, setResetPasswordOpen] = React.useState(false);

  const [userLogoutAlert, setUserLogoutAlert] = React.useState("");
  const [showUserLogoutAlert, setShowUserLogoutAlert] = React.useState(false);
  const [alertSeverity, setAlertSeverity] = React.useState("success");
  const [userdownloadUrlAlert, setdownloadUrlAlert] = React.useState("");
  const [showDownloadAlert, setShowDownloadAlert] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleLoginOpen = () => setLoginOpen(true);
  const handleResetPasswordOpen = () => setResetPasswordOpen(true);

  const handleClose = () => setOpen(false);
  const handleLoginClose = () => setLoginOpen(false);
  const handleResetPasswordClose = () => setResetPasswordOpen(false);

  const handleAlertClose = () => setShowUserLogoutAlert(false);
  const handleDownloadAlertClose = () => setShowDownloadAlert(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const getDownloadLinkUrl = async () => {
    try {
      const resultAction = await dispatch(download());
      if (resultAction.payload.data && resultAction.payload.data.isInstallerExists) {
        setdownloadUrlAlert("Downloading...");
        setAlertSeverity("success");
        window.location.href = resultAction.payload.data.downloadUrl;
      } else if (resultAction.payload.error) {
        setdownloadUrlAlert(resultAction.payload.error.message);
        setAlertSeverity("error");
      } else {
        setdownloadUrlAlert(
          `Currently not available in ${resultAction.payload.data.os ? resultAction.payload.data.os : "this OS"}`
        );
        setAlertSeverity("info");
      }
      history.push("/download");
      setShowDownloadAlert(true);
    } catch (error) {
      const msg = (error as Error).message;
      setdownloadUrlAlert(msg);
      setAlertSeverity("error");
      setShowDownloadAlert(true);
    }
  };
  const handleLogout = async () => {
    try {
      const resultAction = await dispatch(userLogout());
      if (resultAction.payload.success) {
        setUserLogoutAlert("User logged out.");
        setAlertSeverity("success");
      } else if (resultAction.payload.error) {
        setUserLogoutAlert(resultAction.payload.error.message);
        setAlertSeverity("error");
      } else {
        setUserLogoutAlert("Try after some time.");
        setAlertSeverity("info");
      }
      setShowUserLogoutAlert(true);
    } catch (error) {
      const msg = (error as Error).message;
      setUserLogoutAlert(msg);
      setAlertSeverity("error");
      setShowUserLogoutAlert(true);
    }
  };
  return (
    <AppBar
      position="static"
      sx={{ backgroundImage: light.bgColor5, background: light.bgColor5, borderBottom: "1px solid #282C30" }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        <Button component={NavLink} to="/" sx={{ color: light.textColor2, textTransform: "none" }}>
          <img height="46px" width="46px" src="/images/light/enduvoLogo.png" alt="/images/light/enduvoLogo.png" />
          <div style={{ marginLeft: "8px", marginTop: "10px" }}>
            <img height="17.56px" width="93.52px" src="/images/enduvo-text.png" alt="/images/enduvo-text.png" />
          </div>
        </Button>
        <Snackbar open={showUserLogoutAlert} autoHideDuration={6000} onClose={handleAlertClose}>
          {alertSeverity == "success" ? (
            <Alert onClose={handleAlertClose} sx={{ width: "100%" }} severity="success">
              {userLogoutAlert}
            </Alert>
          ) : alertSeverity == "error" ? (
            <Alert onClose={handleAlertClose} sx={{ width: "100%" }} severity="error">
              {userLogoutAlert}
            </Alert>
          ) : (
            <Alert onClose={handleAlertClose} sx={{ width: "100%" }} severity="info">
              {userLogoutAlert}
            </Alert>
          )}
        </Snackbar>
        <Snackbar open={showDownloadAlert} autoHideDuration={6000} onClose={handleDownloadAlertClose}>
          {alertSeverity == "success" ? (
            <Alert onClose={handleDownloadAlertClose} sx={{ width: "100%" }} severity="success">
              {userdownloadUrlAlert}
            </Alert>
          ) : alertSeverity == "error" ? (
            <Alert onClose={handleDownloadAlertClose} sx={{ width: "100%" }} severity="error">
              {userdownloadUrlAlert}
            </Alert>
          ) : (
            <Alert onClose={handleDownloadAlertClose} sx={{ width: "100%" }} severity="info">
              {userdownloadUrlAlert}
            </Alert>
          )}
        </Snackbar>
        <Stack direction="row" spacing={2}>
          {token ? (
            <>
              <Button onClick={handleLogout} sx={{ color: light.textColor2, textTransform: "none" }}>
                <Typography sx={{ color: light.textColor2, textTransform: "none", fontSize: "14px", lineHeight: "18px" }}>
                  Logout
                </Typography>
              </Button>
              <EnduvoButton
                startIcon={<AccountCircleOutlinedIcon />}
                action={getDownloadLinkUrl}
                buttonText="Download"
                width={null}
                height={null}
                disabled={false}
              />
            </>
          ) : (
            <>
              <Button
                onClick={handleLoginOpen}
                sx={{ color: light.textColor2, textTransform: "none", fontSize: "14px", lineHeight: "18px" }}
              >
                <Typography variant="subtitle1">Login</Typography>
              </Button>
              <EnduvoButton
                startIcon={<AccountCircleOutlinedIcon />}
                action={handleOpen}
                buttonText="Sign Up"
                width={null}
                height={null}
                disabled={false}
              />
            </>
          )}
          <Modal
            open={open || openLogin || openResetPassword}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            {/* {!openResetPassword ? */}
            <Box sx={style}>
              <div
                style={{
                  position: "absolute",
                  marginTop: "15px",
                  marginLeft: "930px",
                  marginRight: "20px",
                  cursor: "pointer",
                  zIndex: 200,
                }}
                onClick={() => (open ? handleClose() : openLogin ? handleLoginClose() : handleResetPasswordClose())}
              >
                <img
                  height="32px"
                  width="32px"
                  src="/images/dapp-signup-close1.png"
                  alt="/images/dapp-signup-close1.png"
                  className={classes.closeButton}
                />
              </div>
              {open ? <SignUpPage closeModal={handleClose} openLoginModal={handleLoginOpen} /> : null}
              {openLogin ? (
                <LoginPage
                  closeModal={handleLoginClose}
                  openRegisterModal={handleOpen}
                  openResetPasswordModal={handleResetPasswordOpen}
                />
              ) : null}
              {openResetPassword ? (
                <ForgotPassword
                  closeModal={handleResetPasswordClose}
                  openRegisterModal={handleOpen}
                  openLoginModal={handleLoginOpen}
                />
              ) : null}
            </Box>
          </Modal>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
