
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { light }  from "../../css/color";

export const AboutPage = () => {
  return (
    <Box
      sx={{
        bgcolor: light.bgColor, 
        height: "110vh",
        paddingLeft: "68px",
        paddingTop: "31px",
      }}
    >
      <Stack alignItems="center">
        <Typography sx={{ fontSize: "60px", color: light.textColor1 }}>About</Typography>
      </Stack>
    </Box>
  );
};
