import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { light } from "../../css/color";
import { RightSideUi } from "../../components/RightSideUi/index";
import { useLocation, useHistory } from "react-router-dom";
import { verifyInviteLink } from "../../features/teams/teams.slice";
import { useAppDispatch } from "../../app/hooks";
import { EnduvoButton } from "../../components/EnduvoButton";
import config from "../../app/config";

export const TeamInvite = () => {
  const [teamName, setTeamName] = React.useState("");
  const [teamsInviteKey, setTeamsInviteKey] = React.useState("");
  const [errorMessage, setExpiredLinkMessage] = React.useState("");
  const dispatch = useAppDispatch();

  const seturiAndTeamsInviteKey = async () => {
    const url = window.location.href;
    const pieces = url.split("?");
    if (pieces[1]) {
      const urlparam = pieces[1];
      const urlparamSplitFind = urlparam.split("&");
      let urlArray = [];
      let teamName = "";
      let teamsInviteKey = "";
      for (let i = 0; i < urlparamSplitFind.length; i += 1) {
        urlArray = urlparamSplitFind[i].split("=");
        const [key, value] = urlArray;
        if (key === "teamsInviteKey") teamsInviteKey = value;
        if (key === "name") teamName = decodeURIComponent(value);
      }

      const resultAction = await dispatch(verifyInviteLink({ invite_key: teamsInviteKey }));
      setTeamName(teamName);
      if (resultAction && resultAction.payload && resultAction.payload.success && resultAction.payload.data.validLink)
        setTeamsInviteKey(teamsInviteKey);
      else {
        const errorMessage = "Oops! Your invitation link has expired. Please contact the Team Owner to request a new link.";
        setExpiredLinkMessage(errorMessage);
      }
    }
  };

  useEffect(() => {
    seturiAndTeamsInviteKey();
    return () => {
      seturiAndTeamsInviteKey();
    };
  }, []);

  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  const openLink = query.get("openLink");
  const openApp = () => {
    const { userAgent } = window.navigator;
    const errorMessage = "This device is not supported at the moment. Use Windows/Mac PC to play this course.";
    if (userAgent.indexOf("Mac") !== -1 || userAgent.indexOf("Windows") !== -1) {
      if (openLink) window.location.href = decodeURIComponent(openLink);
      else {
        let now = new Date().valueOf();
        setTimeout(function () {
          if (new Date().valueOf() - now > 100) return;
          history.push("/download");
        }, 1000);
        // window.location.href = `enduvolauncher://teams?name=${teamName}&teamsInviteKey=${teamsInviteKey}`;
        window.location.href = `${config.desktopAppDeepLinkURL}teams?name=${teamName}&teamsInviteKey=${teamsInviteKey}`;
      }
    } else setExpiredLinkMessage(errorMessage);
  };
  return (
    <Box sx={{ bgcolor: "rgb(192, 192, 192)" }}>
      <Stack alignItems="center" pt={6} pb={10}>
        <Stack flexDirection="row" sx={{ bgcolor: light.bgColor3, borderRadius: 5 }}>
          <Stack direction="column" sx={{ width: "500px", pr: 4, pl: 2, mt: 10 }}>
            <Stack alignItems="center" mb={2}>
              <Typography variant="h4" component="h2" color={light.textColor1}>
                Welcome to teams!
              </Typography>
              <Typography color={light.textColor1} sx={{ fontSize: 24, mt: 3 }}>
                {teamName}
              </Typography>
            </Stack>
            <Stack mb={2} pl={4}>
              <Typography color={light.textColor1} sx={{ fontSize: 18 }}>
                You must have the app downloaded to accept invite,
              </Typography>
              <Typography color={light.textColor1} sx={{ fontSize: 18 }}>
                If not, please login and download.
              </Typography>
            </Stack>
            <Stack pr={2} pl={4}>
              <EnduvoButton
                startIcon={null}
                action={() => (errorMessage ? null : openApp())}
                buttonText="Take Me There"
                width={null}
                height={null}
                disabled={errorMessage ? true : false}
              />
            </Stack>
            <Stack mt={4} pl={4}>
              <Typography color={light.textColor1} sx={{ fontSize: 16, fontStyle: "italic" }}>
                {errorMessage}
              </Typography>
            </Stack>
          </Stack>
          <RightSideUi />
        </Stack>
      </Stack>
    </Box>
  );
};
