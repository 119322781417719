import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from '@mui/material/Box';
import classes from './styles.module.css'
import { light }  from "../../css/color";


const onFocused = {
  /* position: absolute; */
  width: '57px',
  height: '9px',
  background: light.buttonBgColor,
  borderRadius: '16px',
  cursor: 'pointer'
};
const notFocused = {
  /* position: absolute; */
  width: '9px',
  height: '9px',
  background: '#8FB1D2',
  borderRadius: '16px',
  cursor: 'pointer',
}
export const RightSideUi = () => {
  const [slide1, setSlide1] = React.useState(true);
  const [slide2, setSlide2] = React.useState(false);
  const [slide3, setSlide3] = React.useState(false);
  const handleSlide = (slide: string) => {
        switch (slide) {
            case 'slide1':
                setSlide1(true)
                setSlide2(false)
                setSlide3(false)
                break;
            case 'slide2':
                setSlide1(false)
                setSlide2(true)
                setSlide3(false)
                break;
            case 'slide3':
                setSlide1(false)
                setSlide2(false)
                setSlide3(true)
                break;
            default:
                break;
        }
        
    };
    
  return (
    <Stack direction="column"  sx={{ bgcolor: light.bgColor4, pr: '30px',borderTopRightRadius: 15, borderBottomRightRadius: 15 }}>
        <div className={classes.elipse} style={{ background: light.bgColor2 }}/>
        <Stack direction="row" spacing={18}>
            <Box className={classes.object1} style={{ background: light.objectColor5 }}/>
            <Box className={classes.object2} style={{ background: light.objectColor5 }}/>
            <Box className={classes.object3} style={{ background: light.objectColor5 }}/>
        </Stack>
        <Box className={classes.object4} style={{ background: light.objectColor5 }} />
        <Box className={classes.object5} style={{ background: light.objectColor5 }} />
        <Box className={classes.object6} style={{ background: light.objectColor5 }} />
        <Box className={classes.object7} style={{ background: light.objectColor5 }} />
        {slide1 || slide2 ? <div className={classes.objectBox1} style={{ background: '#ffffff' }}/> : null }
        {slide1 ? (
            <>
            <Box className={classes.objectBox2}>
                <img
                    height="206px"
                    width="223px"
                    src="/images/light/dapp-signup-box.png"
                    alt="/images/light/dapp-signup-box.png"
                />
            </Box>
            </>
        ) : null}
        {slide1 ? (<Stack sx={{ position:'absolute', ml: '160px', mt: '410px', alignItems: 'center' }}>
            <Typography variant="h6" component="h2"  color={light.textColor1}>
                Create
            </Typography>
            <Typography variant="body1" color={light.textColor1}>
                Create content once.
            </Typography>
        </Stack>) : null}
        {slide2 ? (
            <>
                <Stack sx={{ position:'absolute', ml: '160px', mt: '410px', alignItems: 'center' }}>
                    <Typography variant="h6" component="h2"  color={light.textColor1}>
                        Share
                    </Typography>
                    <Typography variant="body1" color={light.textColor1}>
                        Distribute to any device.
                    </Typography>
                </Stack>
                <Stack className={classes.buttonShare} >
                <Button
                    variant="contained"
                    sx={{
                    color: "white",
                    height: '36px',
                    width: '105px',
                    mt: 3,
                    textTransform: "none",
                    borderRadius:'5px',
                    background:light.buttonBgColor,
                    justifyContent: "space-around"
                    }}
                >   
                    <img
                        height="18px"
                        width="18px"
                        src="/images/dapp-signup-shareIcon.png"
                        alt="/images/dapp-signup-shareIcon.png"
                    />
                    <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                        Share
                    </Typography>
                </Button>
                
            </Stack>
            <Stack className={classes.buttonYoutube}>
                <Button variant="contained" className={classes.youtube} sx={{borderRadius:'13.2167px'}}>   
                        <img
                            height="15.86px"
                            width="21.88px"
                            src="/images/dapp-signup-youtube.png"
                            alt="/images/dapp-signup-youtube.png"
                        />
                        <Typography sx={{ fontSize: '9px', whiteSpace: 'nowrap', mt: '4px' }}>
                            Youtube
                        </Typography>
                    </Button>
            </Stack>
            <Stack className={classes.buttonDropbox}>
                <Button variant="contained" className={classes.Dropbox} sx={{borderRadius:'13.2167px'}} >   
                        <img
                            height="19.97px"
                            width="20.99px"
                            src="/images/dapp-signup-dropbox.png"
                            alt="/images/dapp-signup-dropbox.png"
                        />
                        <Typography sx={{ fontSize: '9px', whiteSpace: 'nowrap', mt: '4px' }}>
                            Dropbox
                        </Typography>
                    </Button>
            </Stack>
            <Stack className={classes.buttonGoogleDrive}>
                <Button variant="contained" className={classes.GoogleDrive} sx={{borderRadius:'13.2167px'}} >   
                        <img
                            height="19.94px"
                            width="23.85px"
                            src="/images/dapp-signup-googledrive.png"
                            alt="/images/dapp-signup-googledrive.png"
                        />
                        <Typography sx={{ fontSize: '8px', whiteSpace: 'nowrap', mt: '4px' }}>
                            Google Drive
                        </Typography>
                    </Button>
            </Stack>
            <Stack className={classes.buttonOneDrive}>
                <Button variant="contained" className={classes.OneDrive} sx={{borderRadius:'13.2167px'}} >   
                        <img
                            height="14.42px"
                            width="22.5px"
                            src="/images/dapp-signup-onedrive.png"
                            alt="/images/dapp-signup-onedrive.png"
                        />
                        <Typography sx={{ fontSize: '9px', whiteSpace: 'nowrap', mt: '4px' }}>
                            OneDrive
                        </Typography>
                    </Button>
            </Stack>
        </>) : null}
        {slide3 ? (
            <>
                <Box className={classes.lap1Border} />
                <Box className={classes.lap2Border} />
                <Box className={classes.lap1}>
                    <img
                        height="144.86px"
                        width="212.94px"
                        src="/images/dapp-signup-lap.png"
                        alt="/images/dapp-signup-lap.png"
                    />
                </Box>
                <Box className={classes.lap2}>
                    <img
                        height="86.3px"
                        width="126.85px"
                        src="/images/dapp-signup-lap2.png"
                        alt="/images/dapp-signup-lap2.png"
                    />
                </Box>
                <Box className={classes.vr}>
                    <img
                        height="111.56px"
                        width="176.56px"
                        src="/images/dapp-signup-vr.png"
                        alt="/images/dapp-signup-vr.png"
                    />
                </Box>
                <Stack sx={{ position:'absolute', ml: '160px', mt: '410px', alignItems: 'center' }}>
                    <Typography variant="h6" component="h2"  color={light.textColor1}>
                        Learn
                    </Typography>
                    <Typography variant="body1" color={light.textColor1}>
                        VR headset, PC, Tablet
                    </Typography>
                </Stack>
        </>) : null}
        <Stack direction="row" justifyContent="center" spacing={1} sx={{ position:'absolute', ml: '200px',  mt: '520px', alignItems: 'center' }}>
            <Box style={slide1 ? onFocused : notFocused} onClick={() => handleSlide('slide1')}/>
            <Box style={slide2 ? onFocused : notFocused} onClick={() => handleSlide('slide2')}/>
            <Box style={slide3 ? onFocused : notFocused} onClick={() => handleSlide('slide3')}/>
        </Stack>
    </Stack>
  );
}
