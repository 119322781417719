import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../app/config";

export interface commonAppConfig {
  applicationLists: [];
  status: string;
}

const initialState: commonAppConfig = {
  applicationLists: [],
  status: '',
};

export const getCommonAppConfig = createAsyncThunk("commonAppConfig", async () => {
  const response = await axios.get(`${config.baseURL}/api/app-config-common/lists`);
  return response.data;
});

const commonAppConfigSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCommonAppConfig.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(getCommonAppConfig.fulfilled, (state, action) => {
      if(action.payload.success){
        state.applicationLists = action.payload.data;
      }
      state.status = "success";
    });
    builder.addCase(getCommonAppConfig.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default commonAppConfigSlice.reducer;
