import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

import config from '../../app/config';

const assignUserToSession = async (state: string, authorization: string) => {
  await axios.post(
    `${config.baseURL}/api/oauth/assign`,
    { state },
    {
      headers: {
        authorization: authorization,
      },
    },
  );

  const search = new URLSearchParams({
    state,
  });
  window.location.href = `${config.baseURL}/api/oauth/complete?${search}`;
};

export const useAssignUserToSession = () => {
  const { search } = useLocation();

  // NOTE(bbuck-enduvo): As part of the application Oauth flow the start Oauth
  //   endpoint needs to send a user to a login page.
  useEffect(() => {
    const authorization = localStorage.getItem('enduvo_token');
    if (!authorization) {
      console.error('User is not currently logged in.');

      return;
    }

    const searchParams = new URLSearchParams(search);
    const state = searchParams.get('state');
    if (!state) {
      console.error('No state value to associate with AuthorizationSession');

      return;
    }

    assignUserToSession(state, authorization);
  }, [search]);
};
