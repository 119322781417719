import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../app/config";

export interface Download {
  status: string;
  downloadUrl: string;
}

const initialState: Download = {
  status: "",
  downloadUrl: "",
};

export interface ApiBody {
  token: string;
}

export const download = createAsyncThunk("auth/download", async () => {
  const token = localStorage.getItem('enduvo_token');
  const response = await axios.get(`${config.baseURL}/api/client-installers?version=2`, { headers: { "Authorization": `${token}` } });
  return response.data;
});

const downloadSlice = createSlice({
  name: "download",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(download.pending, (state, action) => {
      state.status = "loading";
    });
    builder.addCase(download.fulfilled, (state, action) => {
      if(action.payload.success){
        state.downloadUrl = action.payload.data;
      }
      state.status = "success";
    });
    builder.addCase(download.rejected, (state, action) => {
      state.status = "failed";
    });
  },
});

export default downloadSlice.reducer;
