import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";

import { Routes } from "./Routes";
import { Navigation } from "./components/Navigation/Navigation.component";
import { Footer } from "./components/Footer/index";

class App extends React.Component {
  
  appInterceptor = () => {
      axios.interceptors.request.use((request) => {
        request.withCredentials = true;
        return request;
      });

      axios.interceptors.response.use((response) => {
        if (!response.data.success) {
          if (response.data.error && response.data.error.code === 401) {
            const responseClone = Object.assign({}, response);
            let reloadPage = true;
            if (response.config
              && (response.config.method === 'post' || response.config.method === 'put')) reloadPage = false;
            if (responseClone.data) responseClone.data.error = { code: 401, message: '' };
            return responseClone;
          }
          if (response.data.error && response.data.error.code === 403) {
            setTimeout(() => {
              if (window.location.pathname !== '/') {
                window.location.href = '/';
              }
            }, 4000);
          }
        }
        return response;
      }, error => (Promise.reject(error)));
    }
  render () {
    const theme = createTheme({
      typography: {
        fontFamily: "Product Sans",
      },
    });
    this.appInterceptor();

    return (
      <ThemeProvider theme={theme}>
        <Router>
          <div>
            <Navigation />
            <Routes />
            <Footer />
          </div>
        </Router>
      </ThemeProvider>
    );
  }
}
export default App;
