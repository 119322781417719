import React from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { light } from "../../css/color";

interface EnduvoButtonParams {
  startIcon: React.ReactElement<HTMLElement> | null;
  action: () => void;
  buttonText: string;
  width: string | null;
  height: string | null;
  disabled: boolean;
}
export const EnduvoButton = (props: EnduvoButtonParams) => {
  const onClickButton = () => props.action();
  return (
    <Stack>
      <Button
        variant="contained"
        // component={NavLink}
        startIcon={props.startIcon}
        disabled={props.disabled}
        // style={{width: props.width}}
        sx={{
          width: props.width,
          height: props.height,
          textTransform: "none",
          color: "white",
          background: light.buttonBgColor,
          border: "1px solid transparent",
          ":hover": {
            boxShadow: "inset 0 0 70px rgba(0, 0, 0, 0.4)",
            border: "1px solid #EBAF5D",
            borderRadius: "5px",
            boxSizing: "border-box",
          },
          ":active": {
            boxShadow: "inset 0 0 70px rgba(0, 0, 0, 0)",
            border: "1px solid #FFFFFF",
            borderRadius: "5px",
            background: "#FFFFFF",
            boxSizing: "border-box",
            color: "#F1BE68",
          },
        }}
        onClick={onClickButton}
      >
        <Typography variant="subtitle1" sx={{ textTransform: "none", fontWeight: 700 }}>
          {props.buttonText}
        </Typography>
      </Button>
    </Stack>
  );
};
