import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import axios from "axios";
import config from "../../app/config";

export interface Auth {
  token: string;
  status: string;
  error: string | null;
}

export interface LoginBody {
  username: string;
  password: string;
}

export interface RegisterBody {
  name: string;
  last_name: string;
  email: String;
  organization: string;
}

export interface MilitaryRegisterBody {
  name: string;
  email: String;
  military_user: boolean;
  user_category: string;
  secondary_email_section: object;
  how_did_you_hear_about_us: string;
  title: string;
  organization: string;
}
export interface ResetPasswordBody {
  email: string;
  password: string;
  resetPasswordKey: string;
  termsOfUse: boolean;
}

export interface ResetPasswordMilBody {
  email: string;
  password: string;
  resetPasswordKey: string;
  termsOfUse: boolean;
  secondaryEmailSection: object;
}

export interface ForgotPasswordBody {
  email: string;
}
export interface ProviderLoginAction {
  token: string;
}

export interface EarlyAccessBody {
  first_name: string;
  last_name: string;
  email: string;
  title: string;
  phone: string;
  business_name: string;
  how_did_you_find_us: string;
}
export interface ResendCodeBody {
  email: string;
}

interface OidcError {
  errorMessage: string,
}

export interface OidcCallbackBody {
  providerIdentifier: string;
  queryString: string;
}

export const AuthActionStatus = {
  loading: 'loading',
  success: 'success',
  failed: 'failed',
};

const initialState: Auth = {
  token: "",
  status: "",
  error: null,
};

export const oidcCallback = createAsyncThunk(
  'oidc/callback',
  async (data: OidcCallbackBody, { rejectWithValue }) => {
    const callbackUrl = `${config.baseURL}/oidc/${data.providerIdentifier}/callback${data.queryString}`;
    try {
      const response = await axios.get(callbackUrl);
      return response.data;  
    } catch (error: any) {
      const errObj = {
        errorMessage: error.response.data.message,
      };
      return rejectWithValue((errObj) as OidcError);
    }
  });


export const login = createAsyncThunk("auth/login", async (data: LoginBody) => {
  const response = await axios.post(`${config.baseURL}/api/auth/login`, {
    username: data.username,
    password: data.password,
  });
  return response.data;
});

export const userLogout = createAsyncThunk("auth/logout", async () => {
  const response = await axios.put(`${config.baseURL}/api/auth/logout`);
  return response.data;
});

export const register = createAsyncThunk("auth/register", async (data: RegisterBody) => {
  const response = await axios.post(`${config.baseURL}/api/auth/register`, 
  {
    name: data.name,
    last_name: data.last_name,
    email: data.email,
    organization: data.organization,
    client_version: 2,
  });
  return response.data;
});

export const milRegister = createAsyncThunk("auth/military-register", async (data: MilitaryRegisterBody) => {
  const response = await axios.post(`${config.baseURL}/api/auth/register`, 
  {
    name: data.name,
    email: data.email,
    military_user: data.military_user,
    user_category: data.user_category,
    how_did_you_hear_about_us: data.how_did_you_hear_about_us,
    secondary_email_section: data.secondary_email_section,
    title: data.title,
    organization: data.organization,
    client_version: 2
  });
  return response.data;
});

export const resendCode = createAsyncThunk("auth/resendCode", async (data: ResendCodeBody) => {
  const response = await axios.post(`${config.baseURL}/api/auth/resendCode`, {
    email: data.email
  });
  return response.data;
});

export const resetPassword = createAsyncThunk("/auth/resetPassword", async (data: ResetPasswordBody) => {
  const response = await axios.post(`${config.baseURL}/api/auth/resetPassword`, {
    email: data.email,
    password: data.password,
    resetPasswordKey: data.resetPasswordKey,
    termsOfUse: data.termsOfUse,
  });
  return response.data;
});

export const resetPasswordMil = createAsyncThunk("/auth/resetPasswordMil", async (data: ResetPasswordMilBody) => {
  const response = await axios.post(`${config.baseURL}/api/auth/resetPassword`, {
    email: data.email,
    password: data.password,
    resetPasswordKey: data.resetPasswordKey,
    termsOfUse: data.termsOfUse,
    secondaryEmailSection: data.secondaryEmailSection,
  });
  return response.data;
});

export const requestPasswordLink = createAsyncThunk("/auth/forgotPassword", async (data: ForgotPasswordBody) => {
  const response = await axios.post(`${config.baseURL}/api/auth/forgotPassword`, { 
    email: data.email,
    client_version: 2,
  });
  return response.data;
});

export const createEarlyAccessUser = createAsyncThunk("/auth/early-access", async (data: EarlyAccessBody) => {
  const response = await axios.post(`${config.baseURL}/api/early-access`, { 
    first_name: data.first_name,
    last_name: data.last_name,
    email: data.email,
    title: data.title,
    phone: data.phone,
    business_name: data.business_name,
    how_did_you_find_us: data.how_did_you_find_us,
  });
  return response.data;
});

export const providerLogin = createAction<ProviderLoginAction>('/auth/providerLogin');

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(oidcCallback.pending, (state) => {
      state.status = AuthActionStatus.loading;
    });
    builder.addCase(oidcCallback.fulfilled, (state, action) => {
      if(action.payload.success){
        state.token = action.payload.data.token;
        localStorage.setItem('enduvo_token', `Bearer ${action.payload.data.token}`);
      }
      state.status = AuthActionStatus.success;
    });
    builder.addCase(oidcCallback.rejected, (state, action) => {
      state.status = AuthActionStatus.failed;
      const payload = action.payload as OidcError;
      if (payload) {
        state.error = payload.errorMessage;
      }
    });
    builder.addCase(login.pending, (state, action) => {
      state.status = AuthActionStatus.loading;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      if(action.payload.success){
        state.token = action.payload.data.token;
        localStorage.setItem('enduvo_token', `Bearer ${action.payload.data.token}`);
      }
      state.status = AuthActionStatus.success;
    });
    builder.addCase(providerLogin, (state, action) => {
      if(action.payload.token){
        state.token = action.payload.token;
        localStorage.setItem('enduvo_token', `Bearer ${action.payload.token}`);
      }
      state.status = AuthActionStatus.success;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.status = AuthActionStatus.failed;
    });
    builder.addCase(userLogout.fulfilled, (state, action) => {
      if(action.payload.success){
        state.token = "";
        localStorage.removeItem('enduvo_token');
      }
      state.status = AuthActionStatus.success;
    });
    builder.addCase(register.fulfilled, (state, action) => {
      if(action.payload.success){
        state.status = AuthActionStatus.success;
      }
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      if(action.payload.success){
        state.status = AuthActionStatus.success;
      }
    });
    builder.addCase(createEarlyAccessUser.fulfilled, (state, action) => {
      if(action.payload.success){
        state.status = AuthActionStatus.success;
      }
    });
  },
});

export default authSlice.reducer;
