import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import PlayArrowRoundedIcon from "@mui/icons-material/PlayArrowOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import Modal from "@mui/material/Modal";
import { LoginPage } from "../../pages/LoginPage/index";
import { SignUpPage } from "../../pages/SignUpPage/index";
import { ForgotPassword } from "../../pages/ForgotPassword";
import classes from "./styles.module.css";
import { light } from "../../../src/css/color";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "976px",
  height: "673px",
  bgcolor: "#FFFFFF", //'#282C30', //'background.paper',
  border: "2px solid #000",
  background: "#FFFFFF",
  borderRadius: 5,
  boxShadow: 24,
};

export const Footer = () => {
  const [openLogin, setOpenLogin] = React.useState(false);
  const [openSignUp, setopenSignUp] = React.useState(false);
  const [openResetPassword, setResetPasswordOpen] = React.useState(false);
  const location = useLocation();
  const current = new Date();
  const date = `${current.getFullYear()}`;

  const handleOpenLogin = () => setOpenLogin(true);
  const handleLoginClose = () => setOpenLogin(false);
  const handleOpenSignUp = () => setopenSignUp(true);
  const handleSignUpClose = () => setopenSignUp(false);
  const handleResetPasswordOpen = () => setResetPasswordOpen(true);
  const handleResetPasswordClose = () => setResetPasswordOpen(false);
  const handleScrollUp = () => {
    location.pathname == "/" ? window.scrollTo({ left: 0, top: 0, behavior: "smooth" }) : (window.location.href = "/");
  };

  const goTo = (link: string) => {
    switch (link) {
      // Social media
      case "twitter":
        window.open("https://www.twitter.com/enduvo");
        break;
      case "youtube":
        window.open("https://www.youtube.com/enduvo");
        break;
      case "facebook":
        window.open("https://www.facebook.com/enduvo");
        break;
      case "linkedin":
        window.open("https://www.linkedin.com/company/enduvo");
        break;
      case "instagram":
        window.open("https://www.instagram.com/enduvo/");
        break;
      default:
        window.open("https://enduvo.com");
        break;
    }
  };
  return (
    <AppBar position="static" sx={{ backgroundColor: "#343841" }}>
      <Stack justifyContent="space-between" pl={12} pr={8} py={6}>
        <Stack direction="row" justifyContent="space-between">
          <Button sx={{ color: light.textColor2, textTransform: "none" }} onClick={handleScrollUp}>
            <img height="46px" width="46px" src="/images/light/enduvoLogo.png" alt="/images/light/enduvoLogo.png" />
            <div style={{ marginLeft: "8px", marginTop: "10px" }}>
              <img
                style={{ filter: " brightness(0) invert(1) " }}
                height="17.56px"
                width="93.52px"
                src="/images/enduvo-text.png"
                alt="/images/enduvo-text.png"
              />
            </div>
          </Button>
          <Stack direction="row" spacing={4} alignItems="center">
            <Typography sx={{ fontSize: "14px", lineHeight: "17px", fontWeight: "bold" }}>Ready to get started?</Typography>
            <Button
              startIcon={<PlayArrowRoundedIcon />}
              sx={{
                textTransform: "none",
                width: "172px",
                background: "#282C30",
                border: "1px solid transparent",
                ":hover": {
                  border: "1px solid #666564",
                  borderRadius: "5px",
                  boxSizing: "border-box",
                },
                borderRadius: "5px",
                height: "37px",
                color: "white",
              }}
              onClick={handleOpenSignUp}
            >
              <Typography sx={{ fontSize: "14px", lineHeight: "17px", fontWeight: "bold", color: "white" }}>
                Start now for free
              </Typography>
            </Button>
          </Stack>
        </Stack>
        <Modal
          open={openSignUp || openLogin || openResetPassword}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div
              style={{
                position: "absolute",
                marginTop: "15px",
                marginLeft: "930px",
                marginRight: "20px",
                cursor: "pointer",
                zIndex: 200,
              }}
              onClick={() => (openSignUp ? handleSignUpClose() : openLogin ? handleLoginClose() : handleResetPasswordClose())}
            >
              <img
                height="32px"
                width="32px"
                src="/images/dapp-signup-close1.png"
                alt="/images/dapp-signup-close1.png"
                onClick={() => (openSignUp ? handleSignUpClose() : handleLoginClose())}
                className={classes.closeButton}
              />
            </div>
            {openSignUp ? <SignUpPage closeModal={handleSignUpClose} openLoginModal={handleOpenLogin} /> : null}
            {openLogin ? (
              <LoginPage
                closeModal={handleLoginClose}
                openRegisterModal={handleOpenSignUp}
                getDownloadLinkUrl={handleOpenSignUp}
                openResetPasswordModal={handleResetPasswordOpen}
              />
            ) : null}
            {openResetPassword ? (
              <ForgotPassword
                closeModal={handleResetPasswordClose}
                openRegisterModal={handleOpenSignUp}
                openLoginModal={handleOpenLogin}
              />
            ) : null}
          </Box>
        </Modal>
        <Box className={classes.lineSeperator} />
        <Stack mt={6} direction="row" justifyContent="space-between">
          <Stack>
            <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>Contact@enduvo.com</Typography>
            <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>(877) 624-7838</Typography>
          </Stack>
          <Stack direction="row" spacing={20}>
            <Stack>
              <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>About</Typography>
              <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>Healthcare</Typography>
              <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>Government</Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>News</Typography>
              <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>Resources</Typography>
              <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>Careers</Typography>
              <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>Community</Typography>
            </Stack>
            <Stack>
              <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>Blog</Typography>
              <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>Contact</Typography>
              <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>Support</Typography>
            </Stack>
          </Stack>
        </Stack>
        <Stack mt={6} direction="row" justifyContent="space-between" alignItems="flex-end">
          <Stack direction="row" spacing={10}>
            <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>Credits</Typography>
            <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>
              <div onClick={() => window.open("/terms-of-use")} style={{ cursor: "pointer" }}>
                Enduvo’s Terms of Use
              </div>
            </Typography>
            <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>
              <div onClick={() => window.open("/privacy-policy")} style={{ cursor: "pointer" }}>
                Enduvo’s Global Privacy Policy
              </div>
            </Typography>
          </Stack>
          <Stack spacing={4} alignItems="center">
            <Stack direction="row" spacing={4}>
              <div onClick={() => goTo("twitter")} style={{ cursor: "pointer" }}>
                <TwitterIcon />
              </div>
              <div onClick={() => goTo("linkedin")} style={{ cursor: "pointer" }}>
                <LinkedInIcon />
              </div>
              <div onClick={() => goTo("youtube")} style={{ cursor: "pointer" }}>
                <YouTubeIcon />
              </div>
              <div onClick={() => goTo("instagram")} style={{ cursor: "pointer" }}>
                <InstagramIcon />
              </div>
            </Stack>
            <Typography sx={{ fontSize: "16px", lineHeight: "27px" }}>@{date} Enduvo</Typography>
          </Stack>
        </Stack>
      </Stack>
    </AppBar>
  );
};
